import { BaseModel } from './base.model';

export class PaymentModel extends BaseModel {
    id: number;
    amount: number;
    description: any;
    user_id: number;
    payment_method_id: number;
    payment_method: any;
    payment_status_id: number;
    payment_status: any;
    created_at: string;
    updated_at: string;
    deleted_at: string;

    constructor(input) {
        super(input);
    }

    getStatus() {
        if (this.payment_status) {
            return this.payment_status.status;
        }
        return this.payment_status_id;
    }

    getMethod() {
        if (this.payment_method) {
            try {
                return this.getValueByLocale(this.payment_method.name);
            } catch (e) {
                return this.payment_method_id;
            }
        }
        return this.payment_method_id;
    }
}
