import { BaseModel } from './base.model';
import { BookingModel } from './booking.model';
/*
{
    "id": 1,
    "review": "The best service in this city",
    "rate": 5,
    "booking_id": 3,
    "created_at": "2022-11-21T15:00:00.000000Z",
    "updated_at": "2022-11-21T15:00:00.000000Z",
    "laravel_through_key": "14",
    "custom_fields": [],
    "user": null
}
 */
export class UserReviewModel extends BaseModel {
    id: number;
    review: string;
    rate: number;
    booking_id: number;
    created_at: string;
    updated_at: string;
    laravel_through_key: any;
    custom_fields: any;
    user: any;
    booking: any;

    constructor(input: any) {
        super(input);

        if (this.booking) {
            this.booking = new BookingModel(this.booking);
        }
    }
}
