import { BaseModel } from './base.model';

export class SettingModel extends BaseModel {
    app_name: string;
    app_short_description: string;
    locale: string;
    default_tax: string;
    default_currency: any;
    default_currency_code: any;
    default_currency_id: number;
    fcm_key: string;
    main_color: string;
    main_dark_color: string;
    second_color: string;
    second_dark_color: string;
    accent_color: string;
    accent_dark_color: string;
    scaffold_dark_color: string;
    scaffold_color: string;
    google_maps_key: string;
    mobile_language: string;
    app_version: string;
    enable_version: any;
    enable_facebook: any;
    enable_google: any;
    default_currency_decimal_digits: any;
    currency_right: any;
    distance_unit: string;
    default_theme: string;
    default_country_code: string;
    enable_otp: any;
    enable_razorpay: any;
    enable_stripe: any;
    enable_paypal: any;
    app_logo: string;

    constructor(input) {
        super(input);
        if (!this.locale) {
            this.locale = 'en';
        }
        if (!this.default_currency) {
            this.default_currency = 'USD';
        }

        if (!this.default_currency_code) {
            this.default_currency_code = 'USD';
        }
        this.default_currency_id = Number(this.default_currency_id);
        this.enable_stripe = this.enable_stripe === '1';
        this.enable_otp = this.enable_otp === '1';
        this.enable_razorpay = this.enable_razorpay === '1';
        this.enable_version = this.enable_version === '1';
        this.enable_paypal = this.enable_paypal === '1';
        this.currency_right = this.currency_right === '1';
        this.default_currency_decimal_digits = Number(
            this.default_currency_decimal_digits,
        );
        this.distance_unit = this.distance_unit || 'km';
    }
}
