import localeEn from '@angular/common/locales/en';
import localeHu from '@angular/common/locales/hu';
import countryLocaleEn from 'i18n-iso-countries/langs/en.json';
import countryLocaleHu from 'i18n-iso-countries/langs/hu.json';

export type SupportedLanguagesType = 'en' | 'hu';

export const DEFAULT_LANGUAGE: SupportedLanguagesType = 'en';

const SUPPORTED_LANGUAGES_LIST: SupportedLanguagesType[] = [
    DEFAULT_LANGUAGE,
    'en',
    'hu',
];

export const SUPPORTED_LANGUAGES: SupportedLanguagesType[] = [
    ...new Set(SUPPORTED_LANGUAGES_LIST),
];

export const LOCALE_MAP = {
    en: {
        name: 'gb',
        localeFile: localeEn,
        countryLocaleFile: countryLocaleEn,
    },
    hu: {
        name: 'hu',
        localeFile: localeHu,
        countryLocaleFile: countryLocaleHu,
    },
};

export const SUPPORTED_LOCALE_DATA = SUPPORTED_LANGUAGES.map(
    (language) => LOCALE_MAP[language],
);
