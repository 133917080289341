import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SignupInterface } from '@app/shared/interface/signup.interface';
import { AppService } from '@app/shared/service/app.service';
import { environment } from '@environments/environment';
import {
    BehaviorSubject,
    Observable,
    of,
    Subscriber,
    switchMap,
    throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LoginInterface } from '../interface/login.interface';
import { ResponseInterface } from '../interface/response.interface';
import { UserModel } from '../model/user.model';

import { NavigationService } from './navigation.service';
import { NotifyService } from './notify.service';

/**
 * AuthService class provides methods for user authentication and authorization.
 */
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public userState = new BehaviorSubject<boolean>(this.isLoggedIn()!);
    public userAuthState = this.userState.asObservable();

    constructor(
        private http: HttpClient,
        private notifier: NotifyService,
        private navigationService: NavigationService,
        private appService: AppService,
        private router: Router,
    ) {}

    /**
     * Performs a login request to the specified API endpoint.
     *
     * @param {LoginInterface} data - The login data including username and password.
     * @return {Promise<any>} - A promise that resolves with the HTTP response from the server.
     */
    login(data: LoginInterface): Observable<any> {
        return this.http.post(environment.apiUrl + '/login', data);
    }

    /**
     * Logs in a user and returns a login token.
     *
     * @param {any} data - The data required for logging in the user.
     * @returns {Observable<any>} - An observable that emits the login token response.
     */
    loginToken(data: any): Observable<any> {
        return this.http.post(environment.apiUrl + '/loginToken', data);
    }

    /**
     * Fetches the current user and updates the authentication state based on the response.
     *
     * @return {void}
     */
    sync(): void {
        this.fetchCurrentUser().subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.setCurrentUser(response.data);
                this.setAuthState(true);
            }
        });
    }

    /**
     * Sign in a user with a token.
     *
     * @param apiToken - The API token used for signing in.
     *
     * @return - An Observable that emits the response data of the sign in operation.
     */
    signInByToken(apiToken: string): Observable<any> {
        return this.loginToken({ api_token: apiToken }).pipe(
            switchMap((response: ResponseInterface) => {
                if (response.success) {
                    this.notifier.showSuccess('Logged in');
                    this.appService.loginModal.close();
                    this.appService.bookingModal.close();
                    this.setCurrentUser(response.data);
                    this.setAuthState(true);
                    this.appService.stopLoader();
                    // navigate to the route that was requested before login
                    if (this.appService.routeSnapshot) {
                        this.navToRouteSnapshot();
                    } else {
                        this.navTo();
                    }
                    return of({ data: response });
                } else {
                    this.notifier.showError(response.message);
                    return throwError({ data: response });
                }
            }),
            catchError((response) => {
                return throwError({ data: response });
            }),
        );
    }

    /**
     * Signs in a user with the given login data.
     *
     * @param {LoginInterface} data - The login data of the user.
     * @returns {Observable<any>} - An observable that emits the response or error from the login API call.
     */
    signIn(data: LoginInterface): Observable<any> {
        return new Observable((observer) => {
            this.login(data).subscribe(
                (response: ResponseInterface) =>
                    this.onLoginSuccess(response, observer),
                (error) => this.onLoginError(error, observer),
            );
        });
    }

    /**
     * Handles the logic when a login attempt is successful.
     *
     * @private
     * @param {ResponseInterface} response - The response object received from the server.
     * @param {Subscriber<any>} observer - The observer object to notify of the login success.
     * @return {void}
     */
    private onLoginSuccess(
        response: ResponseInterface,
        observer: Subscriber<any>,
    ) {
        if (response.success) {
            this.notifier.showSuccess('Logged in');
            this.appService.loginModal.close();
            this.appService.bookingModal.close();
            // navigate to the route that was requested before login
            if (this.appService.routeSnapshot) {
                this.navToRouteSnapshot();
            } else {
                this.navTo();
            }
            this.setCurrentUser(response.data);
            this.setAuthState(true);
            this.appService.stopLoader();
            observer.next({ data: response });
        } else {
            this.onLoginError(response, observer);
        }
    }

    /**
     * Handle the success response for sign up.
     *
     * @private
     * @param {ResponseInterface} response - The response object from the sign up request.
     * @param {Subscriber<any>} observer - The observer to notify with the response data.
     * @return {void}
     */
    private onSignUpSuccess(
        response: ResponseInterface,
        observer: Subscriber<any>,
    ): void {
        if (response.success) {
            this.appService.stopLoader();
            this.notifier.showSuccess('Signup Success!');
            this.setCurrentUser(response.data);
            this.setAuthState(true);
            this.appService.loginModal.close();
            this.appService.bookingModal.close();
            if (this.appService.routeSnapshot) {
                this.navToRouteSnapshot();
            } else {
                this.navToThankYou();
            }
            observer.next({
                data: response,
            });
        } else {
            this.notifier.showError(response.message);
            this.appService.stopLoader();
            observer.error({
                data: response,
            });
        }
    }

    private onSignUpError(
        response: ResponseInterface,
        observer: Subscriber<any>,
    ) {
        this.notifier.showError(response.message);
        this.appService.stopLoader();
        observer.error({ data: response });
    }

    /**
     * Handles the login error response and notifies the user with an error message.
     * @private
     * @param {any} response - The response received from the server.
     * @param {Subscriber<any>} observer - The observer to emit the error to.
     * @return {void}
     */
    private onLoginError(response: any, observer: Subscriber<any>): void {
        this.notifier.showError(response.message);
        this.appService.stopLoader();
        observer.error({ data: response });
    }

    /**
     * Sign up a user with the given data.
     *
     * @param {SignupInterface | any} data - The data of the user to sign up.
     * @returns {Observable<any>} - An observable that emits the response data from the server.
     */
    signUp(data: SignupInterface | any): Observable<any> {
        return new Observable((observer) => {
            this.http.post(environment.apiUrl + '/register', data).subscribe(
                (response: ResponseInterface) =>
                    this.onSignUpSuccess(response, observer),
                (data) => this.onSignUpError(data, observer),
            );
        });
    }

    /**
     * Sign up as a guest.
     *
     * @param {SignupInterface | any} data - The data for signing up as a guest.
     * @return {Observable<any>} - An Observable that emits the response from the server.
     */
    signUpAsGuest(data: SignupInterface | any): Observable<any> {
        return new Observable((observer) => {
            this.http
                .post(environment.apiUrl + '/register/guest', data)
                .subscribe(
                    (response: ResponseInterface) =>
                        this.onSignUpSuccess(response, observer),
                    (data) => this.onSignUpError(data, observer),
                );
        });
    }

    /**
     * Sends a POST request to the '/become' endpoint with the provided data and returns an Observable that emits the response.
     *
     * @param {any} data - The data to be sent in the request body.
     * @return {Observable<any>} An Observable that emits the response of the request.
     */
    become(data: any): Observable<any> {
        return new Observable((observer) => {
            this.http.post(environment.apiUrl + '/become', data).subscribe(
                (response: ResponseInterface) =>
                    this.onSignUpSuccess(response, observer),
                (data) => this.onSignUpError(data, observer),
            );
        });
    }

    /**
     * Sends a reset link email to the specified email address.
     *
     * @param {Object} data - The data to include in the request body.
     * @return {Promise} - A Promise that resolves with the response from the server.
     */
    sendResetLinkEmail(data: object): Observable<any> {
        return this.http.post(
            environment.apiUrl + '/send_reset_link_email',
            data,
        );
    }

    /**
     * Navigates the user to a specific page based on their role.
     */
    navTo() {
        if (this.getUser().isProvider()) {
            this.navigationService.navigate(['/', 'business', 'dashboard']);
        } else {
            this.navigationService.navigate(['/']);
        }
    }

    /**
     * Navigates the user to the thank you page.
     * If the user is a provider, it navigates to the provider thank you page.
     * Otherwise, it navigates to the customer thank you page.
     * This method also closes the login modal.
     *
     * @returns {void}
     */
    navToThankYou(): void {
        if (this.getUser().isProvider()) {
            // navigate to thank you page
            this.navigationService.navigate([
                '/',
                'become-partner',
                'thank-you',
            ]);
        } else {
            this.navigationService.navigate(['/'], {
                queryParams: { customer: 'thank-you' },
            });
        }
        this.appService.loginModal.close();
        this.appService.bookingModal.close();
    }

    /**
     * Navigates to a route snapshot if available.
     *
     * @return {void} - This method does not return anything.
     */
    navToRouteSnapshot(): void {
        if (this.appService.routeSnapshot) {
            this.router.navigateByUrl(this.appService.routeSnapshot);
            this.appService.routeSnapshot = null;
        }
    }

    /**
     * Check if user is logged in.
     *
     * @returns {boolean} - Returns true if user is authenticated, otherwise returns false.
     */
    public isLoggedIn(): boolean {
        return !!this.getToken();
    }

    /**
     * Sets the authentication state.
     *
     * @param {boolean} value - The value to set the authentication state to.
     *
     * @return {void}
     */
    setAuthState(value: boolean): void {
        this.userState.next(value);
    }

    /**
     * Retrieves the role of the user.
     *
     * @returns {string} The role of the user.
     */
    getRole() {
        return '';
    }

    /**
     * Get Current Token
     *
     * @returns {any} The current token if a user is logged in, otherwise returns false.
     */
    public getToken(): any {
        const currentUser = this.getUser();
        if (currentUser) {
            return currentUser.api_token;
        }
        return false;
    }

    /**
     * Fetches the current user from the API.
     *
     * @return {Observable<User>} An observable that emits the current user object.
     */
    fetchCurrentUser(): Observable<any> {
        return this.http.get(environment.apiUrl + '/user', {
            params: {
                api_token: this.getToken(),
            },
        });
    }

    /**
     * Sets the current user in the local storage and performs missing data check.
     *
     * @param {object} response - The response object containing user data.
     * @returns {void}
     */
    public setCurrentUser(response: object): void {
        window.localStorage.setItem('currentUser', JSON.stringify(response));
        this.checkMissingData();
    }

    /**
     * Retrieves the current user from local storage.
     *
     * @returns {UserModel | any} The current user stored in local storage wrapped in a UserModel object, or false if an error occurred.
     */
    public getUser(): UserModel | any {
        try {
            return new UserModel(
                JSON.parse(window.localStorage.getItem('currentUser')),
            );
        } catch (e) {
            return false;
        }
    }

    /**
     * Logout
     *
     * @return {void}
     */
    logout(): void {
        window.localStorage.removeItem('currentUser');
        this.navigationService.navigate(['/']);
        this.appService.resetCounters();
        this.setAuthState(false);
    }

    /**
     * Checks for missing data in the user object.
     *
     * @returns {void}
     */
    checkMissingData(): void {
        const user = this.getUser();
        let missingData = [];
        for (const key in user) {
            if (user.hasOwnProperty(key)) {
                const element = user[key];

                if (
                    (!element && element !== 0 && element !== false) ||
                    (key === 'phone_number' && element === '00000000')
                ) {
                    missingData.push(key);
                }
            }
        }

        if (user.first_name === undefined) {
            missingData.push('first_name');
        }

        if (user.last_name === undefined) {
            missingData.push('last_name');
        }

        if (user.email === undefined) {
            missingData.push('email');
        }

        if (user.phone_number === undefined) {
            missingData.push('phone_number');
        }

        missingData = missingData.filter((item) => {
            return [
                'first_name',
                'last_name',
                'email',
                'phone_number',
            ].includes(item);
        });

        if (missingData.length) {
            this.appService.showModal = true;
            this.appService.showModalToobar = false;
            this.router.navigate(
                [{ outlets: { modal: ['modals', 'missing-data'] } }],
                {
                    queryParams: {
                        missingData: missingData.join(',') || '',
                    },
                },
            );
        }
    }
}
