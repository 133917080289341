import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    constructor(private http: HttpClient) {}

    getTranslations() {
        return this.http.get(environment.apiUrl + '/translations');
    }

    getLocales() {
        return this.http.get(environment.apiUrl + '/supported_locales');
    }
}
