import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class NotifyService {
    constructor(private toastCtrl: ToastController) {}

    showSuccess(message: any): void {
        message = this.getMessagesAsText(message);
        this.toastCtrl
            .create({
                message: message,
                duration: 3000,
                cssClass: 'success',
                position: 'bottom',
            })
            .then((toast) => {
                toast.present();
            });
    }

    showMessage(message: any): void {
        message = this.getMessagesAsText(message);
        this.toastCtrl
            .create({
                message: message,
                duration: 3000,
                cssClass: '',
                position: 'bottom',
            })
            .then((toast) => {
                toast.present();
            });
    }

    showError(message: any): void {
        message = this.getMessagesAsText(message);
        this.toastCtrl
            .create({
                message: message,
                cssClass: 'danger',
                duration: 3000,
                position: 'bottom',
            })
            .then((toast) => {
                toast.present();
            });
    }

    private getMessagesAsText(text) {
        if (typeof text !== 'string' && typeof text !== 'undefined') {
            return Object.values(text).join('/n');
        }

        return text;
    }
}
