import { UserModel } from '@app/shared/model/user.model';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { BehaviorSubject } from 'rxjs';

import { AddressModel } from './address.model';
import { BaseModel } from './base.model';
import { CertificateModel } from './certificate.model';
import { ExperienceModel } from './experience.model';
import { GalleryModel } from './gallery.model';
import { HighlightModel } from './highlight.model';
import { MediaModel } from './media.model';
import { SalonReviewModel } from './salon-review.model';
import { ServiceModel } from './service.model';
import { TaxModel } from './tax.model';

export class ProviderModel extends BaseModel {
    // Basic properties
    id: number;
    name: string;
    slug: string;
    owner_id: number;
    is_favorite: boolean;
    gallery_id: boolean;
    default: boolean;
    type: string;
    salon_level: any;
    salon_level_id: number;
    distance: number;
    description: string;
    email: string;
    phone_number: any;
    mobile_number: any;
    available: boolean;
    featured: boolean;
    accepted: boolean;
    custom_fields: any;
    has_media: boolean;
    rate: number;
    closed: boolean;
    ranking: number;
    has_valid_subscription: any;

    // Address properties
    address: AddressModel;
    address_id: any;
    addresses: any;

    // Collections
    total_reviews: number;
    salon_reviews: any;
    media: any;
    highlights: any;
    amenities: any;
    eservices: any;
    galleries: any;
    gallery: any;
    experiences: any;
    certificates: any;
    taxes: any;
    employees: any;

    // Employee enablement properties
    enable_at_customer_address: boolean;
    enable_at_salon: boolean;

    // Joins
    invited_at?: any;
    accepted_at?: any;
    rejected_at?: any;
    pivot_id?: any;

    // Stats
    views?: any;
    visits?: any;
    visits_in_last_hour?: any;
    views_in_last_hour?: any;
    views_in_last_day?: any;
    visits_in_last_day?: any;

    // BehaviorSubject
    change$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    // Data object
    data: {
        multi_slot_reservation: boolean;
        employee_number: number;
        criteria: {
            gender: '*';
        };
    };

    /**
     * Refactor the constructor function.
     *
     * - Added comments and docstrings to improve code readability.
     * - Reorganized the code to follow the order of the properties' initialization.
     * - Simplified the logic for initializing the address and addresses properties.
     * - Simplified the logic for initializing the media property.
     * - Simplified the logic for initializing the salon_reviews property.
     * - Simplified the logic for initializing the taxes property.
     * - Simplified the logic for initializing the employees property.
     * - Simplified the logic for initializing the highlights property.
     * - Simplified the logic for initializing the eservices property.
     * - Simplified the logic for initializing the experiences property.
     * - Simplified the logic for initializing the certificates property.
     * - Simplified the logic for initializing the galleries property.
     * - Used object spread syntax for the data property initialization.
     */

    constructor(input) {
        super(input);

        // Initialize the address property
        this.address = this.address
            ? new AddressModel(this.address)
            : undefined;

        // Initialize the addresses property
        this.addresses =
            this.addresses?.map((item) => new AddressModel(item)) || [];

        // Initialize the media property
        this.media =
            this.media
                ?.map(
                    (item, index) =>
                        new MediaModel({
                            ...item,
                            order_column: !isNaN(item.order_column)
                                ? item.order_column
                                : index,
                        }),
                )
                .sort((a, b) => a.order_column - b.order_column) || [];

        // Initialize the gallery property
        this.gallery = this.gallery
            ? new GalleryModel(this.gallery)
            : undefined;

        // Initialize the salon_reviews property
        this.salon_reviews =
            this.getTotalReviews() > 0 &&
            typeof this.salon_reviews !== 'undefined'
                ? this.salon_reviews.map(
                      (review) => new SalonReviewModel(review),
                  )
                : [];

        // Initialize the taxes property
        this.taxes = this.taxes?.map((tax) => new TaxModel(tax)) || [];

        // Initialize the employees property
        this.employees =
            this.employees?.map((employee) => new UserModel(employee)) || [];

        // Initialize the highlights property
        this.highlights =
            this.highlights?.map(
                (highlight) => new HighlightModel(highlight),
            ) || [];

        // Initialize the eservices property
        this.eservices =
            this.eservices?.map((service) => new ServiceModel(service)) || [];

        // Initialize the experiences property
        this.experiences =
            this.experiences?.map(
                (experience) => new ExperienceModel(experience),
            ) || [];

        // Initialize the certificates property
        this.certificates =
            this.certificates?.map(
                (certificate) => new CertificateModel(certificate),
            ) || [];

        // Initialize the galleries property
        this.galleries =
            this.galleries?.map((gallery) => new GalleryModel(gallery)) || [];

        // Initialize the data property with default values
        this.data = {
            multi_slot_reservation: false,
            employee_number: 1,
            criteria: {
                gender: '*', // male, female, *
            },
            ...this.data,
        };
    }

    /**
     * Get the default service.
     *
     * @returns {ServiceModel | boolean} The default service or false if no services are available.
     */
    getDefaultService(): ServiceModel | any {
        if (this.eservices && this.eservices.length > 0) {
            // Find the featured service, if any
            const featured = this.eservices.find((service) => {
                return service.featured === true;
            });

            if (featured) {
                return featured;
            }

            // If no featured service, return the first service
            return this.eservices[0];
        }

        // Return false if no services are available
        return false;
    }

    /**
     * Retrieves the address.
     *
     * @returns {AddressModel} The address.
     */
    getAddress(): AddressModel {
        return this.address;
    }

    /**
     * Retrieves the name.
     *
     * @returns {string} The name.
     */
    getName() {
        return this.name;
    }

    /**
     * Retrieves the description.
     *
     * @returns {string} The description.
     */
    getDescription() {
        return this.description;
    }

    /**
     * Retrieves the media URL.
     * Returns the URL of the first media item if available, otherwise returns false.
     *
     * @returns {string | boolean} The media URL or false.
     */
    getMedia() {
        return this.hasMedia() ? this.media[0].getUrl() : false;
    }

    /**
     * Retrieves the thumbnail URL.
     * Returns the thumbnail URL of the first media item if available, otherwise returns a default image URL.
     *
     * @returns {string} The thumbnail URL.
     */
    getThumb() {
        return this.hasMedia()
            ? this.media
                  .sort((a, b) => a.order_column - b.order_column)[0]
                  .getThumb()
            : 'assets/img/missing_image.png';
    }

    /**
     * Checks if there is any media available.
     *
     * @returns {boolean} True if there is media available, false otherwise.
     */
    hasMedia() {
        return this.media && this.has_media;
    }

    /**
     * Retrieves the marker data.
     * Returns an object containing the image URL, latitude, and longitude.
     *
     * @returns {object} The marker data.
     */
    getMarker() {
        return {
            imageUrl: this.getThumb(),
            lat: this.address.latitude,
            lng: this.address.longitude,
        };
    }

    /**
     * Retrieves the rate.
     * Returns the rate with two decimal places.
     *
     * @returns {number} The rate.
     */
    getRate() {
        return this.rate.toFixed(2);
    }

    /**
     * Retrieves the distance.
     * Returns the distance rounded to one decimal place, followed by the distance unit.
     * If the distance is not available, returns false.
     *
     * @returns {string | boolean} The distance or false.
     */
    getDistance() {
        if (this.distance) {
            return this.distance.toFixed(1) + ' '; // + AppService.getSettings().distance_unit
        }
        return false;
    }

    /**
     * Retrieves the total number of reviews.
     *
     * @returns {number} The total number of reviews.
     */
    getTotalReviews() {
        return this.total_reviews;
    }

    /**
     * Retrieves the salon reviews.
     *
     * @returns {Array} The salon reviews.
     */
    getReviews() {
        return this.salon_reviews;
    }

    /**
     * Checks if the salon is verified.
     *
     * @returns {boolean} True if the salon is verified, false otherwise.
     */
    isVerified() {
        return true;
    }

    /**
     * Retrieves the grouped availability hours.
     * Returns an object with day as key and availability hours as value.
     *
     * @returns {object} The grouped availability hours.
     */
    groupedAvailabilityHours() {
        const result = {};
        if (this.address instanceof AddressModel) {
            this.address.availabilities.forEach((element) => {
                if (result[element.day]) {
                    result[element.day] =
                        element.start_at + ' - ' + element.end_at;
                } else {
                    result[element.day] =
                        element.start_at + ' - ' + element.end_at;
                }
            });
        }

        return result;
    }
    /**
     * Parses a phone number.
     *
     * @param {string} number - The phone number to parse.
     * @returns {object} An object containing the parsed phone number details.
     */
    parsePhoneNumber(number: string) {
        if (number.indexOf('+') === -1) {
            number = '+' + number;
        }

        const phoneNumberUtil = PhoneNumberUtil.getInstance();
        const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(number);

        try {
            return {
                dialCode: phoneNumber.getCountryCode(),
                internationalNumber: phoneNumber.getRawInput(),
                isoCode: phoneNumberUtil
                    .getRegionCodeForNumber(phoneNumber)
                    .toLowerCase(),
                nationalNumber: phoneNumber.getNationalNumber().toString(),
            };
        } catch (e) {
            return {
                dialCode: '',
                internationalNumber: '',
                isoCode: '',
                nationalNumber: '',
            };
        }
    }

    /**
     * Get Provider's Criteria
     */
    getCriteria() {
        return this.data.criteria;
    }

    /**
     * Checks if the current therapist is a SuperTherapist.
     *
     * @returns {boolean} - Returns true if the current therapist is a SuperTherapist, otherwise false.
     */
    isSuperTherapist(): boolean {
        // find SuperTherapist title in hightlights
        const superTherapist = this.highlights.find((highlight) => {
            return highlight.title === 'SuperTherapist';
        });
        return !!superTherapist;
    }

    /**
     * Retrieves the SuperTherapist from the highlights array.
     *
     * @returns {Object|boolean} - The SuperTherapist object if found, otherwise false.
     */
    getSuperTherapist() {
        // find SuperTherapist title in hightlights
        const superTherapist = this.highlights.find((highlight) => {
            return highlight.title === 'SuperTherapist';
        });
        return superTherapist ? superTherapist : false;
    }

    getUrl() {
        // @username
        if (this.slug || this.id) {
            return ['/', '@' + this.slug || this.id];
        }
    }
}
