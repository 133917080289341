import { BaseModel } from './base.model';
import { CurrencyModel } from './currency.model';
import { ServiceModel } from './service.model';

export class OptionModel extends BaseModel {
    id: number;
    option_group_id: number;
    e_service_id: number;
    currency_id: number;
    currency: any;
    name: string;
    price: number;
    description: string;
    image: string;
    e_service: any;

    constructor(props) {
        super(props);

        if (this.e_service) {
            this.e_service = new ServiceModel(this.e_service);
        }

        if (this.currency) {
            this.currency = new CurrencyModel(this.currency);
        }
    }
}
