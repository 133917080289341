<ion-modal #bookingModal
           class="booking-modal"
           [initialBreakpoint]="1"
           [breakpoints]="[0, 1]">
    <ng-template>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button (click)="close()">{{ "cancel" | translate }}</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <ng-container [ngTemplateOutlet]="guest"></ng-container>
            <div class="ion-text-center ion-margin-top">
                <ion-note class="ion-color-dark">{{ 'not_member_yet' | translate }}</ion-note>
            </div>
            <ion-button [routerLink]="['/signup']" (click)="close()"
                        fill="outline"
                        style="width:100%;--background: none; --border-color: #62908EFF; --color:#62908EFF; "
                        expand="block">{{ "signup" | translate }}</ion-button>
            <div class="ion-text-center ion-margin-top">
                <ion-note class="ion-color-dark">{{ 'member_yet' | translate }}</ion-note>
            </div>
            <ion-button class="ion-margin-bottom" style="width: 100%; --background: none; --border-color: #BC72B5FF; --color:#BC72B5FF; " (click)="appService.loginModal.open()" fill="outline"
                        expand="block">{{ "login" | translate }}</ion-button>
        </ion-content>
    </ng-template>
</ion-modal>


<mbsc-popup #bookingModalMbsc [options]="popupOptions">
    <ion-grid class="ion-no-padding">
        <ion-row>

            <ion-col class="ion-padding" size="6">
                <ng-container [ngTemplateOutlet]="guest"></ng-container>
            </ion-col>
            <ion-col size="6" class="ion-padding" >
                <div class="ion-padding ion-text-center">
                    <ui-logo></ui-logo>
                </div>
                <ion-note class="ion-text-center ion-color-dark">{{ 'member_yet' | translate }}</ion-note>
                <ion-button class="ion-margin-bottom" style="width: 100%; --background: none; --border-color: #BC72B5FF; --color:#BC72B5FF; " (click)="appService.loginModal.open()" fill="outline"
                            expand="block">{{ "login" | translate }}</ion-button>
                <ion-note class="ion-text-center ion-color-dark">{{ 'not_member_yet' | translate }}</ion-note>
                <ion-button [routerLink]="['/signup']" (click)="close()"
                            fill="outline"
                            style="width:100%;--background: none; --border-color: #62908EFF; --color:#62908EFF; "
                            expand="block">{{ "signup" | translate }}</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>

</mbsc-popup>


<ng-template #login>
    <ion-note class="ion-text-center ion-color-dark">{{ 'login' | translate }}</ion-note>
    <form [formGroup]="loginForm">
        <ion-list [lines]="'full'">
            <ion-item>
                <ion-label>{{ 'email' | translate }}</ion-label>
                <ion-input [class.invalid]="email.errors && email.touched" autocomplete="off"
                           formControlName="email"></ion-input>
            </ion-item>

            <ion-item>
                <ion-label>{{ 'password' | translate }}</ion-label>
                <ion-input [class.invalid]="password.errors && password.touched" autocomplete="off"
                           type="password" formControlName="password"></ion-input>
            </ion-item>

            <ion-button appPreventDoubleClick
                        style="--background: #BC72B5FF; --background-hover: #BC72B5FF"
                        [disabled]="appService.inProgress"
                        (throttledClick)="onSignIn()"
                        [throttleTime]="3000" class="ion-margin-top" expand="block">
                <span *ngIf="!appService.inProgress">{{ "signin" | translate }}</span>
                <ion-spinner *ngIf="appService.inProgress"></ion-spinner>
            </ion-button>

            <ion-item lines="none" [routerLink]="['/', 'forgot-password'] | translateUrl">
                <ion-label class="ion-text-center">{{ 'forgot_password' | translate }}</ion-label>
            </ion-item>
          <span class="divider line one-line">{{ 'or_use_social_network' | translate }}</span>
          <ion-button style="--background: none; --background-hover:none" GoogleSigningButton></ion-button>
        </ion-list>
    </form>
</ng-template>

<ng-template #guest>
    <form [formGroup]="guestForm">
        <ion-list [lines]="'full'">
            <ion-item>
                <ion-label>{{ 'first_name' | translate }}</ion-label>
                <ion-input [class.invalid]="firstName.errors && firstName.touched" autocomplete="off"
                           formControlName="first_name"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label>{{ 'last_name' | translate }}</ion-label>
                <ion-input [class.invalid]="lastName.errors && lastName.touched" autocomplete="off"
                           formControlName="last_name"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label>{{ 'email' | translate }}</ion-label>
                <ion-input [class.invalid]="guestForm.controls.email.errors && guestForm.controls.email.touched" autocomplete="off"
                           formControlName="email"></ion-input>
            </ion-item>

            <ion-item>
                <ion-label>{{ 'gender' | translate }}</ion-label>
                <ui-chip-select [multiple]="false" formControlName="gender" >
                    <ui-chip-select-option value="*">{{ 'other' | translate }}</ui-chip-select-option>
                    <ui-chip-select-option value="male"> {{ 'male' | translate }}</ui-chip-select-option>
                    <ui-chip-select-option value="female">{{ 'female' | translate }}</ui-chip-select-option>
                </ui-chip-select>
            </ion-item>

            <ion-item>
                <ion-label position="stacked">{{ 'phone_number' | translate }}</ion-label>
                <ui-input-phone [class.invalid]="phone.errors && phone.touched" autocomplete="off"
                           formControlName="phone_number"></ui-input-phone>
            </ion-item>

            <ion-button appPreventDoubleClick
                        style="--background: #BC72B5FF; --background-hover: #BC72B5FF"
                        [disabled]="appService.inProgress"
                        (throttledClick)="onContinueAsGuest()"
                        [throttleTime]="3000" class="ion-margin-top" expand="block">
                <span *ngIf="!appService.inProgress">{{ "continue_as_guest" | translate }}</span>
                <ion-spinner *ngIf="appService.inProgress"></ion-spinner>
            </ion-button>

        </ion-list>
    </form>
</ng-template>
