import { BaseModel } from './base.model';

export class CouponModel extends BaseModel {
    id: number;
    code: string;
    description: string;
    discount: number;
    discount_type: string;
    enabled: boolean;
    expires_at: string;
    updated_at: string;
    services: any;
    salons: any;
    owner_id: number;

    constructor(props) {
        super(props);
    }

    getDescription() {
        return this.description;
    }

    isValid() {
        return this.enabled && new Date(this.expires_at) > new Date();
    }
}
