import { BaseModel } from './base.model';

export class CertificateModel extends BaseModel {
    id: number;
    title: string;
    description: string;
    owner_id: number;
    created_at: string;
    updated_at: string;

    constructor(input) {
        super(input);
    }
}
