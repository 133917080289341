<ion-modal #guideModal [initialBreakpoint]="guideService.guide.breakpoint" [breakpoints]="guideService.guide.breakpoints">
  <ng-template>
    <ion-header *ngIf="guideService.guide.header">
      <ion-toolbar >
        <ion-buttons slot="end">
          <ion-button (click)="guideService.hide()" [strong]="true">{{ "i_get_it" | translate }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" [class.ion-text-center]="guideService.guide.textAlignCenter">
      <h3 [innerHTML]="guideService.guide.title"></h3>
      <p [innerHTML]="guideService.guide.content"></p>
      <ion-button expand="block" *ngIf="guideService.guide.actionBtn.visible" (click)="guideService.guide.actionBtn.callback(); guideService.hide()">{{ guideService.guide.actionBtn.text}}</ion-button>
    </ion-content>
  </ng-template>
</ion-modal>
<ion-modal #cookieModal [initialBreakpoint]="0.33" [breakpoints]="[0, 0.33]">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="appService.denyCookies()">{{ "cookie.deny" | translate }}</ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button (click)="appService.allowCookies()" [strong]="true">{{ "cookie.allow" | translate }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <h3 class="ion-no-margin">{{ "cookie.header" | translate }}</h3>
      <p>{{ "cookie.message" | translate }}</p>
      <a href="https://www.cookiesandyou.com" rel="noopener noreferrer nofollow" target="_blank">{{ "cookie.link" | translate }}</a >
    </ion-content>
  </ng-template>
</ion-modal>
<ion-modal #routeModal (didDismiss)="appService.showModal = false"
           [isOpen]="appService.showModal"
           [backdropDismiss]="appService.showModalToobar"
           [canDismiss]="appService.showModalToobar"
           [initialBreakpoint]="1"
           [breakpoints]="[0, 0.33, 0.5, 0.75, 1]">
  <ng-template>
    <ion-header>
      <ion-toolbar *ngIf="appService.showModalToobar">
        <ion-buttons slot="start">
          <ion-button (click)="appService.showModal = false">{{ "cancel" | translate }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <router-outlet name="modal"></router-outlet>
    </ion-content>
  </ng-template>
</ion-modal>




