import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService } from '../service/auth.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const accessToken = this.authService.getToken();
        if (typeof accessToken !== 'undefined') {
            req = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + accessToken,
                },
            });
        }
        return next.handle(req);
    }
}
