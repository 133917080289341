import { BaseModel } from './base.model';

export class TaxModel extends BaseModel {
    id: number;
    name: string;
    type: string;
    value: number;
    owner_id: number;
    created_at: string;
    updated_at: string;

    constructor(props) {
        super(props);
    }
}
