import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private http: HttpClient) {}

    /**
     * Get User
     * @param id
     */
    get(id: number) {
        const queryParams = {
            with: 'roles;media;salons',
        };
        return this.http.get(environment.apiUrl + '/auth/users/' + id, {
            params: queryParams,
        });
    }

    getUser() {
        return this.http.get(environment.apiUrl + '/auth/user/');
    }

    /**
     * Update User
     * @param id
     * @param data
     */
    update(id: number, data: any) {
        data['id'] = id;
        return this.http.put(environment.apiUrl + '/auth/users/' + id, data);
    }

    /**
     * Sets the last activity for a given ID.
     *
     * @param {string} id - The ID to set the last activity for.
     *
     * @return {Promise} - A Promise representing the HTTP request to update the last activity.
     */
    setLastActivity(id: string): Observable<any> {
        // check id not undefined
        if (!id) {
            return;
        }
        const data = {
            last_activity: new Date(),
        };
        return this.http.put(
            environment.apiUrl + '/auth/lastActivity/' + id,
            data,
        );
    }

    /**
     * Create User
     * @param data
     */
    create(data: any) {
        return this.http.post(environment.apiUrl + '/auth/users', data);
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + '/auth/users/' + id);
    }

    checkEmailExists(email: string) {
        const queryParams = {
            email: email,
        };
        return this.http.get(environment.apiUrl + '/check_email_exists', {
            params: queryParams,
        });
    }
    checkPhoneExists(phone: string) {
        const queryParams = {
            phone_number: phone,
        };
        return this.http.get(environment.apiUrl + '/check_phone_exists', {
            params: queryParams,
        });
    }
}
