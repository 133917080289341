import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationService } from '@app/shared/service/navigation.service';

import { ModalsPage } from './modals.page';

const routes: Routes = [
    {
        path: '',
        component: ModalsPage,
    },
    ...NavigationService.generateTranslatedRouteConfigs('addresses/create', {
        loadChildren: () =>
            import(
                './account/addresses/address-create/address-create.module'
            ).then((m) => m.AddressCreatePageModule),
    }),
    ...NavigationService.generateTranslatedRouteConfigs('addresses/edit', {
        loadChildren: () =>
            import('./account/addresses/address-edit/address-edit.module').then(
                (m) => m.AddressEditPageModule,
            ),
    }),
    ...NavigationService.generateTranslatedRouteConfigs('services/create', {
        loadChildren: () =>
            import(
                './business/services/service-create/service-create.module'
            ).then((m) => m.ServiceCreatePageModule),
    }),
    ...NavigationService.generateTranslatedRouteConfigs('services/edit', {
        loadChildren: () =>
            import('./business/services/service-edit/service-edit.module').then(
                (m) => m.ServiceEditPageModule,
            ),
    }),
    ...NavigationService.generateTranslatedRouteConfigs(
        'addresses/create-simple',
        {
            loadChildren: () =>
                import(
                    './account/addresses/address-create-simple/address-create.module'
                ).then((m) => m.AddressCreatePageModule),
        },
    ),
    ...NavigationService.generateTranslatedRouteConfigs(
        'availabilities/create',
        {
            loadChildren: () =>
                import(
                    './account/availabilities/availability-create/availability-create.module'
                ).then((m) => m.AvailabilityCreatePageModule),
        },
    ),

    ...NavigationService.generateTranslatedRouteConfigs('amenities/create', {
        loadChildren: () =>
            import(
                './account/amenities/amenity-create/amenity-create.module'
            ).then((m) => m.AmenityCreatePageModule),
    }),
    ...NavigationService.generateTranslatedRouteConfigs('taxes/create', {
        loadChildren: () =>
            import('./account/taxes/tax-create/tax-create.module').then(
                (m) => m.TaxCreatePageModule,
            ),
    }),
    ...NavigationService.generateTranslatedRouteConfigs('experiences/create', {
        loadChildren: () =>
            import(
                './business/experiences/experience-create/experience-create.module'
            ).then((m) => m.ExperienceCreatePageModule),
    }),
    ...NavigationService.generateTranslatedRouteConfigs('certificates/create', {
        loadChildren: () =>
            import(
                './business/certificates/certificate-create/certificate-create.module'
            ).then((m) => m.CertificateCreatePageModule),
    }),
    ...NavigationService.generateTranslatedRouteConfigs('galleries/create', {
        loadChildren: () =>
            import(
                './business/galleries/gallery-create/gallery-create.module'
            ).then((m) => m.GalleryCreatePageModule),
    }),
    ...NavigationService.generateTranslatedRouteConfigs('requests/create', {
        loadChildren: () =>
            import(
                './account/requests/request-create/request-create.module'
            ).then((m) => m.RequestCreatePageModule),
    }),
    ...NavigationService.generateTranslatedRouteConfigs('options/create', {
        loadChildren: () =>
            import('./account/options/option-create/option-create.module').then(
                (m) => m.OptionCreatePageModule,
            ),
    }),
    ...NavigationService.generateTranslatedRouteConfigs('missing-data', {
        loadChildren: () =>
            import('./account/missing-data/missing-data.module').then(
                (m) => m.MissingDataModule,
            ),
    }),
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ModalsPageRoutingModule {}
