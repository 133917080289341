import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    constructor(private http: HttpClient) {}

    getMethods() {
        const queryParams = {};
        return this.http.get(environment.apiUrl + '/payment_methods', {
            params: queryParams,
        });
    }

    getStatuses() {
        const queryParams = {};
        return this.http.get(environment.apiUrl + '/payment_statuses', {
            params: queryParams,
        });
    }
}
