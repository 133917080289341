import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ToastController } from '@ionic/angular';

export interface AllValidationErrors {
    control_name: string;
    error_name: string;
    error_value: any;
}

export interface FormGroupControls {
    [key: string]: AbstractControl;
}

export function getFormValidationErrors(
    controls: FormGroupControls,
): AllValidationErrors[] {
    let errors: AllValidationErrors[] = [];
    Object.keys(controls).forEach((key) => {
        const control = controls[key];
        if (control instanceof FormGroup) {
            errors = errors.concat(getFormValidationErrors(control.controls));
        }
        const controlErrors: ValidationErrors = controls[key].errors;
        if (controlErrors !== null) {
            Object.keys(controlErrors).forEach((keyError) => {
                errors.push({
                    control_name: key,
                    error_name: keyError,
                    error_value: controlErrors[keyError],
                });
            });
        }
    });
    return errors;
}

export function getErrorText(error) {
    let text;
    switch (error.error_name) {
        case 'required':
            text = `${error.control_name} is required!`;
            break;
        case 'pattern':
            text = `${error.control_name} has wrong pattern!`;
            break;
        case 'email':
            text = `${error.control_name} has wrong email format!`;
            break;
        case 'minlength':
            text = `${error.control_name} has wrong length! Required length: ${error.error_value.requiredLength}`;
            break;
        case 'areEqual':
            text = `${error.control_name} must be equal!`;
            break;
        case 'isValid':
            text = `${error.control_name} is not valid!`;
            break;
        default:
            text = `${error.control_name}: ${error.error_name}: ${error.error_value}`;
    }
    return text ? text : '';
}

export function showErrorMessages(errors) {
    const toastCtrl = new ToastController();

    toastCtrl
        .create({
            message: getErrorText(errors.shift()),
            cssClass: 'danger',
            duration: 3000,
            position: 'bottom',
        })
        .then((toast) => {
            toast.present();
        });
}
