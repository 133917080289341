import { BaseModel } from './base.model';

export class AmenityModel extends BaseModel {
    id: number;
    title: string;
    desc: string;
    icon: string;
    salon_id: string;
    owner_id: number;
    created_at: string;
    updated_at: string;

    constructor(input) {
        super(input);

        this.setIcon();
    }

    get isDefaultAmenity() {
        return !this.owner_id && this.owner_id !== 0;
    }

    get pureKey() {
        return this.title?.toLowerCase().trim().replace(/\W/g, '_');
    }

    get translationKey() {
        return `amenities_collection.${this.pureKey}`;
    }

    setIcon() {
        if (this.icon) {
            return;
        }

        switch (this.pureKey) {
            case 'air_condition':
            case 'air_conditioning':
                this.icon = 'snow-outline';
                break;
            case 'ambient_lights':
                this.icon = 'bulb-outline';
                break;
            case 'free_parking':
                this.icon = 'car-outline';
                break;
            case 'heated_treatment_beds':
                this.icon = 'bed-outline';
                break;
            case 'paid_parking':
                this.icon = 'car-sport-outline';
                break;
            case 'private_room':
                this.icon = 'lock-closed-outline';
                break;
            case 'relax_music':
                this.icon = 'musical-notes-outline';
                break;
            case 'shower':
                this.icon = 'water-outline';
                break;
            case 'total_silence':
                this.icon = 'volume-mute-outline';
                break;
            case 'towel_warmer':
                this.icon = 'thermometer-outline';
                break;
            case 'tv':
                this.icon = 'tv-outline';
                break;
            case 'wifi':
                this.icon = 'wifi-outline';
                break;
            default:
                this.icon = 'checkmark-circle-outline';
                break;
        }
    }
}
