import { BaseModel } from './base.model';
import { MediaModel } from './media.model';

export class CategoryModel extends BaseModel {
    id: any;
    name: string;
    color: string;
    description: string;
    image: string;
    featured: boolean;
    order: number;
    parent_id: number;
    media: any;
    has_media: boolean;
    sub_categories: any;
    transKey: string;
    icon: any;

    constructor(input) {
        super(input);

        if (this.hasMedia()) {
            const media = [...this.media]
                .map(
                    (item, index) =>
                        new MediaModel({
                            ...item,
                            order_column: !isNaN(item.order_column)
                                ? item.order_column
                                : index,
                        }),
                )
                .sort((a, b) => a.order_column - b.order_column);

            this.media = [...media];
        }

        if (typeof this.sub_categories !== 'undefined') {
            this.sub_categories = this.sub_categories.map(
                (cat) => new CategoryModel(cat),
            );
        } else {
            this.sub_categories = [];
        }
    }

    getName() {
        return this.name;
    }

    hasMedia() {
        return this.has_media;
    }
}
