import { BaseModel } from './base.model';

export class CurrencyModel extends BaseModel {
    id: number;
    name: string;
    symbol: string;
    code: string;
    exchange_rate: string;
    decimal_digits: number;
    active: boolean;
    rounding: number;
    right: boolean;
    format: any;
    created_at: string;
    updated_at: string;

    constructor(input) {
        super(input);

        if (!this.right) {
            this.right = false;
        }

        switch (this.code) {
            case 'HUF':
                this.right = true;
                this.decimal_digits = 0;
                break;
        }
    }
}
