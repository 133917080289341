import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    constructor(private http: HttpClient) {}

    getCategories(id?: number) {
        const queryParams = {
            with: 'subCategories',
            parent: 'true',
        };

        if (id) {
            queryParams['search'] = 'id:' + id;
        }
        return this.http.get(environment.apiUrl + '/categories', {
            params: queryParams,
        });
    }

    getCategoryById(id: number) {
        const queryParams = {
            with: 'subCategories',
            parent: 'true',
        };

        return this.http.get(environment.apiUrl + '/categories/' + id, {
            params: queryParams,
        });
    }
}
