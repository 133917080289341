import { Injectable } from '@angular/core';
import { distinctUntilChanged, Observable, ReplaySubject } from 'rxjs';

export type SupportedThemesType = 'light' | 'dark' | 'auto';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    static LIGHT_THEME: SupportedThemesType = 'light';
    static DARK_THEME: SupportedThemesType = 'dark';

    private _currentTheme: SupportedThemesType;
    private _activeTheme$ = new ReplaySubject<string>(1);

    constructor() {
        this.activeTheme = this.getPreferredTheme();
    }

    getPreferredTheme() {
        return window?.matchMedia('(prefers-color-scheme: dark)').matches
            ? ThemeService.DARK_THEME
            : ThemeService.LIGHT_THEME;
    }

    get currentTheme() {
        return this._currentTheme;
    }

    set currentTheme(val: SupportedThemesType) {
        this._currentTheme = val;
        this._activeTheme$.next(val);
    }

    get activeTheme$(): Observable<string> {
        return this._activeTheme$.asObservable().pipe(distinctUntilChanged());
    }

    set activeTheme(val: SupportedThemesType) {
        this.currentTheme = val;
    }
}
