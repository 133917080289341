import {
    DEFAULT_CURRENCY_CODE,
    DEFAULT_DISTANCE_UNIT,
    DEFAULT_HEIGHT_UNIT,
    DEFAULT_WEIGHT_UNIT,
    SupportedCurrencyCodeType,
    SupportedDistanceUnitType,
    SupportedHeightUnitType,
    SupportedWeightUnitType,
} from '@app/shared/constant/localization-metrics.constant';
import {
    DEFAULT_LANGUAGE,
    SupportedLanguagesType,
} from '@app/shared/constant/localization.constant';

import { BaseModel } from './base.model';

export class AppSettingModel extends BaseModel {
    locale: SupportedLanguagesType;
    currency_code: SupportedCurrencyCodeType;
    cookie_policy: boolean;
    cookie_decide: boolean;
    dark_mode: boolean;
    distance_unit: SupportedDistanceUnitType;
    weight_unit: SupportedWeightUnitType;
    height_unit: SupportedHeightUnitType;
    dial_code: number;

    constructor(input) {
        super(input);

        if (!this.locale) {
            this.locale = DEFAULT_LANGUAGE;
        }

        if (!this.currency_code) {
            this.currency_code = DEFAULT_CURRENCY_CODE;
        }

        if (!this.cookie_policy) {
            this.cookie_policy = false;
        }

        if (!this.cookie_decide) {
            this.cookie_decide = false;
        }

        if (!this.dark_mode) {
            this.dark_mode = null;
        }

        if (!this.distance_unit) {
            this.distance_unit = DEFAULT_DISTANCE_UNIT;
        }

        if (!this.weight_unit) {
            this.weight_unit = DEFAULT_WEIGHT_UNIT;
        }

        if (!this.height_unit) {
            this.height_unit = DEFAULT_HEIGHT_UNIT;
        }

        if (!this.dial_code) {
            this.dial_code = null;
        }
    }
}
