import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { __ } from '@app/global';
import {
    getFormValidationErrors,
    showErrorMessages,
} from '@app/shared/form-validation-errors';
import { AppService } from '@app/shared/service/app.service';
import { AuthService } from '@app/shared/service/auth.service';
import { NotifyService } from '@app/shared/service/notify.service';
import { IonModal, Platform } from '@ionic/angular';
import { MbscPopup, MbscPopupOptions } from '@mobiscroll/angular';

@Component({
    selector: 'ui-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss'],
})
export class UiLoginModalComponent implements AfterViewInit {
    @ViewChild('loginModal') loginModal: IonModal;
    @ViewChild('loginModalMbsc') loginModalMbsc: MbscPopup;
    popupOptions: MbscPopupOptions = {
        theme: 'ios',
        buttons: ['cancel'],
        width: 800,
        maxWidth: 800,
        locale: this.appService.getLocale(),
    };
    loginForm = new FormGroup({
        password: new FormControl('', Validators.required),
        email: new FormControl('', Validators.required),
    });
    constructor(
        private platform: Platform,
        private authService: AuthService,
        public appService: AppService,
        private notify: NotifyService,
    ) {}

    ngAfterViewInit() {
        this.appService.loginModal = this.loginModal;
    }

    close() {
        if (this.platform.is('desktop')) {
            this.loginModalMbsc.close();
        } else {
            this.loginModal.dismiss();
        }
    }

    open() {
        if (this.platform.is('desktop')) {
            this.loginModalMbsc.open();
        } else {
            this.loginModal.present();
        }
    }

    onSignIn() {
        if (this.loginForm.valid) {
            this.appService.showLoader();
            this.authService
                .signIn({
                    email: this.loginForm.value.email,
                    password: this.loginForm.value.password,
                })
                .subscribe(
                    () => {
                        this.appService.stopLoader();
                    },
                    () => {
                        this.notify.showError(__('login_error'));
                        this.appService.stopLoader();
                    },
                );
        } else {
            showErrorMessages(getFormValidationErrors(this.loginForm.controls));
        }
    }

    get email() {
        return this.loginForm.get('email');
    }

    get password() {
        return this.loginForm.get('password');
    }
}
