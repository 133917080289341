import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { AppService } from '@app/shared/service/app.service';
import { GuideService } from '@app/shared/service/guide.service';

@Component({
    selector: 'ui-modals',
    templateUrl: './modals.component.html',
    styleUrls: ['./modals.component.scss'],
})
export class UiModalsComponent implements AfterViewInit {
    @ViewChild('cookieModal') cookieModal: any;
    @ViewChild('guideModal') guideModal: any;
    @ViewChild(RouterOutlet) outlet: RouterOutlet;

    constructor(
        public appService: AppService,
        public guideService: GuideService,
        private router: Router,
    ) {}

    ngOnInit() {
        // deactivate modal outlet on route change
        this.router.events.subscribe((e) => {
            if (e instanceof ActivationStart && e.snapshot.outlet === 'modal')
                this.outlet.deactivate();
        });
    }

    ngAfterViewInit() {
        // Set Modal Ref.
        if (this.cookieModal) {
            this.appService.cookieModal = this.cookieModal;
        }
        // Set Modal Ref.
        if (this.guideModal) {
            this.guideService.guideModal = this.guideModal;
        }
    }
}
