import { BaseModel } from './base.model';
import { MediaModel } from './media.model';
import { RoleModel } from './role.model';

interface CountersInterface {
    salons: number;
    eServices: number;
    experiences: number;
    certificates: number;
}

export class UserModel extends BaseModel {
    id: number;
    name: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: any;
    phone_verified_at: string;
    email_verified_at: string;
    api_token: string;
    allergy: any;
    birth_date: string;
    weight: number;
    weight_type: string;
    height: number;
    height_type: string;
    diseases: any;
    gender: any;
    device_token: string;
    created_at: string;
    updated_at: string;
    custom_fields: any;
    has_media: boolean;
    roles: any;
    media: any[];
    counters: CountersInterface;
    salons: any[];
    address: any;
    locale: string; // default: en
    private default_avatar: 'https://api.trandth.com/images/avatar_default.png';

    constructor(input) {
        super(input);
        this.default_avatar =
            'https://api.trandth.com/images/avatar_default.png';
        if (this.hasMedia()) {
            const media = [...this.media]
                .map(
                    (item, index) =>
                        new MediaModel({
                            ...item,
                            order_column: !isNaN(item.order_column)
                                ? item.order_column
                                : index,
                        }),
                )
                .sort((a, b) => a.order_column - b.order_column);

            this.media = [...media];
        } else {
            this.has_media = true;
            this.media = [
                new MediaModel({
                    url: this.default_avatar,
                    thumb: this.default_avatar,
                    large: this.default_avatar,
                    order_column: 1,
                }),
            ];
        }

        if (this.roles) {
            this.roles = this.roles.map((role) => {
                return new RoleModel(role);
            });
        }

        if (!this.gender) {
            this.gender = undefined;
        }

        if (!this.weight) {
            this.weight = 0;
        }

        if (!this.weight_type) {
            this.weight_type = 'kg';
        }

        if (!this.height) {
            this.height = 0;
        }

        if (!this.height_type) {
            this.height_type = 'cm';
        }

        if (!this.diseases) {
            this.diseases = '';
        }

        if (!this.allergy) {
            this.allergy = '';
        }

        if (!this.counters) {
            this.counters = {
                salons: 0,
                eServices: 0,
                experiences: 0,
                certificates: 0,
            };
        }
    }

    getThumb() {
        return this.hasMedia()
            ? this.media
                  .sort((a, b) => a.order_column - b.order_column)[0]
                  .getThumb()
            : false;
    }

    getMedia() {
        return this.hasMedia() ? this.media[0].getUrl() : false;
    }

    hasMedia() {
        return this.media && this.has_media;
    }

    getFullName() {
        return this.first_name + ' ' + this.last_name;
    }

    getRoles() {
        return this.roles;
    }

    getAvatar() {
        return this.getMedia();
    }

    getRole() {
        if (this.isCustomer()) {
            return 'customer';
        } else if (this.isProvider()) {
            return 'provider';
        } else {
            return '';
        }
    }

    hasRole(roles: any) {
        return (
            this.roles.filter((role) => {
                return roles.includes(role.name);
            }).length > 0
        );
    }

    isProvider() {
        return !!this.roles?.find((role) => {
            return role.name === 'provider';
        });
    }

    isCustomer() {
        return !!this.roles?.find((role) => {
            return role.name === 'customer';
        });
    }

    isAdmin() {
        return !!this.roles?.find((role) => {
            return role.name === 'admin';
        });
    }

    getCompletion() {
        const step = 10;
        let steps = 7;
        let total = 0;
        if (this.isProvider()) {
            steps = 11;
            if (this.counters.salons > 0) {
                total += step;
            }

            if (this.counters.experiences > 0) {
                total += step;
            }

            if (this.counters.certificates > 0) {
                total += step;
            }

            if (this.counters.eServices > 0) {
                total += step;
            }
        }

        // profile information

        if (this.first_name) {
            total += step;
        }

        if (this.last_name) {
            total += step;
        }

        if (this.phone_number) {
            total += step;
        }

        if (this.gender) {
            total += step;
        }

        if (this.birth_date) {
            total += step;
        }

        if (this.weight && this.weight_type) {
            total += step;
        }

        if (this.height && this.height_type) {
            total += step;
        }

        return Number(((total / (step * steps)) * 100).toFixed(1));
    }

    getCompletionPersonalInformation(): number {
        const step = 10;
        const steps = 7;
        let total = 0;

        if (this.first_name) {
            total += step;
        }

        if (this.last_name) {
            total += step;
        }

        if (this.phone_number) {
            total += step;
        }

        if (this.gender) {
            total += step;
        }
        if (this.birth_date) {
            total += step;
        }

        if (this.weight && this.weight_type) {
            total += step;
        }
        if (this.height && this.height_type) {
            total += step;
        }
        return Number(((total / (step * steps)) * 100).toFixed(1));
    }

    getCompletionProvider(): number {
        const step = 10;
        const steps = 4;
        let total = 0;

        if (this.isProvider()) {
            if (this.counters.salons > 0) {
                total += step;
            }

            if (this.counters.experiences > 0) {
                total += step;
            }

            if (this.counters.certificates > 0) {
                total += step;
            }

            if (this.counters.eServices > 0) {
                total += step;
            }
        }

        return Number(((total / (step * steps)) * 100).toFixed(1));
    }
}
