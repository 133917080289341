import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpParams,
} from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { AppService } from '@app/shared/service/app.service';
import { AuthService } from '@app/shared/service/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * This class is for intercepting http requests. When a request starts, we set the loadingSub property
 * in the LoadingService to true. Once the request completes and we have a response, set the loadingSub
 * property to false. If an error occurs while servicing the request, set the loadingSub property to false.
 * @class {HttpRequestInterceptor}
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    constructor(
        private appService: AppService,
        private authService: AuthService,
    ) {}
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        let params = request.params ? request.params : new HttpParams();
        params = params.set('locale', this.appService.getLocale());

        if (isDevMode()) {
            params = params.set('XDEBUG_SESSION_START', 'PHPSTORM');
        }

        const req = request.clone({ params });

        return this.handleRequest(next, req);
    }

    private handleRequest(
        next: HttpHandler,
        request: HttpRequest<any>,
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => this.handleError(err)),
            map((evt) => this.handleResponse(evt)),
        );
    }

    private handleError(err: any): Observable<HttpEvent<any>> {
        this.appService.stopLoader();
        // erros status 401 and 403 logout
        if (err.status === 401) {
            this.authService.logout();
        }
        return throwError(err);
    }

    private handleResponse(evt: HttpEvent<any>): HttpEvent<any> {
        return evt;
    }
}
