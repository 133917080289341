import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemeService } from '@app/shared/service/theme.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ui-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
})
export class UiLogoComponent implements OnInit, OnDestroy {
    iconSrc: string;
    private subscriptions = new Subscription();
    constructor(private themeService: ThemeService) {}

    ngOnInit() {
        this.subscriptions.add(
            this.themeService.activeTheme$.subscribe((theme) => {
                switch (theme) {
                    case ThemeService.DARK_THEME:
                        this.iconSrc = '/assets/logo/icon-white.svg';
                        break;
                    case ThemeService.LIGHT_THEME:
                        this.iconSrc = '/assets/logo/icon-black.svg';
                        break;
                }
            }),
        );
    }

    ngOnDestroy() {
        this.subscriptions?.unsubscribe();
    }
}
