import { SocialAuthService } from '@abacritt/angularx-social-login';
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { __ } from '@app/global';
import { CategoryModel } from '@app/shared/model/category.model';
import { CurrencyModel } from '@app/shared/model/currency.model';
import { ProviderModel } from '@app/shared/model/provider.model';
import { SettingModel } from '@app/shared/model/setting.model';
import { BackgroundService } from '@app/shared/service/background.service';
import { BookingService } from '@app/shared/service/booking.service';
import { CategoryService } from '@app/shared/service/category.service';
import { CurrencyService } from '@app/shared/service/currency.service';
import { HelperService } from '@app/shared/service/helper.service';
import { NavigationService } from '@app/shared/service/navigation.service';
import { PaymentService } from '@app/shared/service/payment.service';
import { ProviderService } from '@app/shared/service/provider.service';
import { ThemeService } from '@app/shared/service/theme.service';
import { TranslationService } from '@app/shared/service/translation.service';
import { StatusBar } from '@capacitor/status-bar';
import { environment } from '@environments/environment';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { inject } from '@vercel/analytics';
import posthog from 'posthog-js';
import { filter, forkJoin, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { register } from 'swiper/element/bundle';

import { AppService } from './shared/service/app.service';
import { AuthService } from './shared/service/auth.service';
import { GuideService } from './shared/service/guide.service';

inject();
register();
if (environment.production) {
    posthog.init('phc_ND5SEKQqzXpurbQ34fIjVaFub5pu23lfst2SbscyEle', {
        api_host: 'https://eu.posthog.com',
    });
}
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    /**
     * Represents the login modal element reference.
     *
     * @typedef {ElementRef} loginModal
     * @property {string} nativeElement - The native element reference to the login modal element.
     */
    @ViewChild('uiLoginModal') loginModal: ElementRef;
    @ViewChild('uiBookingModal') bookingModal: ElementRef;
    /**
     * @typedef {string} activeTheme
     * Represents the currently active theme.
     */
    activeTheme: string;
    /**
     * Represents the current year.
     *
     * @const {number}
     * @readonly
     */
    year = new Date().getFullYear();

    /**
     * Creates an instance of the constructor.
     *
     * @param {AuthService} authService - The authentication service.
     * @param {AppService} appService - The app service.
     * @param {GuideService} guideService - The guide service.
     * @param {Platform} platform - The platform.
     * @param {BackgroundService} backgroundService - The background service.
     * @param {ThemeService} themeService - The theme service.
     * @param {BookingService} bookingService - The booking service.
     * @param {CurrencyService} currencyService - The currency service.
     * @param {PaymentService} paymentService - The payment service.
     * @param {CategoryService} categoryService - The category service.
     * @param {TranslationService} translateService - The translation service.
     * @param {TranslateService} translate - The translate service.
     * @param {HelperService} helper - The helper service.
     * @param {SocialAuthService} socialAuthService - The social authentication service.
     * @param {Router} router - The router service.
     * @param {ActivatedRoute} activatedRoute - The activated route.
     * @param {NavigationService} navigationService - The navigation service.
     *
     * @param providerService
     */
    constructor(
        public authService: AuthService,
        public appService: AppService,
        public guideService: GuideService,
        public platform: Platform,
        private backgroundService: BackgroundService,
        private themeService: ThemeService,
        private bookingService: BookingService,
        private currencyService: CurrencyService,
        private paymentService: PaymentService,
        private categoryService: CategoryService,
        private translateService: TranslationService,
        private translate: TranslateService,
        private helper: HelperService,
        private socialAuthService: SocialAuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private navigationService: NavigationService,
        private providerService: ProviderService,
    ) {
        // global translation function
        window['__'] = this.translate.instant.bind(this.translate);
    }

    /**
     * Initializes the component.
     *
     * @returns {void}
     */
    ngOnInit(): void {
        this.logAppInit();
        this.handleTokenAuthentication();
        this.syncUserData();
        this.setActiveTheme();
        this.initBackgroundServices();
        this.setupRouteNavigation();
        this.processSocialAuthState();
        this.processAuthState();
        this.fetchAppData();
    }

    /**
     * Logs the initialization of the app.
     * @returns {void}
     */
    private logAppInit(): void {
        console.info('App::Init');
    }

    /**
     * Handles token authentication.
     *
     * When the token parameter is present in the query parameters, it calls the `signInByToken` method of the `authService`
     * to sign in with the token. If signing in is successful, it calls the `navigateOnLogin` method to navigate to a specific
     * route. If signing in fails, it navigates to the '/' route.
     *
     * @returns {void}
     */
    private handleTokenAuthentication(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (typeof params.token !== 'undefined') {
                // logged in logout
                if (this.authService.isLoggedIn()) {
                    this.authService.logout();
                }

                this.authService.signInByToken(params.token).subscribe(
                    () => this.navigateOnLogin(params),
                    () => this.navigationService.navigate('/'),
                );
            }
        });
    }

    /**
     * Navigates to the specified destination after login.
     *
     * @param {Object} params - The parameters for navigation.
     * @param {string} params.nav - The destination to navigate to. If not provided, it defaults to '/'.
     *
     * @return {void} - This method doesn't return any value.
     */
    private navigateOnLogin(params): void {
        const destination =
            typeof params.nav !== 'undefined' ? params.nav : '/';
        this.navigationService.navigate(destination);
    }

    /**
     * Synchronizes user data.
     *
     * @returns {void}
     */
    private syncUserData(): void {
        if (this.authService.isLoggedIn()) {
            this.authService.sync();
        }
    }

    /**
     * Sets the active theme based on the value emitted by the themeService.
     *
     * @returns {void}
     */
    private setActiveTheme(): void {
        this.themeService.activeTheme$.subscribe((theme) => {
            this.activeTheme = `theme-${theme}`;
        });
    }

    /**
     * Initializes background services.
     *
     * @return {void}
     */
    private initBackgroundServices(): void {
        if (this.platform.is('android') || this.platform.is('ios')) {
            this.setBackgroundStatusBarColor();
        }

        this.backgroundService.checkNotifications();
        this.backgroundService.checkActivity();
        this.backgroundService.checkMessages();
    }

    /**
     * Sets the background color of the status bar.
     * If the StatusBar object is available in the environment, it will be used to set the color.
     *
     * @returns {void} Nothing is returned.
     */
    private setBackgroundStatusBarColor(): void {
        if (StatusBar) {
            StatusBar.setBackgroundColor({
                color: '#784BA0',
            });
        }
    }

    /**
     * Sets up route navigation by subscribing to router events and
     * invoking the handleRouteChange method when a NavigationEnd event occurs.
     *
     * @returns {void}
     */
    private setupRouteNavigation(): void {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => this.handleRouteChange());
    }

    /**
     * Handles route change.
     *
     * @returns {void}
     */
    private handleRouteChange(): void {
        if (this.authService.isLoggedIn()) {
            this.backgroundService.notifications$.next(true);
            this.backgroundService.messages$.next(true);
        }
    }

    /**
     * Processes the social authentication state.
     *
     * This method is responsible for handling the authentication state returned by the social authentication service.
     * It signs in the user by calling the `signInByToken` method of the authentication service. If the sign-in is successful,
     * it logs a "Logged in" message in the console. If the sign-in fails, it calls the `handleSocialAuthSignUp` method,
     * passing the user object.
     *
     * @returns {void} This method does not return anything.
     */
    private processSocialAuthState(): void {
        this.socialAuthService.authState.subscribe((user) => {
            this.authService.signInByToken(user.id).subscribe(
                () => console.info('Logged in'),
                () => this.handleSocialAuthSignUp(user),
            );
        });
    }

    /**
     * Processes the authentication state.
     *
     * This method subscribes to the userAuthState observable and performs specific actions based on the user's authentication state.
     *
     * @returns {void}
     */
    private processAuthState(): void {
        this.authService.userAuthState.subscribe((user: any) => {
            if (user) {
                if (
                    this.authService.getUser().isProvider() &&
                    !this.appService.getCurrentProvider()
                ) {
                    this.getDefaultProvider().subscribe();
                }
                if (window['HelpWidget']) {
                    window['HelpWidget']('update', {
                        name: this.authService.getUser().name,
                        email: this.authService.getUser().email,
                        //id: this.authService.getUser().id, // optional
                        show: false, // hide name and email fields on contact form
                    });
                }
            } else {
                if (window['HelpWidget']) {
                    window['HelpWidget']('user', {
                        name: '',
                        email: '',
                        show: true, // hide name and email fields on contact form
                    });
                }
            }
        });
    }

    private getDefaultProvider(): Observable<boolean> {
        return this.providerService.getOwnDefaultProvider().pipe(
            tap((response) => {
                if (response.success) {
                    this.appService.setCurrentProvider(
                        new ProviderModel(response.data),
                    );
                } else {
                    this.appService.setCurrentProvider(null);
                }
                this.appService.stopLoader();
            }),
            map((response) => !!response.success),
        );
    }

    /**
     * Handles the process of signing up a user using social authentication.
     * Generates a random password using the helper class, builds the user sign up data,
     * and then calls the signUp method of the authService to initiate the sign up process.
     * Upon successful sign up, it navigates to the main page.
     *
     * @param {Object} user - The user object containing the social authentication details.
     * @returns {void} - This method does not return anything.
     */
    private handleSocialAuthSignUp(user: any): void {
        const pass = this.helper.makePassword();
        this.authService
            .signUp(this.buildSocialUserSignUpData(user, pass))
            .subscribe(() => {
                // navigate route snapshot url
                if (this.appService.routeSnapshot) {
                    this.authService.navToRouteSnapshot();
                } else {
                    this.authService.navTo();
                }
            });
    }

    /**
     * Builds the sign up data for a social user.
     *
     * @param {object} user - The user object.
     * @param {string} pass - The password for the user.
     * @return {object} - The sign up data for the social user.
     *
     * @example
     * // Usage example
     *
     * const user = {
     *    firstName: 'John',
     *    lastName: 'Doe',
     *    email: 'johndoe@example.com',
     *    id: '1234567890'
     * };
     *
     * const signUpData = buildSocialUserSignUpData(user, 'password123');
     */
    private buildSocialUserSignUpData(user, pass): any {
        return {
            first_name: user.firstName,
            last_name: user.lastName,
            phone_number: '00000000',
            gender: '*',
            email: user.email,
            password: pass,
            password_confirmation: pass,
            google_token_id: user.id,
            locale: this.appService.locale,
        };
    }

    /**
     * Fetches app data based on the current authentication status.
     * If the user is logged in, it calls fetchAllData() method.
     * If the user is not logged in, it calls fetchInitialData() method
     * and sets translations in appService.
     *
     * @returns {void}
     */
    private fetchAppData(): void {
        if (this.authService.isLoggedIn()) {
            this.fetchAllData();
        } else {
            this.fetchInitialData();
            this.appService.translations = this.translate.translations;
        }
    }

    /**
     * Fetches initial data by making multiple API requests.
     *
     * @private
     * @returns {void}
     */
    private fetchInitialData(): void {
        forkJoin([
            this.currencyService.getCurrencies(),
            this.bookingService.getStatuses(),
            this.categoryService.getCategories(),
            this.translateService.getLocales(),
            this.appService.fetchSettings(),
        ]).subscribe((responseData) =>
            this.handleInitialDataResponse(responseData),
        );
    }

    /**
     * Handles the initial data response.
     *
     * @param {Array} responseData - The response data containing currencies, booking statuses, categories, locales, and settings.
     * @return {void}
     */
    private handleInitialDataResponse(responseData): void {
        const [currencies, bookingStatuses, categories, locales, settings] =
            responseData;

        this.appService.currencies = currencies.data.map(
            (item) => new CurrencyModel(item),
        );
        this.appService.categories = categories.data.map(
            (item) => new CategoryModel(item),
        );
        this.appService.locales = locales.data;
        this.appService.bookingStatuses = bookingStatuses.data;
        this.appService.settings = new SettingModel(settings.data);
    }

    /**
     * Fetches all necessary data for the application.
     *
     * @private
     * @returns {void}
     */
    private fetchAllData(): void {
        forkJoin([
            this.currencyService.getCurrencies(),
            this.bookingService.getStatuses(),
            this.categoryService.getCategories(),
            this.paymentService.getStatuses(),
            this.paymentService.getMethods(),
            this.translateService.getLocales(),
            this.appService.fetchSettings(),
        ]).subscribe((responseData) =>
            this.handleAllDataResponse(responseData),
        );
    }

    /**
     * Handle all data response
     *
     * @param {*} responseData - The response data containing currencies,
     *                          booking statuses, categories, payment statuses,
     *                          payment methods, locales, and settings
     * @return {void}
     */
    private handleAllDataResponse(responseData: any): void {
        const [
            currencies,
            bookingStatuses,
            categories,
            paymentStatuses,
            paymentMethods,
            locales,
            settings,
        ] = responseData;

        this.appService.currencies = currencies.data.map(
            (item: any) => new CurrencyModel(item),
        );
        this.appService.categories = categories.data.map(
            (item: any) => new CategoryModel(item),
        );
        this.appService.locales = locales.data;
        this.appService.bookingStatuses = bookingStatuses.data;
        this.appService.settings = new SettingModel(settings.data);
        this.appService.paymentStatuses = paymentStatuses;
        this.appService.paymentMethods = paymentMethods;
    }

    /**
     * Executes after the view is initialized.
     *
     * Sets the `loginModal` property of the `appService` object to the value of `loginModal`.
     *
     * @return {void}
     */
    ngAfterViewInit(): void {
        this.appService.loginModal = this.loginModal;
        this.appService.bookingModal = this.bookingModal;
    }
}
