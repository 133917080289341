import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    Optional,
    Output,
    QueryList,
    Self,
} from '@angular/core';
import {
    ControlContainer,
    FormGroupDirective,
    NgControl,
} from '@angular/forms';

import { UiInputChipOptionComponent } from './input-chip-option/input-chip-option.component';

type VoidMethod = (args?: any) => void;

@Component({
    selector: 'ui-chip-select',
    templateUrl: './input-chip-select.component.html',
    styleUrls: ['./input-chip-select.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective,
        },
    ],
})
export class UiInputChipSelectComponent implements AfterContentInit {
    @Output() change: EventEmitter<any> = new EventEmitter<any>();
    @Input() multiple = false;
    @Input() placeholder;

    @ContentChildren(UiInputChipOptionComponent, { descendants: true })
    options: QueryList<UiInputChipOptionComponent>;

    @Input() value: any;
    @Input() disabled = false;

    private onTouched!: VoidMethod;
    private onChanged!: VoidMethod;

    constructor(@Self() @Optional() public ngControl: NgControl) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngAfterContentInit() {
        this.init();
        this.options.changes.subscribe(() => {
            this.init();
        });
    }

    init() {
        // register option change event
        this.options.forEach((instance) => {
            // subscribe intance change evnet
            instance.change.subscribe((child) => this.onChangeValues(child));
        });
        // handle multiple value
        if (Array.isArray(this.value)) {
            this.value.forEach((value) => {
                this.setSelectedValue(value);
            });
        } else {
            this.setSelectedValue(this.value);
        }
    }

    setSelectedValue(value) {
        this.options.forEach((instance) => {
            // set instance selection
            if (value === instance.value) {
                setTimeout(() => instance.setSelected(true), 10);
            }
        });
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    markAsTouched(): void {
        this.onTouched();
    }

    getSelectedItems() {
        return this.options.filter((item) => {
            return item.selected == true;
        });
    }

    onChangeValues(instance) {
        let value;

        if (this.multiple == false) {
            this.options.forEach((option) => {
                if (option !== instance) {
                    option.selected = false;
                }
            });
            // single value
            value = this.getSelectedItems().map((item) => item.value)[0];
        } else {
            // multiple value
            value = this.getSelectedItems().map((item) => item.value);
        }

        this.writeValue(value);
        this.onChanged(value);
        this.onTouched();
        this.change.emit(value);
    }
}
