import { BaseModel } from './base.model';

export class RequestModel extends BaseModel {
    id: number;
    name: string;
    data: any;
    owner_id: number;
    e_service_id: number;
    created_at: string;
    updated_at: string;
}
