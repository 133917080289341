import { Injectable } from '@angular/core';
import { Storage, Drivers } from '@ionic/storage';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    private storage: Storage;
    constructor() {
        this.storage = new Storage({
            name: 'trandth',
            storeName: 'app',
            driverOrder: [Drivers.LocalStorage],
        });
        this.storage.create();
    }

    public set(key: string, value: any) {
        return this.storage.set(key, value);
    }

    public get(key: string) {
        return this.storage.get(key);
    }
}
