import { BaseModel } from './base.model';

// Interface to define the structure of the notification data
interface NotificationData {
    booking_id?: number;
    subject?: string;
    text?: string;
    entity?: string;
    short_text?: string;
    sms?: string;
    type?: string;
    action_text?: string;
    action_url?: string;
    action_type?: string;
    locale?: string;
    salon?: any;
    // Add other properties as needed
}

export class NotificationModel extends BaseModel {
    id: any;
    type: string;
    notifiable_type: string;
    notifiable_id: number;
    data: any;
    read_at: string | null;
    created_at: string;
    updated_at: string;
    custom_fields: any;

    // Method to check if the notification has been read
    isRead(): boolean {
        return !!this.read_at;
    }

    // Method to parse and retrieve structured data from the notification
    getData(): NotificationData | any {
        try {
            const data = this.data ? JSON.parse(this.data) : this.data;

            return data;
        } catch (error) {
            // Log an error if there's an issue parsing the JSON data
            console.error('Error parsing notification data:', error);
            return false;
        }
    }

    // Method to get the simplified type of the notification
    getType(): string {
        return this.type.replace('App\\Notifications\\', '');
    }

    // Method to get the icon based on the notification type
    getIcon(): string {
        const type = this.getType();

        switch (type) {
            case 'Booking\\NewBooking':
                return 'book-outline';
            case 'Booking\\BookingStatusChanged':
            case 'Salon\\WelcomeSalon':
                return 'sync-circle-outline';
            case 'NewBooking':
                return 'book-outline';
            case 'StatusChangedBooking':
            case 'WelcomeSalon':
                return 'sync-circle-outline';
            case 'NewMessage':
                return 'chatbubble-ellipses-outline';
            case 'SystemWarning':
                return 'alert-circle-outline';
            case 'SystemNotification':
                return 'information-circle-outline';
            default:
                return 'book-outline';
        }
    }

    // Method to get the color based on the notification type
    getColor(): string {
        const type = this.getType();

        switch (type) {
            case 'NewBooking':
                return 'primary';
            case 'StatusChangedBooking':
            case 'WelcomeSalon':
                return 'warning';
            case 'NewMessage':
                return 'success';
            case 'SystemWarning':
                return 'danger';
            case 'SystemNotification':
                return 'secondary';
            default:
                return 'primary';
        }
    }

    // Method to get the creation timestamp of the notification
    createdAt(): string {
        return this.created_at;
    }
}
