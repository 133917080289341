import { BaseModel } from './base.model';

export class GuideModel extends BaseModel {
    id?: string;
    title?: string;
    textAlignCenter: string;
    content: string;
    breakpoint: any;
    breakpoints: any;
    header: boolean;
    actionBtn?: any;
    constructor(input) {
        super(input);

        if (!this.breakpoint) {
            this.breakpoint = 0.33;
        }

        if (typeof this.header === 'undefined') {
            this.header = true;
        }

        if (!this.breakpoints) {
            this.breakpoints = [0, 0.33, 0.5, 0.75, 1];
        }

        if (!this.actionBtn) {
            this.actionBtn = {
                visible: false,
                text: 'Start',
                callback: () => null,
            };
        }
    }
}
