import { Injectable } from '@angular/core';
import { ResponseInterface } from '@app/shared/interface/response.interface';
import { AppService } from '@app/shared/service/app.service';
import { AuthService } from '@app/shared/service/auth.service';
import { MessageService } from '@app/shared/service/message.service';
import { NotificationService } from '@app/shared/service/notification.service';
import { UserService } from '@app/shared/service/user.service';
import { firstValueFrom, ReplaySubject, Subscription, timer } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BackgroundService {
    public notifications$: ReplaySubject<any> = new ReplaySubject<any>(0);
    public messages$: ReplaySubject<any> = new ReplaySubject<any>(0);
    activity$: ReplaySubject<any> = new ReplaySubject<any>(0);
    activityTimer$: Subscription;
    intervalDuration = 60 * 1000;

    constructor(
        private notificationService: NotificationService,
        private messageService: MessageService,
        private appService: AppService,
        private authService: AuthService,
        private userService: UserService,
    ) {}

    checkNotifications() {
        this.notifications$.subscribe(() => {
            console.log('BackgroundService:CheckNotifications');
            this.notificationService
                .countUnReadNotifications()
                .subscribe((response: ResponseInterface) => {
                    if (response.success) {
                        this.appService.counters.unReadNotifications =
                            response.data;
                    }
                });
        });

        this.authService.userAuthState.subscribe(() => {
            if (this.authService.isLoggedIn()) {
                this.notifications$.next(true);
            }
        });
    }

    checkActivity() {
        if (this.authService.isLoggedIn()) {
            this.activity$.subscribe(() => {
                console.log('BackgroundService:CheckActivity');
                firstValueFrom(
                    this.userService.setLastActivity(
                        this.authService.getUser().id,
                    ),
                );
            });

            this.activityTimer$ = timer(1000, this.intervalDuration).subscribe(
                () => {
                    this.activity$.next(true);
                },
            );
        }
    }

    checkMessages() {
        this.messages$.subscribe(() => {
            console.log('BackgroundService:CheckMessages');
            this.messageService
                .countUnReadMessages()
                .subscribe((response: ResponseInterface) => {
                    if (response.success) {
                        this.appService.counters.unReadMessages = response.data;
                    }
                });
        });

        this.authService.userAuthState.subscribe(() => {
            if (this.authService.isLoggedIn()) {
                this.messages$.next(true);
            }
        });
    }
}
