import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { ErrorService } from './service/error.service';
import { LoggingService } from './service/logging.service';
import { NotifyService } from './service/notify.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {}

    handleError(error: Error | HttpErrorResponse) {
        const errorService = this.injector.get(ErrorService);
        const logger = this.injector.get(LoggingService);
        const notifier = this.injector.get(NotifyService);

        let message;
        let isSilent = false;
        if (error instanceof HttpErrorResponse) {
            // Server error
            message = errorService.getServerErrorMessage(error);
            isSilent = errorService.isSilentError(error);
            if (!isSilent) {
                notifier.showError(errorService.getErrorMessagesAsText(error));
            }
        }
        // Always log errors
        if (message) {
            logger.logError(message);
        } else {
            logger.logError(error.toString());
        }
    }
}
