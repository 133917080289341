import countries from 'i18n-iso-countries';

import { AmenityModel } from './amenity.model';
import { AvailabilityModel } from './availability.model';
import { BaseModel } from './base.model';
import { TaxModel } from './tax.model';

export class AddressModel extends BaseModel {
    id: number;
    description: string;
    address: string;
    latitude: any;
    longitude: any;
    default: boolean;
    user_id: number;
    custom_fields: [];
    amenities: AmenityModel[];
    availability_range: number;
    availabilities: any;
    street: any;
    country: any;
    country_code: string;
    zip: any;
    state: any;
    city: any;
    data: any;
    other: any;
    number: any;
    outcall_tax_id: number;
    outcall_tax: any;
    keywords: any;
    radius: any;

    constructor(input) {
        super(input);

        if (this.amenities) {
            this.amenities = this.amenities.map((amenity) => {
                return new AmenityModel(amenity);
            });
        } else {
            this.amenities = [];
        }

        if (this.availabilities) {
            this.availabilities = this.availabilities.map((availability) => {
                return new AvailabilityModel(availability);
            });
        } else {
            this.availabilities = [];
        }

        if (this.outcall_tax) {
            this.outcall_tax = new TaxModel(this.outcall_tax);
        }
    }

    getState() {
        if (this.city && this.city?.toLowerCase() === 'budapest') {
            return this.city;
        } else if (this.state) {
            return this.state;
        } else {
            return '';
        }
    }

    getCity() {
        if (this.city && this.city?.toLowerCase() === 'budapest') {
            return this.state;
        } else if (this.city) {
            return this.city;
        } else {
            return '';
        }
    }

    getCountry(language = 'en') {
        const countryName = countries.getName(this.country_code, language);

        if (this.country && countryName) {
            return countryName;
        } else if (this.country) {
            return this.country;
        } else {
            return '';
        }
    }

    /**
     * Get the address description
     */
    getAddress() {
        if (this.description) {
            return this.description;
        } else if (this.address) {
            return this.address;
        } else {
            return '';
        }
    }

    getAddressWithoutNumber() {
        if (this.address) {
            return this.address.replace(/[0-9]/g, '');
        }

        return '';
    }
}
