import { HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { __ } from '@app/global';
import { CurrencyModel } from '@app/shared/model/currency.model';
import { AppService } from '@app/shared/service/app.service';
import { Observable } from 'rxjs';

import { AddressModel } from '../model/address.model';
import { FilterModel } from '../model/filter.model';

@Injectable({
    providedIn: 'root',
})
export class FilterService {
    /**
     * Represents a filter object.
     * @constructor
     * @param {Object} filterOptions - The options for the filter.
     * @property {Object} filterOptions - The options for the filter.
     */
    filter: FilterModel = new FilterModel({});
    /**
     * Event emitter for filtering events based on a FilterModel.
     *
     * @type {EventEmitter<FilterModel>}
     */
    filterEvent: EventEmitter<FilterModel> = new EventEmitter<FilterModel>();
    changeToken: EventEmitter<any> = new EventEmitter<any>();
    /**
     * Represents an array variable named "tokens" which holds any type of elements.
     *
     * @type {Array}
     */
    tokens: any[] = []; // filter tokens
    /**
     * Specifies the available options for a configuration object.
     *
     * @typedef {Object} Options
     * @property {AgeRange} age_range - Sets the range for age.
     * @property {PriceRange} price_range - Sets the range for price.
     */
    options = {
        age_range: { min: 18, max: 70 },
        price_range: { steps: 10, min: 0, max: 100 },
    };

    /**
     * Constructs a new instance of the class.
     * @param {AppService} appService - The instance of the AppService class.
     * @return {void}
     */
    constructor(private appService: AppService) {
        this.filterEvent.subscribe(() => this.addToken());
    }

    /**
     * Resets the filter and sets the price range based on the locale.
     * @returns {void}
     */
    reset(): void {
        this.filter = new FilterModel({});
        this.filter.reset();
        this.setPriceRangeByLocale();
    }

    /**
     * Sets the filter's address property to the default address
     * and emits the filterEvent.
     *
     * @return {void}
     */
    findByDefaultAddress(): void {
        this.filter.address = this.filter.defaultAddress;
        this.filterEvent.emit();
    }

    /**
     * Clears the current location.
     *
     * This method clears the current address stored in the "filter" object and emits an event to notify any subscribers.
     *
     * @return {void} - This method does not return any value.
     */
    clearCurrentLocation(): void {
        this.filter.address = undefined;
        this.filterEvent.emit();
    }

    /**
     * Finds the category based on the given keywords.
     *
     * @param {string} keywords - The keywords to search for the category.
     * @return {Observable<any>} - An observable that emits the found category or the given keywords if no category is found.
     */
    findCategory(keywords): Observable<any> {
        return new Observable((observer) => {
            if (!keywords) {
                observer.next(keywords);
                return;
            }

            let category = this.findCategoryByName(keywords);

            if (!category) {
                category = this.findCategoryInSubCategories(keywords);
            }

            if (category) {
                this.updateFilterCategories(category.id);
                observer.next();
            } else {
                observer.next(keywords);
            }
        });
    }

    /**
     * Finds the location based on given keywords.
     *
     * @param {string} keywords - The keywords to search for the location.
     * @returns {Observable<any>} - An Observable that emits the location information.
     */
    findLocation(keywords): Observable<any> {
        return new Observable((observer) => {
            if (!keywords) {
                observer.next(keywords);
                return;
            }

            this.geocodeAddress(keywords, observer);
        });
    }

    /**
     * Adds tokens based on the provided filter.
     * @method addToken
     * @returns {void}
     *
     */
    addToken(): void {
        this.clearAllTokens();
        if (this.filter.category) {
            this.tokens.push(
                this.createToken(
                    'Category',
                    __(this.filter.category),
                    'category',
                    this.filter.category,
                ),
            );
        }

        if (this.filter.keywords) {
            this.tokens.push(
                this.createToken(
                    'Search',
                    this.filter.keywords,
                    'search',
                    this.filter.keywords,
                ),
            );
        }

        if (this.filter.address) {
            this.tokens.push(
                this.createToken(
                    'Address',
                    this.filter.address.address,
                    'address',
                    this.filter.address.address,
                ),
            );
        }

        if (this.filter.gender) {
            this.tokens.push(
                this.createToken(
                    'Gender',
                    `${__('gender')}: ${__(this.filter.gender)}`,
                    'gender',
                    this.filter.gender,
                ),
            );
        }

        if (this.filter.age_range?.upper) {
            this.tokens.push(
                this.createToken(
                    'Age',
                    `${__('age')}: ${this.filter.age_range.lower} - ${
                        this.filter.age_range.upper
                    }`,
                    'age_range',
                    this.filter.age_range,
                ),
            );
        }

        if (this.filter.duration) {
            this.tokens.push(
                this.createToken(
                    'Duration',
                    `${__('duration')}: ${this.filter.duration}`,
                    'duration',
                    this.filter.duration,
                ),
            );
        }

        if (this.filter.distance_range) {
            this.tokens.push(
                this.createToken(
                    'Distance',
                    `${__('distance')}: ${this.filter.distance_range}`,
                    'distance_range',
                    this.filter.distance_range,
                ),
            );
        }

        if (this.filter.price_range?.upper) {
            const currency: CurrencyModel = this.appService.getCurrency();
            this.tokens.push(
                this.createToken(
                    'Price',
                    `${__('price')}: ${this.filter.price_range.lower}${
                        currency.symbol
                    } - ${this.filter.price_range.upper}${currency.symbol}`,
                    'price_range',
                    this.filter.price_range,
                ),
            );
        }

        if (this.filter.date_from) {
            this.tokens.push(
                this.createToken(
                    'Start Date',
                    `${__('start_at')}: ${this.filter.date_from}`,
                    'date_from',
                    this.filter.date_from,
                ),
            );
        }

        if (this.filter.date_to) {
            this.tokens.push(
                this.createToken(
                    'End Date',
                    `${__('end_at')}: ${this.filter.date_to}`,
                    'date_to',
                    this.filter.date_to,
                ),
            );
        }

        this.filter.categories.forEach((category) => {
            const categoryModel = this.appService.getCategoryById(category);
            this.tokens.push(
                this.createToken(
                    'Categories',
                    __('categories.' + categoryModel.name),
                    'categories',
                    category,
                ),
            );
        });
    }

    /**
     * Removes the specified token from the list of tokens.
     *
     * @param {string} token - The token to be removed.
     *
     * @return {void}
     */
    removeToken(token): void {
        this.tokens = this.tokens.filter((item) => item !== token);
        this.updateFilterStateByTokenParams(token.params);
        this.filterEvent.emit();
    }

    /**
     * Sets the price range options based on the locale currency code.
     *
     * @returns {void}
     */
    setPriceRangeByLocale(): void {
        const currencyCode = this.appService.appSettings.currency_code;
        this.options.price_range =
            this.getPriceRangeOptionsByCurrency(currencyCode);
    }

    /**
     * Finds a category based on the given name.
     *
     * @param {string} name - The name of the category to find.
     * @return {any} - The found category object, or undefined if not found.
     */
    private findCategoryByName(name: string): any {
        return this.appService.categories.find(
            (item) => item.name.toLowerCase() === name.toLowerCase(),
        );
    }

    /**
     * Finds a category in the sub categories based on the provided keywords.
     * @param {string} keywords - The keywords used to search for the category.
     * @return {any} - The found category in the sub categories, or null if not found.
     */
    private findCategoryInSubCategories(keywords: string): any {
        let category = null;
        this.appService.categories.forEach((item) => {
            const subCategory = item.sub_categories.find((sub) => {
                const subCategoryName = this.appService
                    .getTrans('categories.' + sub.name)
                    .toLowerCase();
                return subCategoryName.includes(keywords.toLowerCase());
            });
            if (subCategory) {
                category = subCategory;
            }
        });
        return category;
    }

    /**
     * Updates the filter categories based on the specified category ID.
     * If the category ID is already present in the filter categories, it is removed.
     * If the category ID is not present in the filter categories, it is added.
     *
     * @param {number} categoryId - The ID of the category.
     *
     * @returns {void}
     */
    private updateFilterCategories(categoryId: number): void {
        const isExists = this.filter.categories.includes(categoryId);
        if (isExists) {
            this.filter.categories = this.filter.categories.filter(
                (item) => item !== categoryId,
            );
        } else {
            this.filter.categories.push(categoryId);
        }
    }

    /**
     * Geocodes an address using the Google Maps Geocoder API.
     *
     * @param keywords - The address to be geocoded.
     * @param observer - The observer to be triggered after geocoding.
     * @returns {void}
     */
    private geocodeAddress(keywords: string, observer: any): void {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: keywords }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                const location = this.createAddressModel(results[0]);
                this.updateFilterLocation(location);
                observer.next();
            } else {
                this.updateFilterLocation(undefined);
                observer.next(keywords);
            }
        });
    }

    /**
     * This method is used to clear all tokens from the tokens array.
     *
     * @returns {void} - This method does not return anything.
     */
    private clearAllTokens(): void {
        const paramsToDelete = [
            'category',
            'categories',
            'address',
            'search',
            'gender',
            'age_range',
            'duration',
            'distance_range',
            'price_range',
            'date_from',
            'date_to',
        ];

        paramsToDelete.forEach((param) => {
            this.tokens = this.tokens.filter((token) => !token.params[param]);
        });
    }

    /**
     * Creates a token object.
     *
     * @param {string} name - The name of the token.
     * @param {string} displayText - The display text for the token.
     * @param {string} paramKey - The key for the parameter.
     * @param {*} paramValue - The value for the parameter.
     * @return {*} The created token object.
     */
    private createToken(
        name: string,
        displayText: string,
        paramKey: string,
        paramValue: any,
    ): any {
        return {
            name: displayText,
            params: { [paramKey]: paramValue },
        };
    }

    /**
     * Updates the filter state based on the token parameters.
     * @param {object} params - The token parameters.
     * @return {void}
     */
    private updateFilterStateByTokenParams(params: any): void {
        const paramActions = {
            category: () => (this.filter.category = undefined),
            // add categories
            categories: () => {
                this.updateFilterCategories(params.categories);
            },
            address: () => (this.filter.address = undefined),
            search: () => (this.filter.keywords = undefined),
            gender: () => (this.filter.gender = undefined),
            age_range: () => (this.filter.age_range = { lower: 0, upper: 0 }),
            duration: () => (this.filter.duration = undefined),
            distance_range: () => (this.filter.distance_range = undefined),
            price_range: () =>
                (this.filter.price_range = { lower: 0, upper: 0 }),
            date_from: () => (this.filter.date_from = undefined),
            date_to: () => (this.filter.date_to = undefined),
        };

        Object.keys(params).forEach((param) => {
            if (paramActions[param]) {
                paramActions[param]();
            }
        });
    }

    /**
     * Updates the filter location.
     *
     * @param {AddressModel} location - The new location to update the filter with.
     * @return {void}
     */
    private updateFilterLocation(location: AddressModel): void {
        this.filter.location = location;
        if (this.filter.location) {
            this.filter.address = this.filter.location;
        }
    }

    /**
     * Retrieves the price range options for a given currency code.
     *
     * @param {string} currencyCode - The currency code.
     * @return {any} The price range options object containing steps, min, and max properties.
     */
    private getPriceRangeOptionsByCurrency(currencyCode: string): any {
        return currencyCode === 'HUF'
            ? { steps: 1000, min: 0, max: 30000 }
            : { steps: 10, min: 0, max: 100 };
    }

    /**
     * Creates an AddressModel object based on the given result.
     *
     * @param {any} result - The result from the geocoding API.
     * @returns {AddressModel} - The created AddressModel object.
     */
    private createAddressModel(result: any): AddressModel {
        const lat = result.geometry.location.lat();
        const lng = result.geometry.location.lng();
        return new AddressModel({
            address: result.formatted_address,
            latitude: lat,
            longitude: lng,
        });
    }

    /**
     * Builds HttpParams object with the given query parameters.
     *
     * @param {Object} qParams - The query parameters to build the HttpParams object.
     * @return {HttpParams} - The HttpParams object with the query parameters.
     */
    public buildQueryParams(qParams) {
        const queryParamsObj = {};

        Object.keys(qParams).forEach((key) => {
            if (typeof qParams[key] == 'object') {
                qParams[key] = JSON.stringify(qParams[key]);
            }
            queryParamsObj[key] = [qParams[key]];
        });

        const tokenParams = this.tokens.map((token) => token.params);
        tokenParams.forEach((params) => {
            Object.keys(params).forEach((key) => {
                if (typeof params[key] === 'object') {
                    params[key] = JSON.stringify(params[key]);
                }
                if (queryParamsObj[key]) {
                    queryParamsObj[key].push(params[key]);
                } else {
                    queryParamsObj[key] = [params[key]];
                }
            });
        });

        if (this.filter.categories && this.filter.categories.length > 0) {
            queryParamsObj['categories'] = [this.filter.categories.join(',')];
        }

        if (this.filter.address?.latitude && this.filter.address.longitude) {
            queryParamsObj['myLat'] = [this.filter.address.latitude];
            queryParamsObj['myLon'] = [this.filter.address.longitude];
        }

        if (typeof this.filter.radius !== 'undefined') {
            queryParamsObj['radius'] = [this.filter.radius];
        }

        if (this.filter.limit) {
            queryParamsObj['limit'] = [this.filter.limit];
        }

        if (this.filter.offset) {
            queryParamsObj['offset'] = [this.filter.offset];
        }

        if (this.filter.order_by === 'distance' && !this.filter.address) {
            delete this.filter.order_by;
            delete this.filter.sorted_by;
        }

        if (this.filter.order_by) {
            queryParamsObj['order_by'] = [this.filter.order_by];
        }

        if (this.filter.sorted_by) {
            queryParamsObj['sorted_by'] = [this.filter.sorted_by];
        }

        Object.keys(queryParamsObj).forEach((key) => {
            queryParamsObj[key] = queryParamsObj[key].filter(
                (value) => value !== 'undefined' && value !== undefined,
            );
        });

        //remove duplicate keys
        Object.keys(queryParamsObj).forEach((key) => {
            if (queryParamsObj[key].length > 1) {
                queryParamsObj[key] = [queryParamsObj[key][0]];
            }
        });

        return queryParamsObj;
    }
}
