import { BaseModel } from './base.model';

export class MediaModel extends BaseModel {
    id: number;
    name: string;
    url: string;
    caption: string;
    thumb: string;
    large: string;
    uuid: string;
    icon: string;
    formated_size: string;
    order_column: number;
    custom_properties: any;

    getCaption() {
        return this.caption;
    }

    /**
     * Original Image
     */
    getUrl() {
        return this.url;
    }

    /**
     * Cropped Large Image Size
     */
    getLarge() {
        return this.large ? this.large : this.url;
    }
    /**
     * Cropped Thumb Image Size
     */
    getThumb() {
        return this.thumb ? this.thumb : this.url;
    }

    /**
     * Get Unique Id
     */
    getUUid() {
        return this.uuid;
    }
}
