import { Component, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { AppService } from '@app/shared/service/app.service';
import { PhoneNumber, PhoneNumberUtil } from 'google-libphonenumber';
import CountryCodes from 'src/assets/CountryCodes.json';

interface CountryCode {
    name: string;
    dial_code: string;
    code: string;
}

@Component({
    selector: 'ui-input-phone',
    templateUrl: './input-phone.component.html',
    styleUrls: ['./input-phone.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: UiInputPhoneComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: UiInputPhoneComponent,
        },
    ],
})
export class UiInputPhoneComponent
    implements OnInit, ControlValueAccessor, Validator
{
    @Input() prefill = true;
    @Input() placeholder = 'phone_number_placeholder';

    finalNumber: PhoneNumber;
    touched = false;
    phoneUtil: PhoneNumberUtil;

    onChange = (finalNumber: string) => finalNumber;
    onTouched = () => null;

    data = CountryCodes.map((countryCode) => ({
        text: `(${countryCode.dial_code}) ${countryCode.name}`,
        value: countryCode as CountryCode,
    }));
    /**
     * The phoneForm variable represents a FormGroup object that is used to handle phone number input in a form.
     * It contains two form controls: phoneNumber and countryCode.
     *
     * @type {FormGroup}
     * @property {FormControl} phoneNumber - The form control for the phone number input field.
     * @property {FormControl} countryCode - The form control for the country code input field.
     *
     * @example
     * // Usage example
     * phoneForm = this.fb.group({
     *     phoneNumber: [
     *         '',
     *         [Validators.required, this.createPhoneNumberValidator()],
     *     ],
     *     countryCode: [
     *         this.findCountryCodeByCountry(this.appService.appSettings.locale),
     *         [Validators.required],
     *     ],
     * });
     */
    phoneForm = this.fb.group({
        phoneNumber: [
            '',
            [Validators.required, this.createPhoneNumberValidator()],
        ],
        countryCode: [
            this.findCountryCodeByCountry(this.appService.appSettings.locale),
            [Validators.required],
        ],
    });

    /**
     * Retrieves the value of the phoneNumber.
     *
     * @return {FormControl} The phoneNumber FormControl object.
     */
    get phoneNumber() {
        return this.phoneForm?.get('phoneNumber');
    }

    /**
     * Returns the country code from the phone form.
     *
     * @returns {string|null} The country code, or null if it is not available.
     */
    get countryCode() {
        return this.phoneForm?.get('countryCode');
    }

    /**
     * Get the dial code for the final number.
     *
     * @returns {string} The dial code of the final number.
     */
    get dialCode() {
        return this.finalNumber.getCountryCode();
    }

    /**
     * Retrieves the raw input of the international number.
     *
     * @return {string} The raw input of the international number.
     */
    get internationalNumber() {
        return this.finalNumber?.getRawInput();
    }

    /**
     * Returns the ISO code of the region for the phone number.
     *
     * @returns {string} The ISO code of the region for the phone number.
     */
    get isoCode() {
        return this.phoneUtil
            .getRegionCodeForNumber(this.finalNumber)
            .toUpperCase();
    }

    /**
     * Retrieves the national number as a string representation.
     *
     * @returns {string} The national number as a string.
     */
    get nationalNumber() {
        return this.finalNumber.getNationalNumber().toString();
    }

    /**
     * Constructor for the class.
     *
     * @param appService - An instance of the AppService class.
     * @param fb - An instance of the FormBuilder class.
     */
    constructor(public appService: AppService, private fb: FormBuilder) {}

    /**
     * Initializes the component.
     *
     * @return {void} Returns nothing.
     */
    ngOnInit() {
        this.phoneUtil = PhoneNumberUtil.getInstance();
    }

    /**
     * Sets the value of phoneNumber in the phoneForm.
     *
     * @param {string} phoneNumber - The phone number to be set.
     * @return {void}
     */
    writeValue(phoneNumber: string) {
        this.finalNumber = this.parsePhoneNumber(phoneNumber);

        if (this.finalNumber) {
            this.phoneForm?.patchValue({
                countryCode: this.findCountryCodeByCode(this.dialCode),
                phoneNumber: this.nationalNumber,
            });
            this.markAsTouched();
        }
    }

    /**
     * Registers a callback function to be called when the onChange event occurs.
     *
     * @param {any} onChange - The callback function to be registered.
     */
    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    /**
     * Registers a callback function for touch event.
     * This function will be called when the element is touched.
     *
     * @param {any} onTouched - The callback function to be registered.
     */
    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    /**
     * Marks the input as touched.
     *
     * @return {void} - This method does not return any value.
     */
    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    /**
     * Set the disabled state of phoneNumber and countryCode fields.
     * @param {boolean} disabled - The disabled state to set (true to disable, false to enable).
     * @return {void}
     */
    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.phoneNumber.disable();
            this.countryCode.disable();
        } else {
            this.phoneNumber.enable();
            this.countryCode.enable();
        }
    }

    /**
     * Validates the phone number and country code.
     *
     * @return {ValidationErrors | null} - The validation errors if any, otherwise null.
     */
    validate(): ValidationErrors | null {
        if (
            !this.phoneNumber.value ||
            (this.countryCode.valid && this.phoneNumber.valid)
        ) {
            return null;
        }

        return {
            isValid: {
                countryCode: this.countryCode.valid,
                phoneNumber: this.phoneNumber.valid,
            },
        };
    }

    /**
     * Executes the necessary actions when the country code changes.
     * Calls the onInputChange() method, updates the validity of the phoneNumber field,
     * and invokes the onChange callback with the internationalNumber.
     *
     * @return {void}
     */
    onCountryCodeChange() {
        this.onInputChange();
        this.phoneNumber.updateValueAndValidity();
        this.onChange(this.internationalNumber);
    }

    /**
     * Handles the phone number change event.
     *
     * @param {Event | undefined} event - The event object generated when the phone number is changed. Optional.
     *
     * @return {void}
     */
    onPhoneNumberChange(event?) {
        if (event) {
            const value = event.target!.value;
            const phoneNumber = this.cleanPhoneNumber(value);
            this.phoneNumber.setValue(phoneNumber);
        }
        this.onInputChange();
        this.onChange(this.internationalNumber);
    }

    /**
     * Sets the finalNumber property based on the user input.
     *
     * @returns {void}
     */
    onInputChange() {
        const phoneNumber = this.cleanPhoneNumber(this.phoneNumber.value);
        this.markAsTouched();
        this.finalNumber = this.parsePhoneNumber(
            `${this.countryCode.value.dial_code}${phoneNumber}`,
        );
    }

    /**
     * Finds the country code based on the given country code.
     *
     * @param {number} countryCode - The country code to search for.
     * @return {any} - The country code object matching the given country code.
     */
    findCountryCodeByCode(countryCode: number): any {
        return this.findCountryCode(
            (code) =>
                this.cleanCountryCode(code.value.dial_code) ===
                this.cleanCountryCode(countryCode),
        );
    }

    /**
     * Finds the country code by the given country name.
     *
     * @param {string} country - The country name to search for.
     * @returns {any} - The corresponding country code.
     */
    findCountryCodeByCountry(country: string): any {
        return this.findCountryCode(
            (code) =>
                this.cleanCountryCode(code.value.code) ===
                this.cleanCountryCode(country),
        );
    }

    /**
     * Removes all non-digit characters from the given phone number.
     *
     * @param {string} phoneNumber - The phone number to clean.
     * @returns {string} The cleaned phone number, containing only digits.
     */
    cleanPhoneNumber(phoneNumber: string): string {
        return phoneNumber.replace(/\D/g, '');
    }

    /**
     * Cleans the country code by removing the plus sign and converting it to lowercase.
     *
     * @param {string | number} code - The country code to be cleaned. It can be a string or a number.
     * @return {string} The cleaned country code as a lowercase string.
     */
    cleanCountryCode(code: string | number): string {
        return code.toString().replace('+', '').toLowerCase();
    }

    // Code Refactored to avoid repitition
    /**
     * Finds the country code that satisfies the given predicate.
     *
     * @param {function} predicate - The predicate function to evaluate each country code with.
     *                              The predicate function should take an argument `code` which has the properties `text` and `value`.
     *                              It should return a boolean indicating whether the code satisfies the condition.
     * @returns {*} - The country code that satisfies the given predicate, or the country code at index 230 if no code satisfies the predicate.
     */
    findCountryCode(
        predicate: (code: { text: string; value: CountryCode }) => boolean,
    ): any {
        const result = this.data.find(predicate);
        return result?.value || this.data[230].value;
    }

    /**
     * Validates a phone number input based on the specified country code.
     * @returns A ValidatorFn function that takes an AbstractControl and returns either ValidationErrors or null.
     */
    createPhoneNumberValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const phoneNumber = `${this.countryCode?.value?.dial_code || ''}${
                control.value
            }`;

            if (phoneNumber.length < 9 || !this.finalNumber) {
                return {
                    isValidNumber: false,
                };
            }

            const isValidNumber = this.phoneUtil?.isValidNumberForRegion(
                this.parsePhoneNumber(
                    `${this.countryCode.value.dial_code}${control.value}`,
                ),
                this.countryCode.value.code,
            );

            return !isValidNumber ? { isValidNumber } : null;
        };
    }

    /**
     * Parses a phone number and returns the parsed result.
     *
     * @param {string} phone - The phone number to parse.
     * @return {PhoneNumber|null} - The parsed phone number or null if the phone number is invalid.
     */
    parsePhoneNumber(phone: string): PhoneNumber {
        if (
            !phone ||
            phone === this.countryCode.value.dial_code ||
            phone.length < 9
        ) {
            return null;
        }

        const result = this.phoneUtil.parseAndKeepRawInput(
            phone,
            this.countryCode.value.dial_code,
        );

        return result || null;
    }
}
