import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Directive, ElementRef, OnInit } from '@angular/core';
import { take } from 'rxjs';

declare let google;
@Directive({
    selector: '[GoogleSigningButton]',
})
export class GoogleSigningButtonDirective implements OnInit {
    constructor(
        private el: ElementRef,
        private socialAuthService: SocialAuthService,
    ) {}

    ngOnInit() {
        this.socialAuthService.initState.pipe(take(1)).subscribe(() => {
            google.accounts.id.renderButton(this.el.nativeElement, {
                type: 'standard',
                size: 'large',
                text: 'signin_with',
                theme: 'filled_blue',
                width: 'auto',
            });
        });
    }
}
