import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModalsPageModule } from '@app/modals/modals.module';
import { LanguageGuard } from '@app/shared/guard/language.guard';

import { NavigationService } from './shared/service/navigation.service';

const pageConfigs = [
    {
        path: '',
        loadChildren: () =>
            import('./main/main.module').then((m) => m.MainPageModule),
    },
    {
        path: 'account',
        loadChildren: () =>
            import('./account/account.module').then((m) => m.AccountPageModule),
    },
    {
        path: 'business',
        loadChildren: () =>
            import('./business/business.module').then(
                (m) => m.BusinessPageModule,
            ),
    },
    {
        path: 'modals',
        canActivate: [LanguageGuard],
        outlet: 'modal',
        loadChildren: () => ModalsPageModule,
    },
    {
        path: '**',
        loadChildren: () =>
            import('./not-found/not-found.module').then(
                (m) => m.NotFoundPageModule,
            ),
    },
];

const routes: Routes = pageConfigs.flatMap((config) =>
    NavigationService.generateTranslatedRouteConfigs(config.path, config),
);

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
