<ion-text *pDesktop [routerLink]="'/'" class="ion-text-center relative clickable">
    <ion-img style="height: 26px;" [src]="iconSrc" alt="{{ 'meta.alt' | translate: { text: 'Trandth logo' } }}"></ion-img>
    <ion-badge color="warning">Trandth.com</ion-badge>
</ion-text>


<div *pNotDesktop [routerLink]="'/'" class="ion-text-center relative clickable">
    <ion-img style="height: 20px;" [src]="iconSrc" alt="{{ 'meta.alt' | translate: { text: 'Trandth logo' } }}"></ion-img>
    <ion-badge style="position: relative; top: 3px; " color="warning">Trandth.com</ion-badge>
</div>
