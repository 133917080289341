import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';

if (environment.production) {
    enableProdMode();

    console.log('There is nothing to see here.');
    console.log = () => null;
    console.info = () => null;
}

if (environment.appSettings.hideConsoleErrors) {
    console.error = () => null;
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
