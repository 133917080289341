import { AddressModel } from '@app/shared/model/address.model';

import { BaseModel } from './base.model';

export class AvailabilityModel extends BaseModel {
    id: number;
    day: string;
    start_at: any;
    end_at: any;
    data: any;
    addresses: AddressModel[];
    custom_fields: any;

    constructor(input) {
        super(input);

        if (this.addresses) {
            this.addresses = this.addresses.map((item: AddressModel) => {
                return new AddressModel(item);
            });
        }
    }
}
