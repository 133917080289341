import { AddressModel } from './address.model';
import { BaseModel } from './base.model';
import { OptionModel } from './option.model';
import { PaymentModel } from './payment.model';
import { ProviderModel } from './provider.model';
import { SalonReviewModel } from './salon-review.model';
import { ServiceModel } from './service.model';
import { TaxModel } from './tax.model';
import { UserReviewModel } from './user-review.model';
import { UserModel } from './user.model';

export class BookingModel extends BaseModel {
    id: number;
    name: string;
    booking_at: any;
    start_at: any;
    ends_at: string;
    hint: string;
    allergy: string;
    diseases: string;
    cancel: boolean;
    created_at: string;
    updated_at: string;
    custom_fields: any;
    duration: number;
    at_salon: boolean;
    address_type: string;
    total: number;
    sub_total: number;
    taxes_value: number;
    quantity = 1;
    user_id: number;
    employee_id: number;
    booking_status_id: number;
    payment_id: number;
    payment: any | PaymentModel;
    e_services: any;
    data: any;
    coupon: any;
    address: any;
    salon: any;
    user: any;
    taxes: any;
    options: any;
    reviews: any;
    user_reviews: any;

    constructor(input) {
        super(input);

        if (this.address) {
            this.address = new AddressModel(this.address);
        }

        if (this.salon) {
            this.salon = new ProviderModel(this.salon);
        }

        if (this.payment) {
            this.payment = new PaymentModel(this.payment);
        }

        if (this.e_services) {
            this.e_services = this.e_services.map((item) => {
                return new ServiceModel(item);
            });
        } else {
            this.e_services = [];
        }

        if (this.user) {
            this.user = new UserModel(this.user);
        }

        if (this.taxes) {
            this.taxes = this.taxes.map((item) => {
                return new TaxModel(item);
            });
        } else {
            this.taxes = [];
        }

        if (this.reviews) {
            this.reviews = this.reviews.map((item) => {
                return new SalonReviewModel(item);
            });
        } else {
            this.reviews = [];
        }

        if (this.user_reviews) {
            this.user_reviews = this.user_reviews.map((item) => {
                return new UserReviewModel(item);
            });
        } else {
            this.user_reviews = [];
        }

        if (this.options) {
            this.options = this.options.map((item) => {
                return new OptionModel(item);
            });
        } else {
            this.options = [];
        }
    }

    isCanceled() {
        return this.cancel;
    }

    isPending() {
        return this.ends_at === null;
    }

    canBookingAtSalon() {
        return null;
    }

    canApprove() {
        return this.booking_status_id === 1 && !this.cancel;
    }

    canDecline() {
        return (
            this.booking_status_id !== 7 &&
            this.booking_status_id !== 6 &&
            !this.cancel
        );
    }

    canDone() {
        return (
            this.booking_status_id !== 1 &&
            this.booking_status_id !== 7 &&
            this.booking_status_id !== 6 &&
            !this.cancel
        );
    }

    isDone() {
        return this.booking_status_id === 6 && !this.cancel;
    }

    hasSalonReviews() {
        if (this.reviews && this.reviews.length > 0) {
            return true;
        }
        return false;
    }

    hasUserReviews() {
        if (this.user_reviews && this.user_reviews.length > 0) {
            return true;
        }
        return false;
    }

    canBookingAtCustomerAddress() {
        return null;
    }

    getTotal() {
        let total = this.getSubtotal();
        total += this.getTaxesValue();
        total -= this.getCouponValue();
        return total;
    }

    getTaxesValue() {
        const total = this.getSubtotal();
        let taxValue = 0.0;
        this.taxes.forEach((element) => {
            if (element.type == 'percent') {
                taxValue += (total * element.value) / 100;
            } else {
                taxValue += element.value;
            }
        });
        return taxValue;
    }

    getCouponValue() {
        const total = this.getSubtotal();
        if (typeof this.coupon === 'object') {
            switch (this.coupon.discount_type) {
                case 'percent':
                    return total * (this.coupon.discount / 100);
                    break;
                case 'fixed':
                    return this.coupon.discount;
                    break;
                default:
                    return 0;
                    break;
            }
        }
        return 0;
    }

    getSubtotal() {
        let total = 0.0;
        if (this.e_services) {
            this.e_services.forEach((element) => {
                total +=
                    element.getPrice() *
                    (this.quantity >= 1 ? this.quantity : 1);
            });
        }
        if (this.options) {
            this.options.forEach((element) => {
                total +=
                    element.price * (this.quantity >= 1 ? this.quantity : 1);
            });
        }

        return total;
    }

    hasCoupon() {
        return (
            this.coupon &&
            typeof this.coupon.code !== 'undefined' &&
            this.coupon.code !== null
        );
    }
}
