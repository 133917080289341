import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NotificationModel } from '@app/shared/model/notification.model';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private http: HttpClient) {}
    public change: EventEmitter<any> = new EventEmitter<any>();
    countUnReadNotifications() {
        return this.http.get(environment.apiUrl + '/notifications/count');
    }

    get(id) {
        return this.http.get(environment.apiUrl + '/notifications/' + id);
    }

    getNotifications(read = false) {
        const queryParams = {
            orderBy: 'created_at',
            sortedBy: 'desc',
            read: read,
        };
        return this.http.get(environment.apiUrl + '/notifications', {
            params: queryParams,
        });
    }

    getUnReadNotifications() {
        const queryParams = {
            orderBy: 'created_at',
            sortedBy: 'desc',
            unRead: true,
        };
        return this.http.get(environment.apiUrl + '/notifications', {
            params: queryParams,
        });
    }

    readNotifications() {
        return this.http.get(environment.apiUrl + '/notifications');
    }

    update(id, data) {
        return this.http
            .put(environment.apiUrl + '/notifications/' + id, data)
            .pipe(
                map((res: any) => {
                    if (res.success) {
                        this.change.emit(new NotificationModel(res.data));
                    }
                    return res;
                }),
            );
    }
}
