<ion-modal #loginModal
           class="login-modal"
           [initialBreakpoint]="1"
           [breakpoints]="[0, 1]">
    <ng-template>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button (click)="close()">{{ "cancel" | translate }}</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <ng-container [ngTemplateOutlet]="login"></ng-container>

        </ion-content>
        <ion-footer class="ion-padding">
            <h3 class="ion-text-center">{{ 'not_member_yet' | translate }}</h3>
            <ion-button [routerLink]="['/signup']" class="secondary" (click)="close()" fill="outline" expand="block"
                        color="secondary">{{ "signup" | translate }}</ion-button>
        </ion-footer>
    </ng-template>
</ion-modal>


<mbsc-popup #loginModalMbsc [options]="popupOptions">
    <ion-grid class="ion-no-padding">
        <ion-row>
            <ion-col size="6" class="ion-padding">
                <div class="ion-padding ion-text-center">
                    <ui-logo></ui-logo>
                </div>
                <ion-note class="ion-text-center ion-color-dark">{{ 'not_member_yet' | translate }}</ion-note>
                <ion-button [routerLink]="['/signup']" (click)="close()"
                            fill="outline"
                            style="--background: none; --border-color: #62908EFF; --color:#62908EFF; "
                            expand="block">{{ "signup" | translate }}</ion-button>
            </ion-col>
            <ion-col class="ion-padding" size="6">
                <ng-container [ngTemplateOutlet]="login"></ng-container>
            </ion-col>
        </ion-row>
    </ion-grid>

</mbsc-popup>


<ng-template #login>
    <form [formGroup]="loginForm">
        <ion-list [lines]="'full'">
            <ion-item>
                <ion-label>{{ 'email' | translate }}</ion-label>
                <ion-input [class.invalid]="email.errors && email.touched" autocomplete="off"
                           formControlName="email"></ion-input>
            </ion-item>

            <ion-item>
                <ion-label>{{ 'password' | translate }}</ion-label>
                <ion-input [class.invalid]="password.errors && password.touched" autocomplete="off"
                           type="password" formControlName="password"></ion-input>
            </ion-item>

            <ion-button appPreventDoubleClick
                        style="--background: #BC72B5FF; --background-hover: #BC72B5FF"
                        [disabled]="appService.inProgress"
                        (throttledClick)="onSignIn()"
                        [throttleTime]="3000" class="ion-margin-top" expand="block">
                <span *ngIf="!appService.inProgress">{{ "signin" | translate }}</span>
                <ion-spinner *ngIf="appService.inProgress"></ion-spinner>
            </ion-button>

            <ion-item lines="none" [routerLink]="['/', 'forgot-password'] | translateUrl">
                <ion-label class="ion-text-center">{{ 'forgot_password' | translate }}</ion-label>
            </ion-item>
          <span class="divider line one-line">{{ 'or_use_social_network' | translate }}</span>
          <ion-button style="--background: none; --background-hover:none" GoogleSigningButton></ion-button>
        </ion-list>
    </form>
</ng-template>
