// TYPES & INTERFACES
export type SupportedCurrencyCodeType = 'USD' | 'EUR' | 'HUF';

export type SupportedDistanceUnitType = 'km' | 'mi';

export type SupportedWeightUnitType = 'kg' | 'lb';

export type SupportedHeightUnitType = 'cm' | 'in';

export interface MetricsType {
    currency_code: SupportedCurrencyCodeType;
    distance_unit: SupportedDistanceUnitType;
    weight_unit: SupportedWeightUnitType;
    height_unit: SupportedHeightUnitType;
}

interface MetricsMapType {
    [key: string]: MetricsType;
}

// CONSTANTS
export const DEFAULT_CURRENCY_CODE: SupportedCurrencyCodeType = 'USD';

export const DEFAULT_DISTANCE_UNIT: SupportedDistanceUnitType = 'mi';

export const DEFAULT_WEIGHT_UNIT: SupportedWeightUnitType = 'lb';

export const DEFAULT_HEIGHT_UNIT: SupportedHeightUnitType = 'in';

export const METRICS_MAP: MetricsMapType = {
    en: {
        currency_code: 'USD',
        distance_unit: 'mi',
        weight_unit: 'lb',
        height_unit: 'in',
    },
    hu: {
        currency_code: 'HUF',
        distance_unit: 'km',
        weight_unit: 'kg',
        height_unit: 'cm',
    },
};
