export interface RouteTranslation {
    [key: string]: string;
}
export const en: RouteTranslation = {
    'thank-you': 'thank-you',
    account: 'account',
    overview: 'overview',
    login: 'login',
    signup: 'signup',
    explore: 'explore',
    browse: 'browse',
    map: 'map',
    'explore/map': 'explore/map',
    provider: 'provider',
    language: 'language',
    dashboard: 'dashboard',
    booking: 'booking',
    'become-partner': 'become-partner',
    settings: 'settings',
    'forgot-password': 'forgot-password',
    support: 'support',
    pages: 'pages',
    modals: 'modals',
    profile: 'profile',
    information: 'information',
    bookings: 'bookings',
    messages: 'messages',
    notifications: 'notifications',
    services: 'services',
    providers: 'providers',
    coupons: 'coupons',
    taxes: 'taxes',
    'taxes/create': 'taxes/create',
    experiences: 'experiences',
    'experiences/create': 'experiences/create',
    certificates: 'certificates',
    'certificates/create': 'certificates/create',
    requests: 'requests',
    'requests/create': 'requests/create',
    therapists: 'therapists',
    connections: 'connections',
    amenities: 'amenities',
    'amenities/create': 'amenities/create',
    favorites: 'favorites',
    options: 'options',
    'options/create': 'options/create',
    'missing-data': 'missing-data',
    addresses: 'addresses',
    'addresses/create': 'addresses/create',
    'addresses/edit/:id': 'addresses/edit/:id',
    'addresses/create-simple': 'addresses/create-simple',
    'services/edit/:id': 'services/edit/:id',
    availabilities: 'availabilities',
    'availabilities/create': 'availabilities/create',
    reviews: 'reviews',
    terms: 'terms',
    business: 'business',
    wallet: 'wallet',
    wizard: 'wizard',
    integrations: 'integrations',
    completion: 'completion',
    create: 'create',
    'create-simple': 'create-simple',
    edit: 'edit',
    'edit/:id': 'edit/:id',
    galleries: 'galleries',
    'galleries/create': 'galleries/create',
    'google-calendar': 'google-calendar',
    'create-profile': 'create-profile',
    ':id/summary': ':id/summary',
    summary: 'summary',
    about: 'about',
    faq: 'faq',
    feedback: 'feedback',
    'terms-of-service': 'terms-of-service',
    'terms-of-use': 'terms-of-use',
    'partner-terms': 'partner-terms',
    'privacy-policy': 'privacy-policy',
    'black-friday': 'black-friday',
    'rapid-booking': 'rapid-booking',
    employees: 'employees',
    customers: 'customers',
};

export const hu: RouteTranslation = {
    'thank-you': 'koszonjuk',
    account: 'fiok',
    overview: 'attekinto',
    login: 'bejelentkezes',
    signup: 'regisztracio',
    explore: 'felfedezes',
    browse: 'bongeszes',
    map: 'terkep',
    'explore/map': 'felfedezes/terkep',
    provider: 'szolgaltato',
    language: 'nyelv',
    dashboard: 'iranyitopult',
    booking: 'foglalas',
    'become-partner': 'legyel-a-partnerunk',
    settings: 'beallitasok',
    'forgot-password': 'elfelejtett-jelszo',
    support: 'tamogatas',
    pages: 'oldalak',
    modals: 'ablakok',
    profile: 'profil',
    information: 'informacio',
    bookings: 'foglalasok',
    messages: 'uzenetek',
    notifications: 'ertesitesek',
    services: 'szolgaltatasok',
    providers: 'szolgaltatok',
    coupons: 'kuponok',
    taxes: 'adozas',
    'taxes/create': 'adozas/letrehozas',
    experiences: 'tapasztalatok',
    'experiences/create': 'tapasztalatok/letrehozas',
    certificates: 'tanusitvanyok',
    'certificates/create': 'tanusitvanyok/letrehozas',
    requests: 'keresek',
    'requests/create': 'keresek/letrehozas',
    therapists: 'terapeutak',
    connections: 'kapcsolatok',
    amenities: 'extra-szolgaltatasok',
    'amenities/create': 'extra-szolgaltatasok/letrehozas',
    favorites: 'kedvencek',
    options: 'lehetosegek',
    'options/create': 'lehetosegek/letrehozas',
    'missing-data': 'hianyzo-adatok',
    addresses: 'cimek',
    'addresses/edit/:id': 'cimek/szerkesztes/:id',
    'addresses/create': 'cimek/letrehozas',
    'addresses/create-simple': 'cimek/egyszeru-letrehozas',
    'services/edit/:id': 'szolgaltatasok/szerkesztes/:id',
    availabilities: 'elerhetosegek',
    'availabilities/create': 'elerhetosegek/letrehozas',
    reviews: 'ertekelesek',
    terms: 'feltetelek',
    business: 'uzlet',
    wallet: 'penztarca',
    wizard: 'varazslo',
    integrations: 'integraciok',
    completion: 'befejezes',
    create: 'letrehozas',
    'create-simple': 'egyszeru-letrehozas',
    edit: 'szerkesztes',
    'edit/:id': 'szerkesztes/:id',
    galleries: 'galeriak',
    'galleries/create': 'galeriak/letrehozas',
    'google-calendar': 'google-calendar',
    'create-profile': 'profil-letrehozas',
    ':id/summary': ':id/osszefoglalas',
    summary: 'osszefoglalas',
    about: 'rolam',
    faq: 'gyik',
    feedback: 'visszajelzes',
    'terms-of-service': 'szolgaltatasi-feltetelek',
    'terms-of-use': 'hasznalati-feltetelek',
    'partner-terms': 'partner-feltetelek',
    'privacy-policy': 'adatvedelmi-nyilatkozat',
    'black-friday': 'fekete-pentek',
    'rapid-booking': 'gyors-foglalas',
    employees: 'alkalmazottak',
    customers: 'ugyfelek',
};
