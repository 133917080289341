import { Injectable } from '@angular/core';
import { Drivers, Storage } from '@ionic/storage';

import { GuideModel } from '../model/guide.model';

@Injectable({
    providedIn: 'root',
})
export class GuideService {
    guideModal: any;
    title: any;
    content: any;
    guide: GuideModel = new GuideModel({});
    storage: Storage;

    constructor() {
        this.storage = new Storage({
            name: 'trandth',
            storeName: 'guide',
            driverOrder: [Drivers.LocalStorage],
        });
        this.storage.create();
    }

    show(guide: any) {
        this.guide = new GuideModel(guide);
        this.guideModal.present();
    }

    async getShowed(id) {
        return await this.storage.get(id);
    }

    hide() {
        if (this.guide.id) {
            this.storage.set(this.guide.id, true);
        }
        this.guideModal.dismiss();
    }
}
