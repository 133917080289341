import { BaseModel } from './base.model';

export class HighlightModel extends BaseModel {
    id: number;
    title: string;
    desc: string;
    icon: string;
    salon_id: string;
    created_at: string;
    updated_at: string;
}
