import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsAdminDirective } from '@app/shared/directive/is-admin.directive';
import { MediaQueryDirective } from '@app/shared/directive/media-query.directive';
import { TranslateModule } from '@ngx-translate/core';
import { LightboxModule } from 'ngx-lightbox';
import { StickyDirectiveModule } from 'ngx-sticky-directive';

import { DisableLinkDirective } from './directive/disable-link.directive';
import { GoogleSigningButtonDirective } from './directive/google-signing-button.directive';
import { HideHeaderDirective } from './directive/hide-header.directive';
import { IsCustomerDirective } from './directive/is-customer.directive';
import { IsLoggedInDirective } from './directive/is-logged-in.directive';
import { IsNotLoggedInDirective } from './directive/is-not-logged-in.directive';
import { IsProviderDirective } from './directive/is-provider.directive';
import { ObserveVisibilityDirective } from './directive/observe-visibility.directive';
import { PlatformClassDirective } from './directive/platform-class.directive';
import {
    DesktopPlatformStructuralDirective,
    MobilePlatformStructuralDirective,
    NotDesktopPlatformStructuralDirective,
    TabletPlatformStructuralDirective,
} from './directive/platform-structural.directive';
import { PreventDoubleClickDirective } from './directive/prevent-double-click.directive';
import { ApitransPipe } from './pipe/apitrans.pipe';
import { BookingStatusPipe } from './pipe/booking-status.pipe';
import { CurrencyPipe } from './pipe/currency.pipe';
import { HideContactsPipe } from './pipe/hide-contacts.pipe';
import { LocalizedCurrencyPipe } from './pipe/localized-currency.pipe';
import { LocalizedDatePipe } from './pipe/localized-date.pipe';
import { LocalizedDistancePipe } from './pipe/localized-distance.pipe';
import { LocalizedHeightPipe } from './pipe/localized-height.pipe';
import { LocalizedWeightPipe } from './pipe/localized-weight.pipe';
import { PaymentStatusPipe } from './pipe/payment-status.pipe';
import { TranslateUrlPipe } from './pipe/translate-url.pipe';
import { TruncatePipe } from './pipe/truncate.pipe';

@NgModule({
    declarations: [
        HideHeaderDirective,
        TruncatePipe,
        BookingStatusPipe,
        CurrencyPipe,
        ApitransPipe,
        PaymentStatusPipe,
        PreventDoubleClickDirective,
        ObserveVisibilityDirective,
        TranslateUrlPipe,
        DisableLinkDirective,
        LocalizedDatePipe,
        LocalizedCurrencyPipe,
        LocalizedDistancePipe,
        LocalizedWeightPipe,
        LocalizedHeightPipe,
        PlatformClassDirective,
        MobilePlatformStructuralDirective,
        TabletPlatformStructuralDirective,
        DesktopPlatformStructuralDirective,
        NotDesktopPlatformStructuralDirective,
        IsLoggedInDirective,
        IsNotLoggedInDirective,
        GoogleSigningButtonDirective,
        IsProviderDirective,
        IsCustomerDirective,
        IsAdminDirective,
        HideContactsPipe,
        MediaQueryDirective,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        LightboxModule,
        StickyDirectiveModule,
    ],
    exports: [
        HideHeaderDirective,
        TranslateModule,
        TruncatePipe,
        BookingStatusPipe,
        CurrencyPipe,
        ApitransPipe,
        PreventDoubleClickDirective,
        ObserveVisibilityDirective,
        TranslateUrlPipe,
        DisableLinkDirective,
        LocalizedDatePipe,
        LocalizedCurrencyPipe,
        LocalizedDistancePipe,
        LocalizedWeightPipe,
        LocalizedHeightPipe,
        HideContactsPipe,
        PlatformClassDirective,
        MobilePlatformStructuralDirective,
        TabletPlatformStructuralDirective,
        DesktopPlatformStructuralDirective,
        NotDesktopPlatformStructuralDirective,
        LightboxModule,
        StickyDirectiveModule,
        IsLoggedInDirective,
        IsNotLoggedInDirective,
        IsProviderDirective,
        IsCustomerDirective,
        IsAdminDirective,
        GoogleSigningButtonDirective,
        MediaQueryDirective,
    ],
})
export class SharedModule {}
