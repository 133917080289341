import { BaseModel } from './base.model';

export class FilterModel extends BaseModel {
    keywords: string = undefined;
    address: any = undefined;
    conversion = true;
    defaultAddress: any = undefined;
    location: any = undefined;
    areaLatLng: any = undefined;
    radius: any = undefined;
    groupBy: any = undefined;
    date_from: any = undefined;
    date_to: any = undefined;
    distance_range: any = undefined;
    gender: string = undefined;
    duration: any = 0;
    categories: any = [];
    category: any = undefined; //  instant_booking, top_rated, trending_week, available_now, recommended, open, private_rooms, most_reviewed, new, most_active, most_popular
    price_range: any = {
        lower: 0,
        upper: 0,
    };
    age_range: any = {
        lower: 0,
        upper: 0,
    };
    order_by = undefined;
    sorted_by = undefined;
    limit = 10;
    offset = 0;

    constructor(input) {
        super(input);
    }

    reset() {
        this.date_from = undefined;
        this.date_to = undefined;
        this.distance_range = undefined;
        this.gender = undefined;
        this.duration = 0;
        this.categories = [];
        this.category = undefined;
        this.price_range = {
            lower: 0,
            upper: 0,
        };
        this.age_range = {
            lower: 0,
            upper: 0,
        };
        this.order_by = undefined;
        this.sorted_by = undefined;
        this.conversion = false;
    }

    public set ageRange(value: any) {
        console.log(value);
        this.age_range = value;
    }
}
