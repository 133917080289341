<ion-grid>
    <ion-col class="vertical-center-items" [formGroup]="phoneForm">
        <ion-input mbsc-select
                   formControlName="countryCode"
                   [themeVariant]="appService.getTheme()"
                   [itemTemplate]="itemTemp"
                   [filter]="true"
                   [data]="data"
                   (onChange)="onCountryCodeChange()"
                   [touchUi]="'auto'">
        </ion-input>
        <ng-template #itemTemp let-item>
            <div class="md-country-picker-item">
                <img class="md-country-picker-flag" [src]="'https://flagsapi.com/' + item.value.code + '/flat/32.png'" />
                {{ item.display }}
            </div>
        </ng-template>
        <ion-input
            type="tel"
            formControlName="phoneNumber"
            [placeholder]="placeholder | translate"
            (ionInput)="onPhoneNumberChange($event)"
        ></ion-input>
    </ion-col>
</ion-grid>
