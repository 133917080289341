import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import {
    METRICS_MAP,
    SupportedDistanceUnitType,
} from '@app/shared/constant/localization-metrics.constant';
import {
    DEFAULT_LANGUAGE,
    SupportedLanguagesType,
} from '@app/shared/constant/localization.constant';
import { ResponseInterface } from '@app/shared/interface/response.interface';
import { ProviderModel } from '@app/shared/model/provider.model';
import { NavigationService } from '@app/shared/service/navigation.service';
import {
    SupportedThemesType,
    ThemeService,
} from '@app/shared/service/theme.service';
import { environment } from '@environments/environment';
import { LoadingController, Platform } from '@ionic/angular';
import { localeEn, localeHu } from '@mobiscroll/angular';
import { MbscLocale } from '@mobiscroll/angular/dist/js/i18n/locale';
import { TranslateService } from '@ngx-translate/core';
import { of, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import CountryCodes from 'src/assets/CountryCodes.json';

import { AddressModel } from '../model/address.model';
import { AppSettingModel } from '../model/app-setting.model';
import { CategoryModel } from '../model/category.model';
import { CurrencyModel } from '../model/currency.model';
import { SettingModel } from '../model/setting.model';

import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    public location: AddressModel = undefined; // location
    public ready = false;
    public cookieModal: any;
    public loginModal: any;
    public bookingModal: any;
    public platforms: any;
    public currentProvider: ProviderModel;
    public sideMenu: any;
    public sideMenuClick$: EventEmitter<any> = new EventEmitter<any>();
    public openInquireModal$: EventEmitter<any> = new EventEmitter();
    public urlSearchParams: any;
    public routeSnapshot: any;
    public counters = {
        messages: 0,
        unReadMessages: 0,
        providers: 0,
        addresses: 0,
        notifications: 0,
        unReadNotifications: 0,
        coupons: 0,
        services: 0,
        amenities: 0,
        bookings: 0,
    };
    public showModal = false;
    public showModalToobar = true;
    public bookingStatuses: any = [];
    public paymentStatuses: any = [];
    public translations: any = [];
    public paymentMethods: any = [];
    public inProgress = false;
    public loader: any;
    // event listeners
    public locationChange: EventEmitter<any> = new EventEmitter<any>();
    public readyEvent: EventEmitter<any> = new EventEmitter<any>();
    public changeAppSettings$: EventEmitter<any> = new EventEmitter<any>();
    public changeCurrentProvider$: EventEmitter<any> = new EventEmitter<any>();
    // collections
    public categories: CategoryModel[] = [];
    public locales = [];
    public settings: SettingModel;
    public appSettings = new AppSettingModel({});
    public currencies: CurrencyModel[] = [];
    public currency: CurrencyModel = undefined;
    public distanceUnit: SupportedDistanceUnitType = undefined;
    public locale: string;
    public calendarLocale: MbscLocale;
    static LOCALE: any;

    constructor(
        public http: HttpClient,
        public platform: Platform,
        public loadingCtrl: LoadingController,
        private translate: TranslateService,
        private storage: StorageService,
        private themeService: ThemeService,
    ) {
        this.platforms = this.platform.platforms();
        // Create Loading Controller
        this.loadingCtrl
            .create({
                message: '',
                duration: 10000,
                spinner: 'dots',
            })
            .then((response) => {
                this.loader = response;
            });

        // Change Settings Event Listener
        this.changeAppSettings$.subscribe((settings) => {
            if (settings) {
                this.setAppSettings(settings);
                this.setLocale(settings.locale);
                if (settings.dark_mode !== null) {
                    this.themeService.activeTheme = settings.dark_mode
                        ? ThemeService.DARK_THEME
                        : ThemeService.LIGHT_THEME;
                }
            }
            this.showCookieModal();
        });

        this.restoreAppSettings();
    }

    /**
     * Get Slides From Server
     */
    getSlides() {
        return this.http.get(environment.apiUrl + '/slides');
    }

    fetchSettings() {
        return this.http.get(environment.apiUrl + '/settings');
    }

    getCalendarLocale() {
        return this.calendarLocale;
    }

    setLocation(address: AddressModel) {
        this.location = address;
        // store to local storage
        this.storage.set('location', address);
        this.locationChange.emit(address);
    }

    getLocation() {
        // check if address is set in the local storage
        if (
            !this.location &&
            localStorage.getItem('trandth/app/location') !== 'null' &&
            localStorage.getItem('trandth/app/location') !== null
        ) {
            return new AddressModel(
                JSON.parse(localStorage.getItem('trandth/app/location')),
            );
        }
        return this.location;
    }

    isDarkMode() {
        if (this.themeService.currentTheme) {
            return this.themeService.currentTheme === 'dark';
        }
        return false;
    }

    setCalendarLocale(locale = DEFAULT_LANGUAGE) {
        const locales = {
            en: localeEn,
            hu: localeHu,
            default: localeEn,
        };

        this.calendarLocale = locales[locale] || locales.default;
    }

    /**
     * Get Locale
     */
    getLocale() {
        return this.locale;
    }

    /**
     * Get current theme
     * @returns {string} Currently active theme
     */
    getTheme(): SupportedThemesType {
        return this.themeService.currentTheme;
    }

    /**
     * Set Locale
     * @param locale
     */
    setLocale(locale: SupportedLanguagesType) {
        if (locale && this.locale !== locale) {
            const localizedMetrics = METRICS_MAP[locale];

            this.appSettings.locale = locale;
            this.locale = locale;
            AppService.LOCALE = locale;
            this.appSettings.weight_unit =
                localizedMetrics?.weight_unit || METRICS_MAP.en.weight_unit;
            this.appSettings.height_unit =
                localizedMetrics?.height_unit || METRICS_MAP.en.height_unit;
            this.appSettings.distance_unit =
                localizedMetrics?.distance_unit || METRICS_MAP.en.distance_unit;
            this.appSettings.currency_code =
                localizedMetrics?.currency_code || METRICS_MAP.en.currency_code;

            this.setCalendarLocale(locale);
            this.translate.use(locale);
        }
    }

    setCurrentProvider(provider: ProviderModel) {
        this.currentProvider = provider;
        // store to local storage
        this.storage.set('currentProvider', provider);
        // emit change event
        this.changeCurrentProvider$.emit(provider);
    }

    getCurrentProvider(): ProviderModel {
        return this.currentProvider;
    }

    getUrlParams(): any {
        return new URLSearchParams(window.location.search);
    }

    /**
     * Retrieves the locale based on the strategy employed.
     * @returns {any | undefined} The retrieved locale value.
     */
    getLocaleByStrategy(): any | undefined {
        const languageParameterFromUrl = this.getLanguageParameterFromUrl();
        const languageSegmentFromPath = this.getLanguageSegmentFromPath();
        const localeFromAppSettings = this.getLocaleFromAppSettings();

        const browserLang = this.translate.getBrowserLang();
        const supportedLanguagesMatcher = new RegExp(
            `^(${NavigationService.supportedLanguages.join('|')})`,
        );
        const languageFromBrowser = browserLang.match(supportedLanguagesMatcher)
            ? browserLang
            : null;

        return (
            languageParameterFromUrl ||
            languageSegmentFromPath ||
            localeFromAppSettings ||
            languageFromBrowser ||
            DEFAULT_LANGUAGE
        );
    }

    /**
     * Retrieves the value of the "l" parameter from the current URL's query string.
     * If the parameter is not present, returns null.
     *
     * @returns {string|null} The value of the "l" parameter from the URL, or null if not present.
     */
    getLanguageParameterFromUrl(): string | null {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('l');
    }

    /**
     * Retrieves the language segment from the current path.
     * @returns {string|boolean} The language segment if it exists, otherwise false.
     */
    getLanguageSegmentFromPath(): string | false {
        const lFromPath: any = window.location.pathname.split('/')[1];

        if (!NavigationService.supportedLanguages.includes(lFromPath)) {
            return false;
        }

        return lFromPath;
    }

    /**
     * Retrieves the locale from the application settings.
     *
     * @returns {string|null} The locale obtained from the application settings, or null if not found.
     */
    getLocaleFromAppSettings(): string | null {
        const appSettings = JSON.parse(
            localStorage.getItem('trandth/app/appSettings'),
        );
        return appSettings?.locale ? appSettings.locale : null;
    }

    /**
     * Gets the dial code based on the user's IP.
     * @returns {Observable<number>} An Observable emitting the dial code.
     */
    getDialCode() {
        // @TODO: appservice needs to set the dial_code based on the IP of the user
        return this.appSettings.dial_code
            ? of(this.appSettings.dial_code)
            : of(parseInt(CountryCodes[95].dial_code, 10));
    }

    /**
     * Returns the distance unit from the app settings.
     *
     * @returns {string} The distance unit. Returns undefined if app settings or distance unit is not defined.
     */
    getDistanceUnit() {
        return this.appSettings?.distance_unit;
    }

    /**
     * Get the currency based on the currency code stored in the app settings.
     *
     * @returns {string} The currency code.
     */
    getCurrency() {
        return this.getCurrecyByCode(this.appSettings.currency_code);
    }

    /**
     * Get Currencies List
     *
     * @returns {Array} Array of currencies
     */
    getCurrencies() {
        return this.currencies;
    }

    /**
     * Set Currency
     * @param {string} currency - The currency to be set.
     */
    setCurrency(currency) {
        this.currency = currency;
    }

    /**
     * Retrieves a currency object by its ID.
     * @param {any} cId - The ID of the currency.
     * @return {CurrencyModel} - The currency object with the specified ID.
     */
    getCurrecyById(cId: any): CurrencyModel {
        return this.currencies.find((item) => {
            return item.id === cId;
        });
    }

    /**
     * Retrieves a currency by its code.
     *
     * @param {any} sCode - The currency code to search for.
     * @return {CurrencyModel} The currency model matching the given code, or undefined if not found.
     */
    getCurrecyByCode(sCode: any): CurrencyModel {
        return this.currencies.find((item) => {
            return item.code === sCode;
        });
    }

    /**
     * Set Global Settings
     * @param {object} settings - The settings object to be set.
     */
    setSettings(settings) {
        this.settings = new SettingModel(settings);
    }

    /**
     * Sets the application settings.
     *
     * @param {Object} settings - The new application settings to be set.
     */
    setAppSettings(settings) {
        this.appSettings = new AppSettingModel({ ...settings });
    }

    /**
     * Stores the application settings.
     *
     * @param {Partial<AppSettingModel>} settings - The partial application settings to be stored.
     *
     * @return {void}
     */
    storeAppSettings(settings?: Partial<AppSettingModel>) {
        this.appSettings = {
            ...this.appSettings,
            ...settings,
        } as AppSettingModel;
        this.storage.set('appSettings', this.appSettings).then((results) => {
            this.changeAppSettings$.emit(results);
        });
    }

    /**
     * Restores app settings from storage.
     * @function restoreAppSettings
     * @memberof YourClassName
     * @returns {void}
     */
    restoreAppSettings() {
        // restore appSettings
        this.storage.get('appSettings').then((results) => {
            this.changeAppSettings$.emit({
                ...this.appSettings,
                ...results,
            });
        });
    }

    /**
     * Get the application settings.
     *
     * @return {Object} The application settings.
     */
    getAppSettings() {
        return this.appSettings;
    }

    /**
     * Retrieves the current settings.
     *
     * @returns {Object} The current settings.
     */
    getSettings() {
        return this.settings;
    }

    /**
     * Retrieves a setting value based on the provided key.
     *
     * @param {string} key - The key for the setting value to retrieve.
     * @return {*} - The value associated with the provided key, or false if the key does not exist.
     */
    getSetting(key) {
        if (this.settings[key]) {
            return this.settings[key];
        }
        return false;
    }

    /**
     * Set Setting by Key
     *
     * @param {string} key - The key of the setting to be set
     * @param {any} value - The value to be set for the specified key
     *
     * @return {void}
     */
    setSetting(key, value) {
        const settings = Object.assign({}, this.settings);
        settings[key] = value;
        this.settings = new SettingModel(settings);
    }

    /**
     * Checks if the platform is mobile.
     *
     * @returns {boolean} Returns true if the platform is mobile, otherwise false.
     */
    isMobile() {
        return this.platform.is('mobile') && !this.platform.is('tablet');
    }

    /**
     * Checks if the current platform is a tablet.
     *
     * @return {boolean} Returns `true` if the platform is a tablet, `false` otherwise.
     */
    isTablet() {
        return this.platform.is('mobile') && this.platform.is('tablet');
    }

    /**
     * Checks if the current platform is desktop.
     *
     * @return {boolean} Returns true if the platform is desktop, false otherwise.
     */
    isDesktop() {
        return this.platform.is('desktop');
    }

    /**
     * Retrieves the current platform based on the type of device being used.
     *
     * @returns {string} The platform type can be one of the following: 'tablet', 'mobile', or 'desktop'.
     */
    getPlatform() {
        if (this.isTablet()) {
            return 'tablet';
        } else if (this.isMobile()) {
            return 'mobile';
        } else {
            return 'desktop';
        }
    }

    /**
     * Fetches the default currency.
     *
     * @returns {Currency} The default currency object.
     */
    getDefaultCurrency() {
        return this.getCurrecyByCode(this.appSettings.currency_code);
    }

    /**
     * Creates a loader using the provided loading controller.
     * @returns {Promise<Loader>} A promise that resolves with the created loader.
     */
    async createLoader() {
        await this.loadingCtrl
            .create({
                message: '',
                duration: 10000,
                spinner: 'dots',
            })
            .then((response) => {
                this.loader = response;
            });
        return await this.loader;
    }

    /**
     * Show Loader.
     *
     * This method sets the 'inProgress' property to true to indicate that a loader should be displayed.
     *
     * @return {void}
     */
    showLoader() {
        this.inProgress = true;
    }

    /**
     * Stops the loader.
     *
     * @returns {void}
     */
    stopLoader() {
        this.inProgress = false;
    }

    /**
     * Show Cookie Modal
     *
     * @returns {void}
     */
    showCookieModal() {
        if (!this.appSettings?.cookie_decide && this.cookieModal) {
            this.cookieModal.present();
        }
    }

    /**
     * Allows cookies to be enabled in the application.
     *
     * This method updates the application settings to enable the cookie policy and cookie decide features.
     * It also dismisses the cookie modal.
     *
     * @return {void}
     */
    allowCookies() {
        this.storeAppSettings({
            cookie_policy: true,
            cookie_decide: true,
        });
        this.cookieModal.dismiss();
    }

    /**
     * Deny Cookies
     *
     * @returns {void}
     */
    denyCookies() {
        this.storeAppSettings({
            cookie_policy: false,
            cookie_decide: true,
        });
        this.cookieModal.dismiss();
    }

    /**
     * Returns the translated value for a given key.
     *
     * @param {string} key - The key used to retrieve the translation value.
     * @param {unknown} [params] - Optional parameters used for translation interpolation.
     * @return {any} The translated value for the given key.
     */
    getTrans(key, params?: unknown) {
        return this.translate.instant(key, params);
    }

    __(key, params?: unknown) {
        return this.translate.instant(key, params);
    }

    /**
     * Retrieves a category by its ID.
     *
     * @param {number} catId - The ID of the category to retrieve.
     * @return {object|boolean} - The category object if found, or false if not found.
     */
    getCategoryById(catId) {
        let result: any = false;
        catId = Number(catId);
        this.categories.forEach((item) => {
            if (item.id === catId) {
                result = item;
            } else {
                item.sub_categories.forEach((item) => {
                    if (item.id === catId) {
                        result = item;
                    }
                });
            }
        });

        return result;
    }

    /**
     * Checks if the given platforms are included in the list of platforms.
     *
     * @param {Array} platforms - An array of platforms to check.
     *
     * @return {boolean} - Returns true if at least one of the given platforms is included in the list of platforms, otherwise returns false.
     */
    hasPlatforms(platforms) {
        return (
            this.platforms.filter((platform) => {
                return platforms.includes(platform);
            }).length > 0
        );
    }

    /**
     * Resets the counters for messages, unread messages, providers, addresses, notifications,
     * unread notifications, coupons, services, amenities, and bookings.
     *
     * @return {void}
     */
    resetCounters() {
        this.counters = {
            messages: 0,
            unReadMessages: 0,
            providers: 0,
            addresses: 0,
            notifications: 0,
            unReadNotifications: 0,
            coupons: 0,
            services: 0,
            amenities: 0,
            bookings: 0,
        };
    }
}
