import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { CouponModel } from '../model/coupon.model';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    public change: EventEmitter<any> = new EventEmitter<any>();

    constructor(private http: HttpClient) {}

    countUnReadMessages() {
        return this.http.get(environment.apiUrl + '/messages/count');
    }

    getMessagesByGroup(groupId, read = false) {
        const queryParams = {
            group_id: groupId,
            with: 'user',
            read: read,
        };
        return this.http.get(environment.apiUrl + '/messages', {
            params: queryParams,
        });
    }

    create(data: any = {}) {
        return this.http.post(environment.apiUrl + '/messages', data).pipe(
            map((res: any) => {
                if (res.success) {
                    this.change.emit(new CouponModel(res.data));
                }
                return res;
            }),
        );
    }

    update(id: number, data: any = {}) {
        data['id'] = id;
        return this.http.put(environment.apiUrl + '/messages/' + id, data).pipe(
            map((res: any) => {
                if (res.success) {
                    this.change.emit(new CouponModel(res.data));
                }
                return res;
            }),
        );
    }

    delete(id) {
        return this.http.delete(environment.apiUrl + '/messages/' + id).pipe(
            map((res: any) => {
                if (res.success) {
                    this.change.emit();
                }
                return res;
            }),
        );
    }
}
