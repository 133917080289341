import {
    Directive,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { Platform } from '@ionic/angular';

@Directive({
    selector: '[pMobile]',
})
export class MobilePlatformStructuralDirective implements OnInit {
    constructor(
        private platform: Platform,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
    ) {}

    ngOnInit() {
        const isMobile = this.platform.is('ios') || this.platform.is('android');

        if (isMobile) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainerRef.clear();
        }
    }
}

@Directive({
    selector: '[pTablet]',
})
export class TabletPlatformStructuralDirective implements OnInit {
    constructor(
        private platform: Platform,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
    ) {}

    ngOnInit() {
        const isTablet = this.platform.is('ipad') || this.platform.is('tablet');

        if (isTablet) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainerRef.clear();
        }
    }
}

@Directive({
    selector: '[pDesktop]',
})
export class DesktopPlatformStructuralDirective implements OnInit {
    constructor(
        private platform: Platform,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
    ) {}

    ngOnInit() {
        const isDesktop = this.platform.is('desktop');

        if (isDesktop) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainerRef.clear();
        }
    }
}

@Directive({
    selector: '[pNotDesktop]',
})
export class NotDesktopPlatformStructuralDirective implements OnInit {
    constructor(
        private platform: Platform,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
    ) {}

    ngOnInit() {
        const isDesktop = this.platform.is('desktop');

        if (!isDesktop) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainerRef.clear();
        }
    }
}
