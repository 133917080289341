import { AddressModel } from './address.model';
import { BaseModel } from './base.model';
import { CategoryModel } from './category.model';
import { CurrencyModel } from './currency.model';
import { DynamicPriceModel } from './dynamic-price.model';
import { MediaModel } from './media.model';
import { OptionModel } from './option.model';
import { ProviderModel } from './provider.model';
import { RequestModel } from './request.model';

export class ServiceModel extends BaseModel {
    id: any; // The ID of the service
    e_service_id: number; // The ID of the e-service
    pivot_id: number; // The pivot ID
    name: string; // The name of the service
    description: string; // The description of the service
    duration: string; // The duration of the service

    // Pricing and Currency
    price: number; // The price of the service
    currency_id: number; // The ID of the currency
    currency: any; // The currency object
    discount_price: number; // The discounted price of the service
    dynamic_price: any; // The dynamic price object
    outcall_tax_id: number; // The ID of the outcall tax

    // Location and Distance
    longitude: any; // The longitude of the service
    latitude: any; // The latitude of the service
    address: any; // The address of the service
    distance: any; // The distance of the service

    // Availability and Settings
    featured: boolean; // Indicates if the service is featured
    enable_booking: boolean; // Indicates if booking is enabled for the service
    enable_at_customer_address: boolean; // Indicates if the service is enabled at customer's address
    enable_at_salon: boolean; // Indicates if the service is enabled at the salon
    available: boolean; // Indicates if the service is available

    // Salon and Provider Information
    salon_id: number; // The ID of the salon
    salon: any; // The salon object (SalonModel)
    salons: any; // The salon objects

    // Media and Categories
    has_media: boolean; // Indicates if the service has media
    media: any; // The media objects (MediaModel) (1..n)
    categories: any; // The category objects (CategoryModel) (1..n)

    // Addresses and Options
    addresses: any; // The address objects (AddressModel) (1..n)
    options: any; // The options object

    // Additional Information
    custom_fields: any; // Custom fields object
    is_favorite: boolean; // Indicates if the service is marked as favorite
    requests: any; // The request objects
    prices: any; // The prices object

    /**
     * Initializes a new instance of the class.
     * @param input The input object.
     */
    constructor(input) {
        super(input);

        // Set pivot_id and id to e_service_id if it exists
        if (this.e_service_id) {
            this.pivot_id = this.e_service_id;
            this.id = this.e_service_id;
        }

        // Create a new ProviderModel object for the salon property if it exists
        this.salon = this.salon ? new ProviderModel(this.salon) : null;

        // Create an array of new MediaModel objects for the media property if it exists
        if (this.media) {
            this.media = this.media
                .map(
                    (item, index) =>
                        new MediaModel({
                            ...item,
                            order_column: !isNaN(item.order_column)
                                ? item.order_column
                                : index,
                        }),
                )
                .sort((a, b) => a.order_column - b.order_column);
        } else {
            this.media = [];
        }

        // Create an array of new CategoryModel objects for the categories property if it exists
        this.categories = this.categories
            ? this.categories.map((item) => new CategoryModel(item))
            : [];

        // Create an array of new ProviderModel objects for the salons property if it exists
        this.salons = this.salons
            ? this.salons.map((item) => new ProviderModel(item))
            : [];

        // Create an array of new AddressModel objects for the addresses property if it exists
        this.addresses = this.addresses
            ? this.addresses.map((item) => new AddressModel(item))
            : [];

        // Create an array of new RequestModel objects for the requests property if it exists
        this.requests = this.requests
            ? this.requests.map((item) => new RequestModel(item))
            : [];

        // Create an array of new OptionModel objects for the options property if it exists
        this.options = this.options
            ? this.options.map((item) => new OptionModel(item))
            : [];

        // Create a new DynamicPriceModel object for the dynamic_price property if it exists
        this.dynamic_price = this.dynamic_price
            ? new DynamicPriceModel(this.dynamic_price)
            : null;

        // Create a new CurrencyModel object for the currency property if it exists
        this.currency = this.currency ? new CurrencyModel(this.currency) : null;
    }

    /**
     * Get the name.
     * @returns The name.
     */
    getName() {
        return this.name;
    }

    /**
     * Get the description.
     * @returns The description.
     */
    getDescription() {
        return this.description;
    }

    /**
     * Check if the service has media.
     * @returns True if the service has media, false otherwise.
     */
    hasMedia() {
        return this.media && this.has_media;
    }

    /**
     * Get the URL of the first media if it exists.
     * @returns The URL of the first media, or false if no media exists.
     */
    getMedia() {
        return this.hasMedia() ? this.media[0].getUrl() : false;
    }

    /**
     * Get the thumbnail URL of the first media if it exists.
     * @returns The thumbnail URL of the first media, or false if no media exists.
     */
    getThumb() {
        return this.hasMedia()
            ? this.media
                  .sort((a, b) => a.order_column - b.order_column)[0]
                  .getThumb()
            : false;
    }

    /**
     * Get the price.
     * @returns The discount price if available, otherwise the regular price.
     */
    getPrice(): number {
        return this.discount_price ? this.discount_price : this.price;
    }

    /**
     * Get the currency.
     * @returns The currency.
     */
    getCurrecy() {
        return this.currency;
    }

    /**
     * Get the discount price.
     * @returns The discount price if available and greater than 0, otherwise 0.
     */
    getDiscountPrice() {
        return (this.discount_price ?? 0) > 0 ? this.price : 0;
    }

    /**
     * Get the duration as an integer.
     * @returns The duration.
     */
    getDuration() {
        return parseInt(this.duration);
    }

    /**
     * Get the distance as a formatted string.
     * @returns The formatted distance if available, otherwise false.
     */
    getDistance() {
        if (this.distance) {
            return this.distance.toFixed(1) + ' '; // + AppService.getSettings().distance_unit
        }
        return false;
    }
}
