import { BaseModel } from './base.model';
import { MediaModel } from './media.model';
import { ProviderModel } from './provider.model';

export class GalleryModel extends BaseModel {
    id: number;
    name: string;
    description: string;
    owner_id: number;
    data: any;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    has_media: boolean;
    // collections
    salons: ProviderModel[];
    media: MediaModel[];
    constructor(props) {
        super(props);

        if (this.salons) {
            this.salons = this.salons.map((item) => new ProviderModel(item));
        } else {
            this.salons = [];
        }
        // Media
        if (this.hasMedia()) {
            const media = [...this.media]
                .map(
                    (item, index) =>
                        new MediaModel({
                            ...item,
                            order_column: !isNaN(item.order_column)
                                ? item.order_column
                                : index,
                        }),
                )
                .sort((a, b) => a.order_column - b.order_column);

            this.media = [...media];
        }
    }

    getName() {
        return this.name;
    }

    getDescription() {
        return this.description;
    }

    getMedia() {
        return this.hasMedia() ? this.media[0].getUrl() : false;
    }

    getThumb() {
        return this.hasMedia()
            ? this.media
                  .sort((a, b) => a.order_column - b.order_column)[0]
                  .getThumb()
            : false;
    }

    hasMedia() {
        return this.has_media;
    }
}
