import {
    DEFAULT_LANGUAGE,
    SupportedLanguagesType,
} from '@app/shared/constant/localization.constant';
import { AppService } from '@app/shared/service/app.service';
import { NavigationService } from '@app/shared/service/navigation.service';

import { Deserializable } from './deserializable.model';

export class BaseModel implements Deserializable {
    public cast =
        false ||
        {
            // for example
            // name: 'string',
            // services: 'ServiceModel[]',
            // address: 'AddressModel',
        };
    /**
     * Indicates if the current object is a new item.
     */
    isNewItem = false;
    /**
     * This is the constructor for the class.
     *
     * @param input - The input object for the constructor.
     */
    constructor(input: any) {
        // create id if not provided
        if (input && typeof input.id === 'undefined') {
            input.id = this.getRandomId();
            this.isNewItem = true;
        }

        // deserialize the input
        this.deserialize(input);

        // cast the input
        this.doCast(input);
    }

    /**
     * Casts properties of an object according to the given casting rules.
     *
     * @param {any} input - The object to be casted.
     */
    doCast(input: any) {
        if (this.cast && typeof this.cast === 'object') {
            Object.keys(this.cast).forEach((key) => {
                if (input[key] && typeof input[key] === 'object') {
                    if (Array.isArray(input[key])) {
                        // for example: this.cast.services = "ServiceModel[]"
                        // input[key] = input[key].map((item) => {
                        //     return new ServiceModel(item);
                        // });
                        input[key] = input[key].map((item) => {
                            return new this.cast[key](item);
                        });
                    } else {
                        // for example: this.cast.address = "AddressModel"
                        // input[key] = new AddressModel(input[key]);
                        input[key] = new this.cast[key](input[key]);
                    }
                } else {
                    // for example: this.cast.name = "string"
                    // set default value if input[key] is undefined
                    if (typeof input[key] === 'undefined') {
                        input[key] = '';
                    }
                }
            });
        }
    }

    /**
     * Deserialize the input object and assign its values to the current object.
     *
     * @param input - The input object to deserialize.
     * @returns The deserialized object.
     */
    deserialize(input: any): this {
        if (input && input !== 'undefined') {
            // Iterate over each key-value pair in the input object
            Object.entries(input).forEach(([key, value]) => {
                if (this.isValueTranslatedObject(value)) {
                    // If the value is a translated object, replace it with the localized value
                    input[key] = this.getValueByLocale(value);
                } else {
                    // Otherwise, keep the value as is
                    input[key] = value;
                }
            });
            // Filter out any undefined values from the input object
            Object.keys(input).filter((key) => input[key] !== undefined);
            // Assign the deserialized values to the current object
            Object.assign(this, input);
        }
        return this;
    }

    /**
     * Generate a random ID between 100000 and 1100000
     * @returns {number} The random ID
     */
    getRandomId(): number {
        const min = 100000;
        const max = 1100000;
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    /**
     * Checks if the given value is a translated object.
     * A translated object is an object that has keys representing supported languages.
     *
     * @param value - The value to check
     * @returns True if the value is a translated object, false otherwise
     */
    isValueTranslatedObject(value) {
        // Check if the value is an object
        if (typeof value === 'object' && value !== null) {
            // Check if any of the object keys are supported languages
            if (
                Object.keys(value).some((key) =>
                    NavigationService.supportedLanguages.includes(
                        key as SupportedLanguagesType,
                    ),
                )
            ) {
                return true;
            }
        }
        return false;
    }

    /**
     * Retrieves the current locale.
     * @returns The current locale.
     */
    getLocale(): string {
        return AppService.LOCALE;
    }
    /**
     * Returns the value from an object based on the current locale.
     * If the value does not exist for the current locale, it will try to find
     * the value for the default language. If that also does not exist,
     * it will return the value for the first key in the object.
     *
     * @param oValue - The object containing the values.
     * @returns The value based on the current locale.
     */
    getValueByLocale(oValue: any) {
        const locale = this.getLocale();
        let sValue: any = '';
        // find locale key in object
        if (typeof oValue[locale] !== 'undefined') {
            sValue = oValue[locale];
        } else {
            // find default language key in object
            if (typeof oValue[DEFAULT_LANGUAGE] !== 'undefined') {
                sValue = oValue[DEFAULT_LANGUAGE];
            } else {
                // find first key in object
                sValue = oValue[Object.keys(oValue)[0]];
            }
        }

        return sValue;
    }
}
