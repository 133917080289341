import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    getClientErrorMessage(error: Error): string {
        return error.message ? error.message : error.toString();
    }

    getServerErrorMessage(error: HttpErrorResponse): string {
        return navigator.onLine ? error.message : 'No Internet Connection';
    }

    isSilentError(error: HttpErrorResponse): boolean {
        return (
            error.status === 401 || error.status === 409 || error.status === 422
        );
    }

    getErrorMessages(error: any): any {
        if (error.error.errors) {
            return Object.values(error.error.errors);
        }
        return [error.error.message];
    }

    getErrorMessagesAsText(error: HttpErrorResponse) {
        return this.getErrorMessages(error).join('<br>');
    }
}
