import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { AppService } from '../service/app.service';

@Injectable({
    providedIn: 'root',
})
export class LanguageGuard implements CanActivate {
    constructor(private appService: AppService) {}
    canActivate(): boolean {
        this.appService.setLocale(this.appService.getLocaleByStrategy());
        return true;
    }
}
