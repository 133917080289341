import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { CurrencyModel } from '../model/currency.model';
@Injectable({
    providedIn: 'root',
})
export class CurrencyService {
    public change: EventEmitter<any> = new EventEmitter<any>();
    constructor(private http: HttpClient) {}

    /**
     * Get Service
     * @param id
     */
    get(id: number) {
        const queryParams = {};
        return this.http.get(environment.apiUrl + '/currencies/' + id, {
            params: queryParams,
        });
    }

    getCurrencies() {
        const queryParams = {};
        return this.http.get(environment.apiUrl + '/currencies', {
            params: queryParams,
        });
    }

    create(data: any = {}) {
        return this.http.post(environment.apiUrl + '/currencies', data).pipe(
            map((res: any) => {
                if (res.success) {
                    this.change.emit(new CurrencyModel(res.data));
                }
                return res;
            }),
        );
    }

    update(id: number, data: any = {}) {
        data['id'] = id;
        return this.http
            .put(environment.apiUrl + '/currencies/' + id, data)
            .pipe(
                map((res: any) => {
                    if (res.success) {
                        this.change.emit(new CurrencyModel(res.data));
                    }
                    return res;
                }),
            );
    }

    delete(id) {
        return this.http.delete(environment.apiUrl + '/currencies/' + id).pipe(
            map((res: any) => {
                if (res.success) {
                    this.change.emit();
                }
                return res;
            }),
        );
    }
}
