import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ui-chip-select-option',
    templateUrl: './input-chip-option.component.html',
    styleUrls: ['./input-chip-option.component.scss'],
})
export class UiInputChipOptionComponent {
    selected = false;

    @Input() value;
    @Input() disabled = false;
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    onSelect() {
        if (!this.disabled) {
            this.selected = !this.selected;
            this.change.emit(this);
        }
    }
    setSelected(value: boolean) {
        this.selected = value;
    }
}
