import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ProviderModel } from '@app/shared/model/provider.model';
import { FilterService } from '@app/shared/service/filter.service';
import { environment } from '@environments/environment';
import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppService } from './app.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class ProviderService {
    /**
     * Represents an instance of the EventEmitter class.
     *
     * @typedef EventEmitter
     * @type {object}
     *
     * @description
     * The EventEmitter class provides a way to manage and emit custom events within an application.
     * It allows communication between different parts of the application by allowing components or services to emit events, and other components or services to subscribe to those events
     *.
     *
     * @constructor
     * Creates a new instance of the EventEmitter class.
     *
     * @param {any} [initialValue] - Optional initial value for the event emitter.
     *
     * @method emit
     * Emits an event with the specified value.
     *
     * @param {any} value - The value to be emitted.
     *
     * @method subscribe
     * Subscribes to the event and listens for emitted values.
     *
     * @param {Function} next - A function to be called whenever a new value is emitted.
     * @returns {Subscription} Returns a subscription object that can be used to unsubscribe from the event.
     *
     * @method unsubscribe
     * Unsubscribes from the event and stops listening for emitted values.
     *
     * @param {Subscription} subscription - The subscription object returned by the 'subscribe' method.
     *
     * @example
     * // Creating a new EventEmitter instance
     * const eventEmitter = new EventEmitter();
     *
     * // Subscribing to the event
     * const subscription = eventEmitter.subscribe((value) => {
     *   console.log(value);
     * });
     *
     * // Emitting a value
     * eventEmitter.emit('Hello, world!');
     *
     * // Unsubscribing from the event
     * eventEmitter.unsubscribe(subscription);
     */
    public change: EventEmitter<any> = new EventEmitter<any>();
    /**
     * A string representing the properties to expand in a response object.
     * This string has a specific format, where each property is separated by a semicolon (;).
     * The properties can be nested using dot notation.
     *
     * @type {string}
     *
     * @example
     * const expand = 'salonLevel;address;address.availabilities;salonReviews;employees;highlights;media;galleries;gallery;addresses;addresses.amenities;address.amenities;experiences;cert
     *ificates;taxes';
     * console.log(expand); // 'salonLevel;address;address.availabilities;salonReviews;employees;highlights;media;galleries;gallery;addresses;addresses.amenities;address.amenities;experiences
     *;certificates;taxes'
     */
    private readonly expand: string =
        'salonLevel;address;address.availabilities;salonReviews;employees;highlights;media;galleries;gallery;addresses;addresses.amenities;address.amenities;experiences;certificates;taxes';

    /**
     * Creates a new instance of the constructor.
     *
     * @param {HttpClient} http - The HttpClient service for making HTTP requests.
     * @param {AuthService} authService - The AuthService service for managing user authentication.
     * @param {AppService} appService - The AppService service for managing application-specific functionality.
     * @param {FilterService} filterService - The FilterService service for managing filtering logic.
     */
    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private appService: AppService,
        private filterService: FilterService,
    ) {}

    /**
     * Retrieves data from the server based on the specified ID.
     *
     * @param {number} id - The unique identifier of the data to retrieve.
     * @returns {Observable<any>} - An Observable that emits the retrieved data.
     */
    get(id: number): Observable<any> {
        const url = this.buildSalonUrl(id);
        const queryParams = this.buildDefaultQueryParams();
        return this.http
            .get(url, { params: queryParams })
            .pipe(map((response: any) => this.parseResponse(response)));
    }

    /**
     * Updates data for a specific id.
     *
     * @param {number} id - The id of the item to be updated.
     * @param {any} [data={}] - The data to be updated. Defaults to an empty object.
     * @return {Observable<any>} - An observable that emits the updated data.
     */
    update(id: number, data: any = {}): Observable<any> {
        const updatedData = this.prepareData(data, id);
        return this.http
            .put(`${environment.apiUrl}/provider/salons/${id}`, updatedData)
            .pipe(this.transformApiResponse());
    }

    /**
     * Creates a new record in the backend.
     *
     * @param {any} data - The data representing the new record. Default value is an empty object.
     * @returns {Observable<any>} - An Observable that emits the response from the backend after creating the record.
     */
    create(data: any = {}): Observable<any> {
        const preparedData = this.prepareData(data);
        return this.http
            .post(`${environment.apiUrl}/provider/salons`, preparedData)
            .pipe(this.transformApiResponse());
    }

    /**
     * Duplicate a salon by its ID.
     * @param {number} id - The ID of the salon to be duplicated.
     * @return {Observable<any>} - An observable representing the duplicate salon response.
     */
    duplicate(id: number): Observable<any> {
        return this.http
            .post(`${environment.apiUrl}/provider/salons/duplicate`, { id })
            .pipe(this.transformApiResponse());
    }

    /**
     * Retrieves the services for a given salon ID.
     *
     * @param {number} id - The ID of the salon.
     * @returns {Observable<any>} - An observable that emits the services data.
     */
    getServices(id: number): Observable<any> {
        const url = this.buildSalonUrl(id, '/eservices');
        const queryParams = this.buildDefaultQueryParams();
        return this.http.get(url, { params: queryParams });
    }

    /**
     * Search for salons based on given keywords and type.
     *
     * @param {string} keywords - The keywords to search for.
     * @param {string} [type] - The type of salon to filter the search results (optional).
     * @returns {Observable<any>} - An Observable that emits the search results as an array of salon objects.
     */
    search(keywords: string, type?: string): Observable<any> {
        const search = this.buildSearchQuery(keywords, type);
        const queryParams = this.buildSearchQueryParams(search);
        return this.http.get(`${environment.apiUrl}/salons`, {
            params: queryParams,
        });
    }

    /**
     * Sends an inquiry about a salon with the specified ID.
     *
     * @param {number} id - The ID of the salon to inquire about.
     * @param {any} [data={}] - Additional data to include in the inquiry.
     * @returns {Observable<any>} - An observable that emits the response from the server.
     */
    inquire(id: number, data: any = {}): Observable<any> {
        const updatedData = this.prepareData(data, id);
        return this.http
            .post(this.buildSalonUrl(id, '/inquire'), updatedData)
            .pipe(this.transformApiResponse());
    }

    /**
     * Retrieves the list of own connections from the provider server.
     *
     * @returns {Observable<any>} An observable that emits the list of own connections from the provider server.
     */
    getOwnConnections(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/provider/connections`);
    }

    /**
     * Retrieves the list of own providers.
     *
     * @return {Observable<any>} - An observable that emits the response from the API call.
     */
    getOwnProviders(queryParams?: any): Observable<any> {
        queryParams = Object.assign(
            this.buildDefaultQueryParams(),
            queryParams,
        );
        return this.http.get(`${environment.apiUrl}/provider/salons`, {
            params: queryParams,
        });
    }

    /**
     * Retrieves the default provider for the current user.
     *
     * @returns {Observable<any>} - An observable that emits the default provider information.
     */
    getOwnDefaultProvider(): Observable<any> {
        const queryParams = this.buildDefaultQueryParams();
        return this.http.get(`${environment.apiUrl}/provider/salons/default`, {
            params: queryParams,
        });
    }

    /**
     * Retrieve slots for a given salon and date range.
     *
     * @param {number} id - The ID of the salon.
     * @param {any} startDate - The start date of the date range.
     * @param {any} endDate - The end date of the date range.
     * @param serviceId
     * @returns {Observable<any>} An Observable containing the slots for the given salon and date range.
     */
    getSlots(
        id: number,
        startDate: any,
        endDate: any,
        serviceId?: any,
    ): Observable<any> {
        const url = this.buildSalonUrl(id, `/slots`);
        const queryParams = {};
        queryParams['start_date'] = startDate;
        queryParams['end_date'] = endDate;
        if (serviceId) {
            queryParams['service_id'] = serviceId;
        }
        return this.http.get(url, { params: queryParams });
    }

    /**
     * Retrieves scores for a given salon between the specified start and end dates.
     *
     * @param {number} id - The ID of the salon.
     * @param {any} startDate - The start date for retrieving scores.
     * @param {any} endDate - The end date for retrieving scores.
     * @returns {Observable<any>} An Observable that emits the scores.
     */
    getScores(id: number, startDate: any, endDate: any): Observable<any> {
        const url = this.buildSalonUrl(id, `/scores`);
        const queryParams = {};
        queryParams['start_date'] = startDate;
        queryParams['end_date'] = endDate;
        return this.http.get(url, { params: queryParams });
    }

    /**
     * Retrieves visitors for a specific salon within a given date range.
     *
     * @param {number} id - The salon ID.
     * @param {any} startDate - The start date of the date range.
     * @param {any} endDate - The end date of the date range.
     * @returns {Observable<any>} - An observable that emits the visitors data.
     */
    getVisitors(id: number, startDate: any, endDate: any): Observable<any> {
        const url = this.buildSalonUrl(id, `/visitors`);
        const queryParams = {};
        queryParams['start_date'] = startDate;
        queryParams['end_date'] = endDate;
        return this.http.get(url, { params: queryParams });
    }

    /**
     * Retrieves recommended providers from the server.
     *
     * @returns {Observable<any>} An observable that emits the response.
     */
    getRecommendedProviders(): Observable<any> {
        const queryParams = this.buildRecommendedProvidersQueryParams();
        return this.http.get(`${environment.apiUrl}/salons`, {
            params: queryParams,
        });
    }

    /**
     * Retrieves a list of providers based on the provided query parameters.
     *
     * @param {Object} queryParams - The query parameters to filter the providers.
     * @return {Observable<any>} - An observable that emits the list of providers.
     */
    getProviders(queryParams = {}): Observable<any> {
        queryParams = this.buildDefaultQueryParams(queryParams);
        return this.http.get(`${environment.apiUrl}/salons`, {
            params: this.filterService.buildQueryParams(queryParams),
        });
    }

    /**
     * Retrieves galleries from the server based on the given salon ID.
     *
     * @param {number} salonId - The ID of the salon for which galleries should be retrieved.
     *
     * @return {Observable<any>} - An observable that emits the response of the HTTP request to the server.
     */
    getGalleries(salonId: number): Observable<any> {
        const queryParams = this.buildGalleryQueryParams(salonId);
        return this.http.get(`${environment.apiUrl}/galleries`, {
            params: queryParams,
        });
    }

    /**
     * Retrieves the list of employees for a given salon ID.
     *
     * @param {number} salonId - The ID of the salon.
     *
     * @return {Observable<any>} Observable that emits the response of the HTTP request to retrieve employees.
     */
    getEmployees(salonId: number): Observable<any> {
        return this.http.get(
            `${environment.apiUrl}/salons/${salonId}/employees`,
        );
    }

    /**
     * Deletes a record by its ID.
     *
     * @param {number} id - The ID of the record to delete.
     *
     * @returns {Observable<any>} - An observable that emits the result of the delete operation.
     *
     * @example
     * delete(1).subscribe((result) => {
     *     console.log("Record deleted successfully");
     * }, (error) => {
     *     console.error("Failed to delete record: ", error);
     * });
     */
    delete(id: number): Observable<any> {
        return this.http
            .delete(`${environment.apiUrl}/provider/salons/${id}`)
            .pipe(this.transformApiResponse());
    }

    /**
     * Builds the URL for the salon endpoint based on the given parameters.
     *
     * @param {number} id - The ID of the salon.
     * @param {string} [path=''] - The optional path to append to the URL.
     * @return {string} The generated URL for the salon endpoint.
     */
    private buildSalonUrl(id: number, path = ''): string {
        return this.authService.isLoggedIn()
            ? `${environment.apiUrl}/auth/salons/${id}${path}`
            : `${environment.apiUrl}/salons/${id}${path}`;
    }

    /**
     * Build default query parameters.
     *
     * @param {Object} qParams - The custom query parameters.
     * @return {Object} - The merged query parameters.
     */
    private buildDefaultQueryParams(qParams = {}) {
        const queryParams = {
            with: this.expand,
            currency: this.appService.appSettings.currency_code,
            conversion: 'true',
        };

        // merge qParams to queryParams
        return Object.assign(queryParams, qParams);
    }

    /**
     * Parse the response data.
     *
     * @param {any} response - The response to parse.
     * @returns {any} - The parsed response.
     */
    private parseResponse(response: any): any {
        if (typeof response.data?.data === 'string') {
            response.data.data = JSON.parse(response.data.data);
        }
        return response;
    }

    /**
     * Prepares the given data by optionally adding an ID and stringifying the availabilities_set property.
     *
     * @param {any} data - The data to be prepared.
     * @param {number} [id] - The optional ID to be added to the data.
     * @returns {any} - The prepared data.
     */
    private prepareData(data: any, id?: number): any {
        if (id) {
            data.id = id;
        }
        if (typeof data.availabilities_set === 'object') {
            data.availabilities_set = JSON.stringify(data.availabilities_set);
        }
        return data;
    }

    /**
     * Handles the response of an update request.
     *
     * @param {any} res - The response data.
     * @returns {any} - The response data.
     */
    private handleUpdateResponse(res: any): any {
        if (res.success) {
            this.change.emit(new ProviderModel(res.data));
        }
        return res;
    }

    /**
     * Handles the response after creating an entity.
     *
     * @param {any} res - The response received after creating the entity.
     * @return {any} - The response object from the server.
     */
    private handleCreateResponse(res: any): any {
        if (res.success) {
            this.change.emit(new ProviderModel(res.data));
        }
        return res;
    }

    /**
     * Handles the duplication response.
     *
     * @param {any} res - The response object.
     * @return {any} - The modified response object.
     */
    private handleDuplicateResponse(res: any): any {
        if (res.success) {
            this.change.emit(new ProviderModel(res.data));
        }
        return res;
    }

    /**
     * Builds a search query based on the given keywords and optional type.
     *
     * @param {string} keywords - The keywords to search for.
     * @param {string} [type] - The optional type to filter the search by.
     * @return {string} The generated search query.
     */
    private buildSearchQuery(keywords: string, type?: string): string {
        let search = keywords ? `name:${keywords}` : '';
        if (type) {
            search += `${search ? ';' : ''}type:${type}`;
        }
        return search;
    }

    /**
     * Builds the search query params for a given search string.
     *
     * @param {string} search - The search string.
     * @returns {HttpParams} - The constructed HttpParams object.
     */
    private buildSearchQueryParams(search: string): HttpParams {
        return new HttpParams({
            fromObject: {
                search,
                searchFields: 'name:like;type:=',
                searchJoin: 'and',
            },
        });
    }

    /**
     * Builds the query parameters for retrieving recommended providers.
     *
     * @method buildRecommendedProvidersQueryParams
     * @private
     * @returns {HttpParams} - The query parameters as an instance of HttpParams class for making the API request.
     */
    private buildRecommendedProvidersQueryParams(): HttpParams {
        const queryParams = {
            with: 'salonLevel;address;eservices',
            limit: '6',
            orderBy: 'distance',
        };
        if (typeof this.appService.location !== 'undefined') {
            queryParams['myLat'] = this.appService.location.latitude.toString();
            queryParams['myLon'] =
                this.appService.location.longitude.toString();
        }
        return new HttpParams({ fromObject: queryParams });
    }

    /**
     * Builds the query parameters for gallery API request.
     *
     * @param {number} salonId - The ID of the salon.
     * @return {HttpParams} - The query parameters for the gallery API request.
     */
    private buildGalleryQueryParams(salonId: number): HttpParams {
        return new HttpParams({
            fromObject: {
                with: 'media',
                search: `salon_id:${salonId}`,
                searchFields: 'salon_id:=',
                orderBy: 'updated_at',
                sortedBy: 'desc',
            },
        });
    }

    /**
     * Transforms the response received from an API into the specified format.
     *
     * @returns {OperatorFunction<any, any>} Returns an operator function that transforms the API response.
     */
    transformApiResponse(): OperatorFunction<any, any> {
        return map((res) => {
            if (res.success) {
                this.change.emit(new ProviderModel(res.data));
            }
            return res;
        });
    }
}
