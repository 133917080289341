import {
    GoogleLoginProvider,
    SocialAuthServiceConfig,
    SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { CommonModule, registerLocaleData } from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from '@angular/common/http';
import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA,
    ErrorHandler,
    NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { SUPPORTED_LOCALE_DATA } from '@app/shared/constant/localization.constant';
import { AppService } from '@app/shared/service/app.service';
import { UiLoginModalComponent } from '@app/ui/layout/components/login-modal/login-modal.component';
import { environment } from '@environments/environment';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { MbscModule, setOptions } from '@mobiscroll/angular';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GtagModule } from 'angular-gtag';
import countries from 'i18n-iso-countries';
import { firstValueFrom } from 'rxjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './shared/error-handler';
import { AuthInterceptor } from './shared/interceptor/auth.interceptor';
import { HttpRequestInterceptor } from './shared/interceptor/http.interceptor';
import { ServerErrorInterceptor } from './shared/interceptor/server-error.interceptor';
import { SharedModule } from './shared/shared.module';
import { UiModule } from './ui/ui.module';

function registerAllLocaleData() {
    SUPPORTED_LOCALE_DATA.forEach((locale) => {
        registerLocaleData(locale.localeFile, locale.name);
        countries.registerLocale(locale.countryLocaleFile);
    });
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appInitializerFactory(
    translate: TranslateService,
    appService: AppService,
) {
    return () => {
        translate.setDefaultLang(appService.getLocaleByStrategy());
        appService.setLocale(appService.getLocaleByStrategy());
        return Promise.all([
            firstValueFrom(translate.use(appService.getLocaleByStrategy())),
        ]);
    };
}

setOptions({
    theme: 'ios',
});

registerAllLocaleData();

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [AppComponent, UiLoginModalComponent],
    imports: [
        HttpClientModule,
        BrowserModule,
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        IonicModule.forRoot({
            mode: 'ios',
        }),
        SocialLoginModule,
        SharedModule,
        UiModule,
        FormsModule,
        MbscModule,
        AppRoutingModule,
        IonicStorageModule.forRoot({
            driverOrder: [Drivers.LocalStorage],
        }),
        GtagModule.forRoot({
            trackingId: 'GTM-PCDDG7ZD',
            trackPageviews: true,
        }),
        ReactiveFormsModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: environment.appSettings.hideConsoleErrors
                ? GlobalErrorHandler
                : ErrorHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true,
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, AppService],
            multi: true,
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            environment.googleClientId,
                        ),
                    },
                ],
                onError: (err) => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
