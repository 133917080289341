import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    logError(message: string) {
        const blacklist = ['StatusBar'];

        const isBlacklisted = blacklist.some((search) => {
            return message.indexOf(search) !== -1;
        });

        if (!isBlacklisted) {
            console.log('LoggingService: ' + message);
        }
    }
}
