import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { __ } from '@app/global';
import {
    getFormValidationErrors,
    showErrorMessages,
} from '@app/shared/form-validation-errors';
import { AppService } from '@app/shared/service/app.service';
import { AuthService } from '@app/shared/service/auth.service';
import { NotifyService } from '@app/shared/service/notify.service';
import { IonModal, Platform } from '@ionic/angular';
import { MbscPopup, MbscPopupOptions } from '@mobiscroll/angular';

@Component({
    selector: 'ui-booking-modal',
    templateUrl: './booking-modal.component.html',
    styleUrls: ['./booking-modal.component.scss'],
})
export class UiBookingModalComponent implements AfterViewInit {
    @ViewChild('bookingModal') bookingModal: IonModal;
    @ViewChild('bookingModalMbsc') bookingModalMbsc: MbscPopup;
    popupOptions: MbscPopupOptions = {
        theme: 'ios',
        buttons: ['cancel'],
        width: 800,
        maxWidth: 800,
        locale: this.appService.getLocale(),
    };
    loginForm = new FormGroup({
        password: new FormControl('', Validators.required),
        email: new FormControl(
            '',
            Validators.compose([
                Validators.required,
                Validators.pattern(
                    '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
                ),
            ]),
        ),
    });
    guestForm = new FormGroup({
        email: new FormControl(
            '',
            Validators.compose([
                Validators.required,
                Validators.pattern(
                    '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
                ),
            ]),
        ),
        first_name: new FormControl('', Validators.required),
        last_name: new FormControl('', Validators.required),
        phone_number: new FormControl('', Validators.required),
        gender: new FormControl('', Validators.required),
    });
    constructor(
        private platform: Platform,
        private authService: AuthService,
        public appService: AppService,
        private notify: NotifyService,
    ) {}

    /**
     * Performs an action after the view initialization.
     *
     * Sets the bookingModal property of the appService to the provided bookingModal.
     * This method is usually called automatically by Angular after the view has been initialized.
     *
     * @return {void} This method does not return a value.
     */
    ngAfterViewInit() {
        this.appService.bookingModal = this.bookingModal;
    }

    /**
     * Closes the booking modal.
     *
     * @return {void} No return value
     */
    close() {
        if (this.platform.is('desktop')) {
            this.bookingModalMbsc.close();
        } else {
            this.bookingModal.dismiss();
        }
    }

    /**
     * Opens the booking modal.
     *
     * @return {void}
     */
    open() {
        if (this.platform.is('desktop')) {
            this.popupOptions.cancelText = __('cancel');
            this.bookingModalMbsc.open();
        } else {
            this.bookingModal.present();
        }
    }

    /**
     * Continue as a guest and sign up using the provided guest form data.
     *
     * It will validate the guest form and make a sign up request with the form data to the authentication service.
     * If the form is valid, it will show the loader while making the request and stop the loader after the request is completed.
     * If the form is invalid, it will display the error messages using the `getFormValidationErrors` function.
     *
     * @return {void}
     */
    onContinueAsGuest(): void {
        if (this.guestForm.valid) {
            this.appService.showLoader();
            this.authService
                .signUpAsGuest({
                    email: this.guestForm.value.email,
                    first_name: this.guestForm.value.first_name,
                    last_name: this.guestForm.value.last_name,
                    phone_number: this.guestForm.value.phone_number,
                    gender: this.guestForm.value.gender,
                })
                .subscribe();
        } else {
            showErrorMessages(getFormValidationErrors(this.guestForm.controls));
        }
    }

    /**
     * Sign in method.
     * This method is responsible for signing in a user by submitting the login form.
     * If the login form is valid, it sends a request to the authentication service to sign in the user.
     * If the sign-in process is successful, it stops the loader and performs any necessary actions.
     * If there is an error during the sign-in process, it shows an error notification message and stops the loader.
     *
     * @returns {void}
     */
    onSignIn(): void {
        if (this.loginForm.valid) {
            this.appService.showLoader();
            this.authService
                .signIn({
                    email: this.loginForm.value.email,
                    password: this.loginForm.value.password,
                })
                .subscribe(
                    () => {
                        this.appService.stopLoader();
                    },
                    () => {
                        this.notify.showError(__('login_error'));
                        this.appService.stopLoader();
                    },
                );
        } else {
            showErrorMessages(getFormValidationErrors(this.loginForm.controls));
        }
    }

    /**
     * Retrieves the email FormControl from the loginForm.
     *
     * @returns {FormControl} The email FormControl object.
     */
    get email() {
        return this.loginForm.get('email');
    }

    /**
     * Retrieves the first name form control from the guestForm.
     *
     * @return {AbstractControl} The first name form control.
     */
    get firstName() {
        return this.guestForm.get('first_name');
    }

    /**
     * Returns the last name form control of the guest form.
     *
     * @return {AbstractControl} The last name form control of the guest form.
     */
    get lastName() {
        return this.guestForm.get('last_name');
    }

    /**
     * Retrieves the phone number control from the guest form.
     *
     * @returns {AbstractControl} The phone number control.
     */
    get phone() {
        return this.guestForm.get('phone_number');
    }

    /**
     * Retrieves the password form control from the login form.
     *
     * @returns {AbstractControl} - The password form control.
     */
    get password() {
        return this.loginForm.get('password');
    }
}
