import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AuthService } from '@app/shared/service/auth.service';
import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { BookingModel } from '../model/booking.model';

import { AppService } from './app.service';

@Injectable({
    providedIn: 'root',
})
export class BookingService {
    public change: EventEmitter<any> = new EventEmitter<any>();
    private url = environment.apiUrl + '/bookings';
    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private appService: AppService,
    ) {}

    /**
     * Retrieves the booking statuses from the API.
     * @return {Observable} An Observable that emits the booking statuses.
     */
    getStatuses(): Observable<any> {
        return this.http.get(environment.apiUrl + '/booking_statuses');
    }

    /**
     * Retrieves bookings from the server.
     *
     * @param {Object} [queryParams] - Optional query parameters to filter the bookings.
     * @param {string} [queryParams.with] - Additional entities to include in the response.
     * @param {string} [queryParams.currency] - Currency code for currency conversion.
     * @param {boolean} [queryParams.conversion] - Indicates if currency conversion should be performed.
     *
     * @returns {Observable<any>} - An observable that emits the list of bookings.
     */
    getBookings(queryParams?: any): Observable<any> {
        queryParams = Object.assign(
            {
                with: 'bookingStatus;user;employee;payment;payment.currency;payment.paymentMethod;payment.paymentStatus',
                currency: this.appService.appSettings.currency_code,
                conversion: true,
            },
            queryParams,
        );

        return this.http.get(this.url, {
            params: queryParams,
        });
    }

    /**
     * Retrieves bookings by status.
     *
     * @param {string | number} statusId - The ID of the booking status.
     * @param {any} queryParams - Additional query parameters.
     * @returns {Observable<any>} - The observable that emits the response containing the bookings.
     */
    getBookingsByStatus(
        statusId?: string | number,
        queryParams?: any,
    ): Observable<any> {
        queryParams = Object.assign(
            {
                search: 'booking_status_id:' + statusId,
                with: 'bookingStatus;user;employee;payment;payment.paymentMethod;payment.paymentStatus',
                currency: this.appService.appSettings.currency_code,
                conversion: true,
            },
            queryParams,
        );

        return this.http.get(this.url, {
            params: queryParams,
        });
    }

    /**
     * Retrieves data for a given id.
     *
     * @param {string | number} id - The id of the data to retrieve.
     *
     * @return {Observable<any>} - An observable that emits the retrieved data.
     */
    get(id: string | number): Observable<any> {
        const queryParams = {
            with: 'bookingStatus;user;employee;payment;payment.currency;payment.paymentMethod;payment.paymentStatus;reviews;user_reviews',
            currency: this.appService.appSettings.currency_code,
            conversion: true,
        };
        return this.http.get(this.url + '/' + id, {
            params: queryParams,
        });
    }

    /**
     * Sends an HTTP POST request to the specified URL with the provided data.
     *
     * @param {any} data - The data to be sent in the request body.
     *
     * @returns {Observable<any>} - An observable that emits the response from the HTTP POST request.
     *                             If the response is successful (res.success is true),
     *                             emits a BookingModel instance by emitting a change event.
     */
    create(data: any): Observable<any> {
        return this.http.post(this.url, data).pipe(this.transformApiResponse());
    }

    /**
     * Updates the data associated with the given ID.
     * @param {string} id - The ID of the data to be updated.
     * @param {Object} data - The updated data.
     * @returns {Observable<Object>} - The transformed API response containing the updated data.
     */
    update(id: any, data: object): Observable<object> {
        data['id'] = id;
        return this.http
            .put(this.url + '/' + id, data)
            .pipe(this.transformApiResponse());
    }

    /**
     * Cancels a booking by updating the booking status.
     *
     * @param {number} id - The ID of the booking to be canceled.
     * @return {Observable<any>} - An observable that emits the API response after canceling the booking.
     */
    cancel(id: number): Observable<any> {
        const data = {
            id: id,
            cancel: true,
        };
        return this.http
            .put(this.url + '/' + id, data)
            .pipe(this.transformApiResponse());
    }

    /**
     * Updates the status of a booking.
     *
     * @param {number} id - The ID of the booking.
     * @param {any} statusId - The ID of the status to update the booking to.
     * @param {any} [message] - Optional message to include with the status update.
     *
     * @returns {Observable<any>} - Observable that emits the transformed API response.
     */
    updateStatus(id: any, statusId: any, message?: any): Observable<any> {
        const data = {
            id: id,
            booking_status_id: statusId, // accepted
        };
        if (message) {
            data['message'] = message;
        }

        switch (statusId) {
            case 'approve':
                data['booking_status_id'] = 4;
                break;
            case 'done':
                data['booking_status_id'] = 6;
                break;
            case 'cancel':
                data['booking_status_id'] = 7;
                data['cancel'] = true;
                break;
        }
        return this.http
            .put(this.url + '/' + id, data)
            .pipe(this.transformApiResponse());
    }

    /**
     * Transforms the response received from an API call.
     * If the response is successful, it emits a change event with the transformed data.
     * @param {Object} res - The response object received from the API.
     * @param {*} res.success - The success flag indicating if the API call was successful.
     * @param {*} res.data - The data received from the API call.
     * @return {Object} - The transformed response object.
     */
    transformApiResponse(): OperatorFunction<any, any> {
        return map((res: any) => {
            if (res.success) {
                this.change.emit(new BookingModel(res.data));
            }
            return res;
        });
    }
}
