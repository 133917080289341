import { Injectable } from '@angular/core';
import { MatchMediaQueries } from '@app/global';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as uuid from 'uuid';
@Injectable({
    providedIn: 'root',
})
export class HelperService {
    static instance: HelperService;

    constructor(
        private alertController: AlertController,
        private translate: TranslateService,
    ) {
        return (HelperService.instance = HelperService.instance || this);
    }

    /**
     * Confirms an action by showing an alert with Yes and No buttons.
     *
     * @param confirmText - Optional. The text to display in the alert. If not provided, a default text will be used.
     * @returns A promise that resolves with a boolean value indicating whether the user confirmed the action (true) or not (false).
     */
    async confirm(confirmText?: string): Promise<any> {
        return new Promise((resolve) => {
            this.alertController
                .create({
                    header:
                        confirmText || this.translate.instant('are_you_sure'),
                    cssClass: 'custom-alert',
                    buttons: [
                        {
                            text: this.translate.instant('no'),
                            cssClass: 'alert-button-cancel',
                            handler: () => resolve(false),
                        },
                        {
                            text: this.translate.instant('yes'),
                            cssClass: 'alert-button-danger',
                            handler: () => resolve(true),
                        },
                    ],
                })
                .then((alert) => {
                    // Now we just need to present the alert
                    alert.present();
                });
        });
    }

    /**
     * Converts a string to a slug format.
     *
     * @param {string} str - The string to be converted.
     * @return {string} - The string converted to a slug format.
     */
    slugify(str: string): string {
        return String(str)
            .normalize('NFKD') // split accented characters into their base characters and diacritical marks
            .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
            .trim() // trim leading or trailing whitespace
            .toLowerCase() // convert to lowercase
            .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
            .replace(/\s+/g, '-') // replace spaces with hyphens
            .replace(/-+/g, '-'); // remove consecutive hyphens
    }

    /**
     * Generates a universally unique identifier (UUID) using the uuid.v4() method.
     *
     * @returns {string} A randomly generated UUID.
     */
    uuid(): string {
        return uuid.v4();
    }

    id(): number {
        return Math.floor(Math.random() * 1000000000);
    }

    /**
     * Generates a random password with the specified length and characters from the provided wishlist.
     *
     * @param {number} [length=20] - The length of the password. Default is 20.
     * @param {string} [wishlist='0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'] - The wishlist of characters to choose from when generating the password. Default
     * is a combination of alphanumeric and special characters.
     * @returns {string} - The randomly generated password.
     */
    makePassword(
        length: any = 20,
        wishlist: any = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$',
    ): string {
        return Array.from(crypto.getRandomValues(new Uint32Array(length)))
            .map((x) => wishlist[x % wishlist.length])
            .join('');
    }

    /**
     * Transforms the given data according to the specified pattern.
     *
     * @param {any[]} data - The data to be transformed.
     * @param {Object} [pattern] - Optional pattern object to specify the mapping for each item in the data.
     *                            Defaults to { value: 'id', text: 'name' } if not provided.
     * @return {Object[]} - The transformed data array.
     */
    transformMbscData(data: any, pattern?: any) {
        pattern = pattern || {
            value: 'id',
            text: 'name',
        };

        return data.map((item: any) => {
            return {
                value: item[pattern.value],
                text: item[pattern.text],
            };
        });
    }
    mediaQueries = MatchMediaQueries;
}
