import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { en, hu } from '../constant/localization-route.constant';
import {
    DEFAULT_LANGUAGE,
    SUPPORTED_LANGUAGES,
    SupportedLanguagesType,
} from '../constant/localization.constant';

import { AppService } from './app.service';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    static en = en;
    static hu = hu;
    static supportedLanguages = SUPPORTED_LANGUAGES;
    static defaultLanguage = DEFAULT_LANGUAGE;

    constructor(private router: Router, private appService: AppService) {}

    static generateTranslatedRouteConfigs(path, routeConfig) {
        return [
            ...this.supportedLanguages.reduce((accumulator, language) => {
                const finalConfig = {
                    ...routeConfig,
                    path: NavigationService[language]?.[path]
                        ? NavigationService[language][path]
                        : path,
                };

                if (
                    !accumulator.find((item) => item.path === finalConfig.path)
                ) {
                    accumulator.push(finalConfig);
                }

                return accumulator;
            }, []),
        ];
    }

    determineRouteLanguage(path: string): SupportedLanguagesType {
        return NavigationService.supportedLanguages.find((supportedLanguage) =>
            Object.values(NavigationService[supportedLanguage]).includes(path),
        );
    }

    getTargetUrl(targetUrlKeys: string | (string | number)[]) {
        let targetUrl;

        if (targetUrlKeys instanceof Array) {
            targetUrl = targetUrlKeys
                .map((item) => this.getUrlTranslation(item))
                .join('/');
        } else {
            targetUrl = this.getUrlTranslation(targetUrlKeys);
        }

        return targetUrl;
    }

    navigate(targetUrlKeys: string | (string | number)[], pageParam?: unknown) {
        this.routerNavigate([this.getTargetUrl(targetUrlKeys)], pageParam);
    }

    navigateToModal(
        targetUrlKeys: string | (string | number)[],
        pageParam?: unknown,
    ) {
        this.routerNavigate(
            [
                '/',
                {
                    outlets: {
                        modal: this.getTargetUrl([
                            'modals',
                            ...(Array.isArray(targetUrlKeys)
                                ? targetUrlKeys
                                : [targetUrlKeys]),
                        ]),
                    },
                },
            ],
            {
                skipLocationChange: true,
                replaceUrl: false,
                ...((typeof pageParam === 'object' &&
                    pageParam !== null &&
                    pageParam) ||
                    {}),
            },
        );
    }

    private routerNavigate(target, pageParam?: unknown) {
        this.router.navigate(target, pageParam);
    }

    private getUrlTranslation(targetUrlKey) {
        const language = NavigationService[this.appService.getLocale()] || [];
        try {
            return language[targetUrlKey] || targetUrlKey;
        } catch (e) {
            return targetUrlKey;
        }
    }
}
