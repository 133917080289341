import { Pipe, PipeTransform } from '@angular/core';

import { NavigationService } from '../service/navigation.service';

@Pipe({
    name: 'translateUrl',
    pure: false,
})
export class TranslateUrlPipe implements PipeTransform {
    constructor(private navigationService: NavigationService) {}

    transform(value: string | (string | number)[]): string {
        return this.navigationService.getTargetUrl(value);
    }
}
