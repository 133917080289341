import { BaseModel } from './base.model';

export class RoleModel extends BaseModel {
    id: number;
    name: string;
    guard_name: string;
    default: string;
    created_at: string;
    updated_at: string;
    pivot: any;
}
