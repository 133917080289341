import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { UiBookingInstantComponent } from '@app/ui/components/booking/booking-instant/booking-instant.component';
import { UiCalendarComponent } from '@app/ui/components/calendar/calendar.component';
import { UiSearchCategoriesComponent } from '@app/ui/components/categories/categories.component';
import { UiCustomerCardComponent } from '@app/ui/components/customer/customer-card/customer-card.component';
import { UiCustomerFieldsComponent } from '@app/ui/components/customer/customer-fields/customer-fields.component';
import { UiEmployeeCardComponent } from '@app/ui/components/employee/employee-card/employee-card.component';
import { UiEmployeeFieldsComponent } from '@app/ui/components/employee/employee-fields/employee-fields.component';
import { UiInputAddressComponent } from '@app/ui/components/form/input-address/input-address.component';
import { UiInputSelectorOptionComponent } from '@app/ui/components/form/input-selector/input-selector-option/input-selector-option.component';
import { UiInputSelectorComponent } from '@app/ui/components/form/input-selector/input-selector.component';
import { UiGalleryListComponent } from '@app/ui/components/image-list/image-list.component';
import { UiMoveToIconComponent } from '@app/ui/components/move-to-icon/move-to-icon.component';
import { UiSkeletonComponent } from '@app/ui/components/skeleton/skeleton.component';
import { UiTextSwitchingComponent } from '@app/ui/components/text-switching/text-switching.component';
import { UiUserCompletionComponent } from '@app/ui/components/user/user-completion/user-completion.component';
import { UiUserFieldsComponent } from '@app/ui/components/user/user-fields/user-fields.component';
import { UiWizardBecomePartnerComponent } from '@app/ui/components/wizard/become-partner/become-partner.component';
import { UiAccountLayoutComponent } from '@app/ui/layout/account-layout/account-layout.component';
import { UiBusinessLayoutComponent } from '@app/ui/layout/business-layout/business-layout.component';
import { UiBusinessMenuComponent } from '@app/ui/layout/business-layout/menu/menu.component';
import { UiBookingModalComponent } from '@app/ui/layout/components/booking-modal/booking-modal.component';
import { UiHeaderComponent } from '@app/ui/layout/components/header/header.component';
import { UiLogoComponent } from '@app/ui/layout/components/header/logo/logo.component';
import { UiModalsComponent } from '@app/ui/layout/components/modals/modals.component';
import { UiSideMenuDesktopComponent } from '@app/ui/layout/components/side-menu-desktop/side-menu.component';
import { SideMenuComponent } from '@app/ui/layout/components/side-menu/side-menu.component';
import { UiMainLayoutComponent } from '@app/ui/layout/main-layout/main-layout.component';
import { IonicModule } from '@ionic/angular';
import { MbscDatepickerModule, MbscModule } from '@mobiscroll/angular';
import { TranslateModule } from '@ngx-translate/core';

import { UiAddressFieldsSimpleComponent } from './components/address/address-fields-simple/address-fields.component';
import { UiAddressFieldsComponent } from './components/address/address-fields/address-fields.component';
import { UiAmenityFieldsComponent } from './components/amenity/amenity-fields/amenity-fields.component';
import { UiAvailabilityFieldsComponent } from './components/availability/availability-fields/availability-fields.component';
import { UIBookingCardComponent } from './components/booking/booking-card/booking-card.component';
import { UiCertificateFieldsComponent } from './components/certificate/certificate-fields/certificate-fields.component';
import { UiCouponCardComponent } from './components/coupon/coupon-card/coupon-card.component';
import { UiCouponFieldsComponent } from './components/coupon/coupon-fields/coupon-fields.component';
import { UiDatePickerComponent } from './components/date-picker/date-picker.component';
import { UiExperienceFieldsComponent } from './components/experience/experience-fields/experience-fields.component';
import { FiltersComponent } from './components/filters/filters.component';
import { UiInputChipOptionComponent } from './components/form/input-chip-select/input-chip-option/input-chip-option.component';
import { UiInputChipSelectComponent } from './components/form/input-chip-select/input-chip-select.component';
import { UiInputPhoneComponent } from './components/form/input-phone/input-phone.component';
import { UiGalleryFieldsComponent } from './components/gallery/gallery-fields/gallery-fields.component';
import { MapComponent } from './components/map/map.component';
import { UiModalComponent } from './components/modal/modal.component';
import { UiOptionFieldsComponent } from './components/option/option-fields/option-fields.component';
import { UiOrderByComponent } from './components/order-by/order-by.component';
import { UiProviderCardComponent } from './components/provider/provider-card/provider-card.component';
import { UiProviderFieldsComponent } from './components/provider/provider-fields/provider-fields.component';
import { RatingComponent } from './components/rating/rating.component';
import { UiRequestFieldsComponent } from './components/request/request-fields/request-fields.component';
import { UiSearchbarComponent } from './components/searchbar/searchbar.component';
import { UiServiceCardGroupComponent } from './components/service/service-card-group/service-card-group.component';
import { UiServiceCardComponent } from './components/service/service-card/service-card.component';
import { UiServiceFieldsComponent } from './components/service/service-fields/service-fields.component';
import { UiTabsComponent } from './components/tabs/tabs.component';
import { UiTaxFieldsComponent } from './components/tax/tax-fields/tax-fields.component';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        SideMenuComponent,
        UiTabsComponent,
        UiHeaderComponent,
        RatingComponent,
        UiProviderCardComponent,
        UiServiceCardComponent,
        MapComponent,
        FiltersComponent,
        UiProviderFieldsComponent,
        UiServiceFieldsComponent,
        UiCouponFieldsComponent,
        UiCouponCardComponent,
        UiDatePickerComponent,
        UiTaxFieldsComponent,
        UiExperienceFieldsComponent,
        UiCertificateFieldsComponent,
        UiRequestFieldsComponent,
        UIBookingCardComponent,
        UiInputPhoneComponent,
        UiAmenityFieldsComponent,
        UiOptionFieldsComponent,
        UiAvailabilityFieldsComponent,
        UiAddressFieldsComponent,
        UiLogoComponent,
        UiGalleryFieldsComponent,
        UiServiceCardGroupComponent,
        UiModalComponent,
        UiInputChipSelectComponent,
        UiInputChipOptionComponent,
        UiSearchbarComponent,
        UiOrderByComponent,
        UiModalsComponent,
        UiAddressFieldsSimpleComponent,
        UiSearchCategoriesComponent,
        UiCalendarComponent,
        UiSkeletonComponent,
        UiSkeletonComponent,
        UiTextSwitchingComponent,
        UiMoveToIconComponent,
        UiBusinessMenuComponent,
        UiBusinessLayoutComponent,
        UiMainLayoutComponent,
        UiAccountLayoutComponent,
        UiSideMenuDesktopComponent,
        UiWizardBecomePartnerComponent,
        UiInputAddressComponent,
        UiUserCompletionComponent,
        UiGalleryListComponent,
        UiInputSelectorComponent,
        UiInputSelectorOptionComponent,
        UiCustomerCardComponent,
        UiCustomerFieldsComponent,
        UiUserFieldsComponent,
        UiEmployeeCardComponent,
        UiEmployeeFieldsComponent,
        UiBookingModalComponent,
        UiBookingInstantComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        IonicModule.forRoot({
            mode: 'ios',
        }),
        TranslateModule,
        GoogleMapsModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MbscDatepickerModule,
        MbscModule,
        NgOptimizedImage,
    ],
    exports: [
        UiTabsComponent,
        SideMenuComponent,
        UiHeaderComponent,
        RatingComponent,
        MapComponent,
        FiltersComponent,
        UiServiceCardComponent,
        UiProviderCardComponent,
        UiProviderFieldsComponent,
        UiServiceFieldsComponent,
        UiCouponFieldsComponent,
        UiCouponCardComponent,
        UiDatePickerComponent,
        UiTaxFieldsComponent,
        UiExperienceFieldsComponent,
        UiCertificateFieldsComponent,
        UiRequestFieldsComponent,
        UIBookingCardComponent,
        UiInputPhoneComponent,
        UiAmenityFieldsComponent,
        UiOptionFieldsComponent,
        UiAvailabilityFieldsComponent,
        UiAddressFieldsComponent,
        UiLogoComponent,
        UiGalleryFieldsComponent,
        UiServiceCardGroupComponent,
        UiModalComponent,
        UiInputChipSelectComponent,
        UiInputChipOptionComponent,
        UiSearchbarComponent,
        UiOrderByComponent,
        UiModalsComponent,
        UiAddressFieldsSimpleComponent,
        UiSearchCategoriesComponent,
        UiCalendarComponent,
        UiSkeletonComponent,
        UiSkeletonComponent,
        UiTextSwitchingComponent,
        UiMoveToIconComponent,
        UiBusinessMenuComponent,
        UiBusinessLayoutComponent,
        UiMainLayoutComponent,
        UiAccountLayoutComponent,
        UiWizardBecomePartnerComponent,
        UiUserCompletionComponent,
        UiInputSelectorComponent,
        UiInputSelectorOptionComponent,
        UiCustomerFieldsComponent,
        UiCustomerCardComponent,
        UiUserFieldsComponent,
        UiEmployeeCardComponent,
        UiEmployeeFieldsComponent,
        UiBookingModalComponent,
        UiBookingInstantComponent,
        UiInputAddressComponent,
    ],
})
export class UiModule {}
